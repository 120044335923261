import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import {getRequest, putRequest} from "../../../actions/common";
import {SEMI_REQUIRED_FIELDS, STATUS_ACCEPT, STATUS_APPROVE, STATUS_NEW, STATUS_PROCESS} from "./constant";
import {Check, Input, Select, Area} from "../../../components/form/forms";
import {PERMISSIONS} from "../../../rbac/constant";
import {useAbac} from "react-abac";
import {AuthContext} from "../../../auth";
import {REQUIRED_FIELDS} from "./constant";
import {setChangedValue} from "../../../utils/utils";
import {toast} from "react-toastify";

export const PublicationCreate = () => {
    const {state} = useLocation();
    const {userHasPermissions} = useAbac();
    const user = useContext(AuthContext);
    const navigate = useNavigate();

    const [input_values, setInputValues] = useState({common: {}});
    const [select_data, setSelectData] = useState({});
    const [select_values, setSelectValues] = useState({});
    const [errors, setErrors] = useState(() => {
        let temp = {};
        REQUIRED_FIELDS[STATUS_ACCEPT].forEach((field) => {
            temp[field] = null;
        })
        return temp;
    });

    const select_fields = ['country', 'project'];

    useEffect(() => {
        if (!userHasPermissions(PERMISSIONS.MANAGER_PANEL)) {
            navigate('/access-error');
        } else {
            document.title = `Создание записи в БД Публикации | ИС «НЦМУ»`;
            if (user?.project?.id) {
                setSelectValues({...select_values, project: user?.project?.id})
            }
        }
    });

    useEffect(() => {
        if (Object.keys(select_data).length < select_fields.length) {
            for (let field of select_fields) {
                if (!Object.keys(select_data).includes(field)) {
                    getRequest(field, setSelectData, {type_data: 'all'}, '', select_data, field);
                    break
                }
            }
        }
    }, [select_data]);

    const getStatusClassName = (status) => {
        if (status === 1) {
            return 'bg-primary'
        } else if (status < 1) {
            return 'bg-success'
        } else if (status > 1) {
            return 'bg-secondary'
        }
        return null;
    }

    const handleChangeValue = (e) => {
        e.preventDefault();
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        let target = e.target.getAttribute('data_change_key');
        let sub_key = e.target.getAttribute('data_foreign_id');
        setChangedValue(setInputValues, input_values, target, e.target.id, value, sub_key);
    }

    const generateOptions = (id) => {
        let options = [];
        if (select_data !== null && select_data !== undefined) {
            if (Object.keys(select_data).includes(id)) {
                for (let element of select_data[id]) {
                    options.push({value: element.id, label: element.title});
                }
            }
        }
        return options;
    }

    const checkErrors = (status) => {
        let new_errors = {};
        let has_errors = false;

        REQUIRED_FIELDS[status].forEach((field) => {
            if (!document.getElementById(field)?.value && !select_values[field]) {
                new_errors[field] = 'Обязательное поле!';
                has_errors = true;
            } else {
                new_errors[field] = null;
            }
        })

        setErrors({...errors, ...new_errors})
        return has_errors
    }

    const savePublication = () => {
        let payload = {...input_values['common'], ...select_values};
        payload['status'] = state?.monitoring ? STATUS_ACCEPT : STATUS_NEW;
        putRequest('publication', payload).then(response => {
            if (response.status === 201) {
                navigate(`/publication/item/${response.data.id}?update=true`)
            }
        })
    }

    const handleSumitForm = (e) => {
        e.preventDefault();
        if (checkErrors(STATUS_NEW)) {
            toast.error('Ошибка в заполнении данных!');
            return null
        }
        savePublication();
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <Form onSubmit={handleSumitForm}>
                <div className="section-header sticky-top">
                    <h1 className="page-header">
                        {`Создание записи в БД Публикации`}
                    </h1>
                    <div className="button-actions">
                        <Button variant='success' type='submit'>Создать</Button>
                    </div>
                </div>
                <div
                    className="mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center">
                    <div className="mx-2 mb-2" style={{minWidth: "250px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_NEW)}`}>Новый</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{minWidth: "250px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_APPROVE)}`}>Рассматривается</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{minWidth: "250px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_PROCESS)}`}>Обрабатывается</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{minWidth: "250px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_ACCEPT)}`}>Принято</p>
                    </div>
                </div>
                <Card className={'bg-light mb-3 mt-3'}>
                    <Card.Body>
                        <p>
                            <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                            условные обозначения,
                            используемые в интерфейсе:
                        </p>
                        <ul>
                            <li>поля помеченные <strong className="text-danger">красной «*»</strong> являются <strong>обязательными
                                на текущем
                                этапе для перехода на следующий</strong>;
                            </li>
                            <li>поля помеченные <strong className="text-success">зелёной «*»</strong> являются <strong>обязательными
                                для
                                определенных условий</strong>;
                            </li>
                            <li>кнопка <span className="badge badge-success">Сохранить</span> выполняет сохранение
                                текущего состояния карточки (требуется
                                заполнения ключевых полей);
                            </li>
                            <li>кнопка <span
                                className="badge badge-success">Сохранить + отправить далее</span> сохраняет карточку и
                                проверяет выполнение
                                условий текущего этапа (при выполнении условий карточка переходит на следующий этап).
                            </li>
                        </ul>
                        <hr/>
                        <fieldset>
                            <legend>Показатели журнала</legend>
                            <Input handleChangeValue={handleChangeValue} label={'Наименование журнала'}
                                   id={'journal'}
                                   required={REQUIRED_FIELDS[STATUS_NEW].includes('journal')}
                                   error={errors['journal']}
                                   invalid={errors['journal']}
                            />
                            <Row>
                                <Col md={6}>
                                    <Select label={'Вид издания'} id={'publication_type_id'}
                                            isClearable={true}
                                            options={generateOptions('publication_type')}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('publication_type')}
                                            error={errors['publication_type']}
                                            invalid={errors['publication_type']}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Select label={'Страна издания'} id={'country_id'}
                                            isClearable={true}
                                            options={generateOptions('country')}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('country')}
                                            error={errors['country']}
                                            invalid={errors['country']}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                    />
                                </Col>
                            </Row>
                            <Row sm={2} md={4} className={'row-cols-1'}>
                                <Col>
                                    <Input handleChangeValue={handleChangeValue} label={'Квартиль журнала'}
                                           id={'journal_quartile'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('journal_quartile')}
                                           error={errors['journal_quartile']}
                                           invalid={errors['journal_quartile']}
                                    />
                                </Col>
                                <Col>
                                    <Input handleChangeValue={handleChangeValue} label={'ISSN'} id={'journal_issn'}
                                           required={SEMI_REQUIRED_FIELDS[STATUS_NEW].includes('journal_issn')}
                                           error={errors['journal_issn']}
                                           invalid={errors['journal_issn']}
                                    />
                                </Col>
                                <Col>
                                    <Input handleChangeValue={handleChangeValue} label={'ISBN'} id={'journal_isbn'}
                                           required={SEMI_REQUIRED_FIELDS[STATUS_NEW].includes('journal_isbn')}
                                           error={errors['journal_isbn']}
                                           invalid={errors['journal_isbn']}
                                    />
                                </Col>
                                <Col>
                                    <Input handleChangeValue={handleChangeValue} label={'Импакт-Фактор'}
                                           id={'impact_factor'} required={false}
                                    />
                                </Col>
                            </Row>
                            <hr/>
                        </fieldset>
                        <fieldset>
                            <legend>Показатели статьи</legend>
                            <Row>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Заголовок'} id={'title'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('title')}
                                           error={errors['title']}
                                           invalid={errors['title']}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Тип публикации'} id={'type'}
                                           required={false}
                                           disabled={true}/>
                                </Col>
                            </Row>
                            <Input handleChangeValue={handleChangeValue} label={'DOI'}
                                   id={'doi'}
                                   required={REQUIRED_FIELDS[STATUS_NEW].includes('doi')}
                                   error={errors['doi']}
                                   invalid={errors['doi']}
                                   small={'В формате 10.xxxx/xxxxx...'}
                            />
                            <Row>
                                <Col md={6}>
                                    <Check label={'Индексируется в WoS'} id={'wos_indexed'}
                                           handleChangeValue={handleChangeValue}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Check label={'Индексируется в Scopus'} id={'scopus_indexed'}
                                           handleChangeValue={handleChangeValue}
                                    />
                                </Col>
                            </Row>
                            <Input handleChangeValue={handleChangeValue} label={'Авторы-сотрудники НЦМУ'}
                                   id={'authors'}
                                   isMulti={true}
                                   required={REQUIRED_FIELDS[STATUS_NEW].includes('authors')}
                                   error={errors['authors']}
                                   invalid={errors['authors']}
                            />
                            <Area label={'Прочие авторы'} id={'other_authors'}
                                  required={false}
                            />
                            <Row sm={2} md={3} className={'row-cols-1'}>
                                <Col>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Дата первичного поступления в редакцию'}
                                           id={'receipt_date'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('receipt_date')}
                                           error={errors['receipt_date']}
                                           invalid={errors['receipt_date']}
                                           type={'date'}
                                           small={'Дата загрузки на сайт или дата получения письма о принятии к рассмотрению'}
                                    />
                                </Col>
                                <Col>
                                    <Input handleChangeValue={handleChangeValue} label={'Дата публикации'}
                                           id={'pub_date'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('pub_date')}
                                           error={errors['pub_date']}
                                           invalid={errors['pub_date']}
                                           type={'date'}
                                           small={'Дата опубликования статьи'}
                                    />
                                </Col>
                                <Col>
                                    <Input handleChangeValue={handleChangeValue} label={'Год зачета публикации'}
                                           id={'report_year'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('report_year')}
                                           error={errors['report_year']}
                                           invalid={errors['report_year']}
                                           type={'number'}
                                    />
                                </Col>
                            </Row>
                            <hr/>
                        </fieldset>

                        <fieldset>
                            <legend>Взаимосвязь с проектом НЦМУ</legend>
                            <Row>
                                <Col md={6}>
                                    <Select label={'Проект'} id={'project'}
                                            options={generateOptions('project')}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['project']}
                                            invalid={errors['project']}
                                            disabled={user ? user?.project?.id : false}
                                            required={true}/>
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Направление'} id={'direction'}
                                           value={user?.project?.direction}
                                           disabled={true}
                                    />
                                </Col>
                            </Row>
                            <Input handleChangeValue={handleChangeValue} label={'Ссылка на сопутствующие документы'}
                                   id={'related_docs_url'}
                            />
                            <hr/>
                        </fieldset>
                    </Card.Body>
                </Card>
            </Form>
        </React.Fragment>
    );
}
