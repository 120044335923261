import * as React from "react";
import {Button, Table} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {IconText} from "../../../components/icon_txt";
import {deleteRequest, getRequest} from "../../../actions/common";
import ReactTable from "../../../components/tables/react_table";
import {AuthContext} from "../../../auth";
import {PERMISSIONS, ROLES} from "../../../rbac/constant";
import {useAbac} from "react-abac";

const DictField = ({
                       fields, labels, url_update, dict_title,
                       additionalCheckField, tableCheckField, document_header = 'title',
                       header = 'title',
                       additional_table, additional_table_header, additional_field, additional_labels
                   }) => {
    const {userHasPermissions} = useAbac();

    const [data, setData] = useState(null);
    const [additional_data, setAdditionalData] = useState(null);
    const params = useParams();
    const navigate = useNavigate();
    const user = useContext(AuthContext);

    useEffect(() => {
        if (additional_table && !additional_data) {
            getRequest(additional_table, setAdditionalData, {[additional_field]: params.id});
        }
    }, [additional_data, additional_table])

    useEffect(() => {
        if (!data) {
            getRequest(dict_title, setData, {}, params.id);
        } else {
            if (data?.results) {
                for (let result in data.results) {
                    if (data.results[result]?.id === Number(params.id)) {
                        setData(data.results[result])
                        break
                    }
                }
            }
            document.title = `${data?.[document_header]} | ИС «НЦМУ»`;
        }
    }, [data]);

    const checkField = (field, key) => {
        if (data && labels) {
            if (additionalCheckField) {
                let response = additionalCheckField(field, key);
                if (response !== null) {
                    field = response;
                }
            }
            if (field === true) {
                return <span className="badge badge-success">Да</span>
            } else if (field === false) {
                return <span className="badge badge-danger">Нет</span>
            } else if (field !== null) {
                return field
            } else {
                return <span className={'not-set'}>(не задано)</span>
            }
        }
    }

    const TablesRow = () => {
        if (data) {
            let rows = [];
            for (let key in data) {
                if (fields.includes(key)) {
                    if (key === 'id' && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) continue;

                    rows.push(
                        <tr key={key}>
                            <th className={'text-right'}>{labels[key]}</th>
                            <td className={'text-left'}>{checkField(data[key], key)}</td>
                        </tr>
                    );
                }
            }
            return rows;
        }
        return null;
    }

    const handleDelete = () => {
        if (data !== null) {
            deleteRequest(dict_title, data.id).then();
            navigate(`/dicts/${dict_title.replaceAll('_', '-')}`);
        }
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb label={data?.title}/>
            <h1 className={"page-header"}>
                {data !== null ? data[header] : null}
            </h1>
            <div className='button-actions mb-4'>
                {url_update && !user?.roles?.includes(ROLES.management) ? <React.Fragment>
                    <Button onClick={() => {
                        navigate(`${url_update}/${params.id}`)
                    }}>
                        <IconText icon={'pencil-alt'} text={'Редактировать'}/>
                    </Button>
                    <Button variant='danger' onClick={handleDelete}>
                        <IconText icon={'trash-alt'} text={'Удалить'}/>
                    </Button>
                </React.Fragment> : null}
            </div>
            <Table striped bordered hover className={'detail-view'}>
                <tbody>
                <TablesRow/>
                </tbody>
            </Table>
            {additional_table_header ? <p className="h2">{additional_table_header}</p> : null}
            {additional_table
                ?
                <ReactTable
                    striped={true} bordered={true} hover={true}
                    data={additional_data}
                    labels={additional_labels}
                    additionalCheckField={tableCheckField}
                    show_result={false}
                />
                : null}
        </React.Fragment>
    );
}

export default DictField;
