import * as React from "react";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import ReactFilterTable from "../../../components/tables/react_table_filters";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FIELDS, LABELS, STATUS_BADGE_CLASS, STATUS_LABELS} from "./constant";
import {PERMISSIONS, ROLES} from "../../../rbac/constant";
import {AuthContext} from "../../../auth";
import {Button} from "react-bootstrap";
import {IconText} from "../../../components/icon_txt";
import {putRequest} from "../../../actions/common";
import {STATUS_ACCEPT, STATUS_NEW} from "./constant";
import {Messages} from "../../../utils/utils";
import {useAbac} from "react-abac";

const Publication = () => {
    const navigate = useNavigate();
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();

    const [publication, setPublication] = useState(null);
    const [filtering, setFiltering] = useState(null);
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'title', 'status', 'type', 'journal', 'publication_type', 'journal_quartile', 'journal_issn', 'journal_isbn', 'impact_factor'],
        'Скрытые / Фиксированные колонки': [
            "scopus_indexed",
            "wos_indexed",
            "receipt_date",
            "pub_date",
            "project",
            "report_year",
            "doi",
            "country",
            "other_authors",
            "messages",
            'updated_by',
            "created_at",
            "updated_at",
            "created_by",
            "authors",
            "direction_by",
        ]
    });


    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({status_not: STATUS_NEW})
        } else {
            setFiltering({})
        }
    }, [user])

    const checkField = (field, key) => {
        if (key === 'status') {
            if (key === 'status') {
                return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
            }
        }
        if (key === 'type') {
            if (field === 1) {
                return <span>Статья в журнале</span>
            }
        }
        // if (['country'].includes(key) && field) {
        //     return <span>{field.title}</span>
        // }
        if (['project', 'country'].includes(key) && field) {
            return <span>{field.title_short}</span>
        }
        if (['publication_type'].includes(key) && field) {
            return <span>{field.title}</span>
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'publication'}/>
        }
        if (key === 'authors' && field) {
            let elements = [];
            for (let index in field) {
                elements.push(`${field[index].fio}   `)
            }
            return elements
        }
        return null;
    }

    const addNewPublication = (state) => {
        putRequest('publication', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW,
            project: user?.project?.id,
            type: 1
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/publication/item/${response.data.id}/?update=true`);
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={"page-header"}>
                Публикации
            </h1>
            {user?.roles?.includes(ROLES.admin)  || user?.roles?.includes(ROLES.project_manager)
            || user?.roles?.includes(ROLES.direction) ?
                <React.Fragment>
                    <div className={'button-actions'}>
                        {!user?.roles?.includes(ROLES.direction) ?
                            <Button variant={'success'} className={'mb-3'} onClick={() => {
                                addNewPublication();
                            }}>
                                <IconText icon={'plus'} text={'Создать'}/>
                            </Button> : null}
                        {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction) ?
                            <Button variant={'secondary'} className={'mb-3'} onClick={() => {
                                addNewPublication({monitoring: true});
                            }}>
                                <IconText icon={'plus'} text={'Добавить в мониторинг'}/>
                            </Button> : null}
                    </div>
                </React.Fragment> : null}
            <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                              filtering={filtering}
                              data={publication} setFunc={setPublication} page={1} url={'/publication'}
                              url_update={'/publication/item'}
                              get_title={'publication'}
                              checkField={checkField}
                              permission={PERMISSIONS.MANAGE_PUB}
                              fields={FIELDS}
            />
        </React.Fragment>
    );
}

export default Publication;
