import React, {useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {AuthContext} from "../../auth";

const RoutePublic = ({component: Component, ...rest}) => {
    const user = useContext(AuthContext);

    const hasJWT = () => {
        return !!localStorage.getItem("token")
    }

    return (
        hasJWT() ?
            <Navigate to={{pathname: '/'}}/>
            :
            <Component/>
    );
};

export default RoutePublic;