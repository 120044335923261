import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {Alert, Button, Card, Col, Form, Row} from "react-bootstrap";
import {IconText} from "../../../components/icon_txt";
import {getRequest, putRequest, updateRequest} from "../../../actions/common";
import {
    REQUIRED_FIELDS, STATUS_ACCEPT, STATUS_PROCESS, STATUS_NEW,
    VALUES_TO_HISTORY
} from "./constant";
import {Area, Check, Input} from "../../../components/form/forms";
import {useAbac} from "react-abac";
import {PERMISSIONS} from "../../../rbac/constant";
import {btnMessage, btnHistory, findInSelectData} from "../../../utils/utils";
import {MyFileBrowser} from "../../../components/file_manager/file_manager";
import {Field, FormikProvider, useFormik} from "formik";
import {REQUIRED_FIELDS_TEMP} from "./constant";
import {FormikDate, FormikSelect} from "../../../components/form/formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";

export const StaffItem = ({
                              data,
                              setData,
                              user,
                              disabled,
                              messages,
                              handleChangeValue,
                              generateOptions,
                              ErrorsBlock,
                              MessageHistory,
                              ActionButtons,
                              DisableButton,
                              HeaderStatus,
                              handleSubmit,
                          }) => {
    const params = useParams();
    const navigate = useNavigate();
    const project_ref = useRef();

    const {userHasPermissions} = useAbac();
    const [new_history, setNewHistory] = useState(null);
    const [history, setHistory] = useState(null);
    const [select_data, setSelectData] = useState({});
    const select_fields = ['country', 'self_academic_degree', 'self_academic_rank', 'project', 'event', 'department',
        'job_contract', 'staff_category', 'staff_position', 'order'];
    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'age': 0,
        'last_name': '',
        'first_name': '',
        'middle_name': '',
        'birth_date': null,
        'dismissal_date': null,
        'snils': '',
        'inn': '',
        'is_supervisor': '',
        'employment_date': null,
        'contract_number': '',
        'contract_date': null,
        'employee_id': '',
        'personal_account': '',
        'work_group_order': null,
        'is_scientists_new': null,
        'participant_info': '',
        'acc_scopus': '',
        'acc_researcher': '',
        'h_index': '',
        'orcid': '',
        'pub_info': [],
        'patent_info': [],
        'postgraduate_info': {},
        'additional_info': '',
        'related_docs_url': '',
        'finance_category': null
    });

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: values => {
            return saveStaff()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false
    })

    useEffect(() => {
        handleSubmit(formik, saveStaff);
    }, [formik.values])


    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД Кадры | ИС «НЦМУ»`;
            let set_selected = {};
            select_fields.forEach((field) => {
                if (field === 'staff_category' || field === 'staff_position') {
                    set_selected[`staff_${field}`] = data[`staff_${field}`]?.map((element) => element.id)
                    if (field === 'staff_category') {
                        set_selected[`${field}_id`] = data[field]?.id
                    }
                } else {
                    set_selected[`${field}_id`] = data[field]?.id
                }
            });

            let set_inputs = {};
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }
            set_inputs['patent_info'] = typeof data?.patent_info === 'object' && data?.patent_info
            && Object.keys(data?.patent_info).length === 0
                ? []
                : data?.patent_info ? data?.patent_info : [];
            set_inputs['pub_info'] = typeof data?.pub_info === 'object' && data?.pub_info
            && Object.keys(data?.pub_info).length === 0
                ? []
                : data?.pub_info ? data?.pub_info : [];

            formik.setValues({...set_inputs, ...set_selected, current_status: data.status});

            let d = {};
            for (let value_n in VALUES_TO_HISTORY) {
                let value_m = VALUES_TO_HISTORY[value_n];
                let is_id = false;
                if (value_m.includes('_id')) {
                    is_id = true;
                }

                if (is_id == true) {
                    let tmp = value_m.substring(0, value_m.lastIndexOf('_id'));
                    if (data[tmp])
                        d[value_m] = data[tmp]?.id;
                } else if (data[value_m])
                    d[value_m] = data[value_m];

            }
            setNewHistory(d);
            if (history != null) {
                if (new_history && new_history != {} && history['empty'] == 1 && data.status == STATUS_ACCEPT) {
                    putRequest('staff_history', {
                        content: d,
                        created_by: user.id,
                        staff: data.id
                    }, false).then()
                }
            }
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
                for (let field of select_fields) {
                    if (!Object.keys(select_data).includes(field) && field !== 'event') {
                        getRequest(field, setSelectData, {type_data: 'all'}, '', select_data, field);
                        break
                    }

                    if (!select_data?.event?.length && field === 'event' && formik.values?.project_id) {
                        getRequest('event', setSelectData, {
                            type_data: 'all',
                            project: formik.values?.project_id
                        }, '', select_data, 'event').then(r => {
                            if (formik.values?.event_id && !findInSelectData(r.data, 'id', formik.values?.event_id)) {
                                setInputFields({...formik.values, event_id: null})
                            }
                        });
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values]);

    useEffect(() => {
        if (formik.values?.project_id !== project_ref.current && Object.keys(select_data).includes('event')) {
            setSelectData({...select_data, event: []})
        }
        if (formik.values?.project_id)
            project_ref.current = formik.values?.project_id;

        let d = {};
        for (let value_n in VALUES_TO_HISTORY) {
            let value_m = VALUES_TO_HISTORY[value_n];
            if (formik.values[value_m]) {
                d[value_m] = formik.values[value_m];
            }
        }
        setNewHistory(d);
    }, [formik.values])


    useEffect(() => {
        if (history == null) {
            getRequest(`staff_history`, setHistory, {type_data: 'all', staff_id: params.id}, '', history);
        }
    }, [history]);


    const getPubInfoPlaceholder = (index) => {
        if (data) {
            let pub_info = data?.pub_info;
            return pub_info ? pub_info[index]?.q1_q2 === 'on' || pub_info[index]?.q1_q2 === true ? 'Да' : 'Нет' : ''
        }
    }

    const saveStaff = () => {
        formik.setFieldValue('save_form', false);
        if (new_history && new_history != {} && data.status != STATUS_NEW) {
            putRequest('staff_history', {
                content: new_history,
                created_by: user.id,
                staff: data.id
            }, false).then()
            setNewHistory(null)
        }

        let payload = null;
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = {...formik.values, direction: user.id}
        } else if (STATUS_PROCESS === formik.values.status && formik.values.status > data?.status) {
            payload = {...formik.values, direction_staff: user.id}
        } else {
            payload = formik.values;
        }

        updateRequest('staff', {'common': payload}, params.id).then(response => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/staff')
                setData(response.data);
            } else if (response.status === 400) {
                if (response.data?.message)
                    toast.error(response.data?.message);
                if (response.data?.fields)
                    formik.setErrors({...formik.errors, ...response.data?.fields})
            }
        });

        if (formik?.values?.message) {
            putRequest('staff_messages', {
                content: formik?.values?.message,
                staff_status: data.status,
                status: data.status,
                created_by: user.id,
                staff: data.id
            }, false);
            document.getElementById('message').value = null;
            formik.setFieldValue('message', '');
        }

        return Promise.resolve('is_saved');
    }

    const AlertDiv = () => {
        let to_return = [];
        if (data && !data?.check_category) {
            to_return.push(<Alert variant={'warning'} key={'alert_category'}>
                Проверьте корректность:
                <ul>
                    <li>Категория в отчетность</li>
                    <li>Возможные категории по методическим указаниям</li>
                </ul>
            </Alert>);
        }
        if (data && data?.ncmuspecialist) {
            to_return.push(<Alert variant={'info'} key={'alert_specialist'}>
                Присутствует в БД Специалисты <Link to={`/specialist/item/${data?.ncmuspecialist}/?update=false`}>
                <FontAwesomeIcon icon={'eye'}/>
            </Link>
            </Alert>);
        }

        return to_return;
    }

    function PrintHistory() {
        let blockquotes = [];
        if (history != null && history.length != 0) {
            for (let key in history) {
                for (let item in history[key]) {
                    blockquotes.push(<blockquote className="blockquote">
                        <strong>{key}</strong>: {history[key][item]['value']}
                        <footer className="blockquote-footer">
                            Обновил: <strong>{history[key][item]['updated_at']}</strong>, {history[key][item]['updated_by']}.
                        </footer>
                    </blockquote>)
                }
            }
            return (<div className="history">
                {blockquotes}
            </div>)
        } else
            return (<div className="history">
                {blockquotes}
            </div>);
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <ReactBreadcrumb/>
                <Form>
                    <div className="section-header sticky-top" style={{zIndex: 2}}>
                        <h1 className="page-header">
                            {`Редактирование записи №${params.id} в БД Кадры`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_STAFF}
                                       permission_manage={PERMISSIONS.MANAGE_STAFF}
                        />
                    </div>
                    <div
                        className="mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center">
                        <HeaderStatus style={{minWidth: "250px"}}/>
                    </div>
                    <div className={'button-actions'}>
                        <Button variant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                                text={'Сообщения'}/></Button>
                        <Button variant={'info'} onClick={btnHistory}><IconText icon={'comment'}
                                                                                text={'История'}/></Button>
                    </div>

                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? {display: "block"} : {display: "none"}}
                          id={'card-message'}>
                        <Card.Body>
                            <div className="alert alert-danger"
                                 style={{display: formik.errors?.message ? "block" : "none"}}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id="field-mention" className="content-group navbar-nav px-0">
                                <div id="field-comment">
                                    <label htmlFor="staff_message">Комментарий</label>
                                    <Area id="message"
                                          name={"message"}
                                          disabled={disabled}
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          rows="5"
                                          value={formik?.values?.message}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mt-4'}
                          style={history && history?.length !== 0 || formik.errors?.history ? {display: "block"} : {display: "none"}}
                          id={'card-history'}>
                        <Card.Body>
                            <label>История изменений</label>
                            <PrintHistory/>

                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <AlertDiv/>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className="text-danger">красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className="text-success">зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className="badge badge-success">Сохранить</span> выполняет сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className="badge badge-success">Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                {data?.status >= STATUS_PROCESS && userHasPermissions(PERMISSIONS.DIRECTION_PANEL) ?
                                    <Check label={'Считать в показатель 1.1'}
                                           value={formik.values.is_scientists_new}
                                           handleChangeValue={formik.handleChange}
                                           id={'is_scientists_new'}
                                           name={'is_scientists_new'}
                                    />
                                    : null}
                            </fieldset>
                            <fieldset>
                                <legend>Личные данные</legend>
                                <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
                                    <Col>
                                        <Input handleChangeValue={formik.handleChange} label={'Фамилия'}
                                               name={'last_name'}
                                               id={'last_name'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('last_name') : false}
                                               error={formik.errors['last_name']}
                                               invalid={formik.errors['last_name']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.last_name : null}/>
                                    </Col>
                                    <Col>
                                        <Input handleChangeValue={formik.handleChange} label={'Имя'} id={'first_name'}
                                               name={'first_name'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('first_name') : false}
                                               error={formik.errors['first_name']}
                                               invalid={formik.errors['first_name']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.first_name : null}/>
                                    </Col>
                                    <Col>
                                        <Input handleChangeValue={formik.handleChange} label={'Отчество'}
                                               id={'middle_name'}
                                               name={'middle_name'}
                                               error={formik.errors['middle_name']}
                                               invalid={formik.errors['middle_name']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.middle_name : null}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата рождения'}
                                               id={'birth_date'}
                                               name={'birth_date'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('birth_date') : false}
                                               error={formik.errors['birth_date']}
                                               invalid={formik.errors['birth_date']}
                                               disabled={disabled}
                                               value={formik.values.birth_date}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input label={'Возраст'} id={'age'}
                                               disabled={true}
                                               name={'age'}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values.age : null}/>
                                    </Col>
                                    <Col md={12}>
                                        <Field component={FormikSelect}
                                               label={'Гражданство'}
                                               id={'country_id'}
                                               name={'country_id'}
                                               isSearchable={true}
                                               options={generateOptions('country', 'title_short', select_data)}
                                               error={formik.errors['country_id']}
                                               invalid={formik.errors['country_id']}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('country_id') : false}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'СНИЛС'} id={'snils'}
                                               name={'snils'}
                                               disabled={disabled}
                                               mask={'999-999-999 99'}
                                               error={formik.errors['snils']}
                                               invalid={formik.errors['snils']}
                                               value={formik.values ? formik.values?.snils : null}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'ИНН'} id={'inn'}
                                               name={'inn'}
                                               disabled={disabled}
                                               mask={'9999-999999-99'}
                                               error={formik.errors['inn']}
                                               invalid={formik.errors['inn']}
                                               value={formik.values ? formik.values?.inn : null}/>
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} label={'Ученая степень'}
                                               id={'self_academic_degree_id'}
                                               name={'self_academic_degree_id'}
                                               isSearchable={true}
                                               options={generateOptions('self_academic_degree', (element) => {
                                                   if (element?.title_short)
                                                       return `(${element.title_short}) ${element.title}`
                                                   else
                                                       return `${element.title}`
                                               }, select_data)}
                                               disabled={disabled}
                                               error={formik.errors['self_academic_degree_id']}
                                               invalid={formik.errors['self_academic_degree_id']}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('self_academic_degree_id') : false}/>
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} label={'Ученое звание'}
                                               id={'self_academic_rank_id'}
                                               name={'self_academic_rank_id'}
                                               isSearchable={true}
                                               options={generateOptions('self_academic_rank', (element) => {
                                                   if (element?.title_short)
                                                       return `(${element.title_short}) ${element.title}`
                                                   else
                                                       return `${element.title}`
                                               }, select_data)}
                                               disabled={disabled}
                                               error={formik.errors['self_academic_rank_id']}
                                               invalid={formik.errors['self_academic_rank_id']}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('self_academic_rank_id') : false}/>
                                    </Col>
                                </Row>
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>Данные о трудоустройстве</legend>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect} label={'Проект'} id={'project_id'}
                                               isSearchable={true}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               name={'project_id'}
                                               disabled={user?.roles.every((value) => value === 'project_manager') ? true : disabled}
                                               error={formik.errors['project_id']}
                                               invalid={formik.errors['project_id']}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('project_id') : false}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Направление'}
                                               id={'direction'}
                                               value={data ? data?.project?.direction : null}
                                               disabled={true}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} label={'Мероприятие'}
                                               id={'event_id'}
                                               isSearchable={true}
                                               options={generateOptions('event', (element) => {
                                                   if (element?.title_short)
                                                       return `(${element.title_short}) ${element.title}`
                                                   else
                                                       return `${element.title}`
                                               }, select_data)}
                                               name={'event_id'}
                                               disabled={disabled}
                                               error={formik.errors['event_id']}
                                               invalid={formik.errors['event_id']}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('event_id') : false}/>
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} label={'Форма привлечения работника'}
                                               id={'job_contract_id'}
                                               name={'job_contract_id'}
                                               isSearchable={true}
                                               options={generateOptions('job_contract', (element) => {
                                                   if (element?.title_short)
                                                       return `${element.title} (${element.title_short})`
                                                   else
                                                       return `${element.title}`
                                               }, select_data)}
                                               disabled={disabled}
                                               error={formik.errors['job_contract_id']}
                                               invalid={formik.errors['job_contract_id']}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('job_contract_id') : false}/>
                                    </Col>
                                </Row>
                                <Check label={'Руководитель исследования'}
                                       id={'is_supervisor'}
                                       name={'is_supervisor'}
                                       disabled={disabled}
                                       handleChangeValue={formik.handleChange}
                                       value={formik.values ? formik.values?.is_supervisor : null}/>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect} label={'Подразделение'}
                                               id={'department_id'}
                                               name={'department_id'}
                                               isSearchable={true}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('department_id') : false}
                                               options={generateOptions('department', (element) => {
                                                   if (element?.title_short)
                                                       return `${element.title} (${element.title_short})`
                                                   else
                                                       return `${element.title}`
                                               }, select_data)}
                                               disabled={disabled}
                                               error={formik.errors['department_id']}
                                               invalid={formik.errors['department_id']}
                                               small={'Подразделение, за которым закреплен сотрудник в рамках договора НЦМУ'}/>
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} label={'Должность(и)'}
                                               id={'staff_staff_position'}
                                               name={'staff_staff_position'}
                                               isSearchable={true}
                                               disabled={disabled}
                                               isMulti={true}
                                               closeMenuOnSelect={false}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('staff_staff_position') : false}
                                               options={generateOptions('staff_position', (element) => {
                                                   if (element?.title_short)
                                                       return `${element.title} (${element.title_short})`
                                                   else
                                                       return `${element.title}`
                                               }, select_data)}
                                               error={formik.errors['staff_staff_position']}
                                               invalid={formik.errors['staff_staff_position']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate} handleChangeValue={formik.handleChange}
                                               label={'Дата первого приема в СПбПУ'}
                                               id={'employment_date'}
                                               name={'employment_date'}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('employment_date') : false}
                                               error={formik.errors['employment_date']}
                                               invalid={formik.errors['employment_date']}
                                               value={formik.values?.employment_date}
                                               small={'Для подтверждения работника, впервые устроившегося в СПбПУ'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} label={'Приказ о включении в рабочую группу'}
                                               id={'order_id'}
                                               isSearchable={true}
                                               disabled={disabled}
                                               options={generateOptions('order', 'title', select_data)}
                                               name={'order_id'}
                                               required={false}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Номер договора'}
                                               disabled={disabled}
                                               id={'contract_number'}
                                               name={'contract_number'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('contract_number') : false}
                                               error={formik.errors['contract_number']}
                                               invalid={formik.errors['contract_number']}
                                               value={formik.values ? formik.values?.contract_number : null}
                                               small={'Номер действующего договора о трудоустройстве работника НЦМУ'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate} handleChangeValue={formik.handleChange}
                                               label={'Дата договора'}
                                               disabled={disabled}
                                               id={'contract_date'}
                                               name={'contract_date'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('contract_date') : false}
                                               error={formik.errors['contract_date']}
                                               invalid={formik.errors['contract_date']}
                                               value={formik.values?.contract_date}
                                               small={'Дата заключения действующего договора о трудоустройства работника НЦМУ'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Табельный номер'}
                                               disabled={disabled}
                                               id={'employee_id'}
                                               name={'employee_id'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('employee_id') : false}
                                               value={formik.values ? formik.values?.employee_id : null}
                                               error={formik.errors['employee_id']}
                                               invalid={formik.errors['employee_id']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Приказ о включении в рабочую группу'} id={'work_group_order'}
                                               required={false}
                                               disabled={true}
                                               value={formik.values ? formik.values?.work_group_order : null}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Лицевой счет'}
                                               disabled={disabled}
                                               id={'personal_account'}
                                               name={'personal_account'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('personal_account') : false}
                                               error={formik.errors['personal_account']}
                                               invalid={formik.errors['personal_account']}
                                               value={formik.values ? formik.values?.personal_account : null}
                                               small={'Лицевой счет, с которого производится выплата заработной платы работника'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Штатная единица (внешний источник)'} id={'unit_id'}
                                               name={'unit_id'}
                                               disabled={true}
                                               value={data ? data?.unit?.title : null}
                                               small={'Не заполняется'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               label={'Возможные категории по методическим указаниям'}
                                               closeMenuOnSelect={false}
                                               selectAllOption={true}
                                               isSearchable={true}
                                               isClearable={true}
                                               disabled={disabled}
                                               id={'staff_staff_category'}
                                               name={'staff_staff_category'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('staff_staff_category') : false}
                                               options={generateOptions('staff_category', (element) => {
                                                   if (element?.title_short)
                                                       return `${element.title} (${element.title_short})`
                                                   else
                                                       return `${element.title}`
                                               }, select_data, PERMISSIONS.CONTROL_STAFF)}
                                               isMulti={true}
                                               error={formik.errors['staff_staff_category']}
                                               invalid={formik.errors['staff_staff_category']}
                                               small={'Для внесения в это поле следует обратить внимание на заполнение полей «Возраст», «Гражданство», «Ученая степень», а также степень заполнения полей раздела «Дополнительная информация»\n'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate} handleChangeValue={formik.handleChange}
                                               label={'Дата увольнения'}
                                               disabled={disabled}
                                               id={'dismissal_date'}
                                               name={'dismissal_date'}
                                               errors={formik.errors?.dismissal_date}
                                               invalid={formik.errors?.dismissal_date}
                                               required={false}
                                               value={formik.values?.dismissal_date}/>
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} label={'Категория в отчетность'}
                                               id={'staff_category_id'}
                                               name={'staff_category_id'}
                                               isSearchable={true}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('staff_category_id') : false}
                                               options={generateOptions('staff_category', (element) => {
                                                   if (element?.title_short)
                                                       return `${element.title} (${element.title_short})`
                                                   else
                                                       return `${element.title}`
                                               }, select_data, PERMISSIONS.CONTROL_STAFF)}
                                               disabled={disabled}
                                               error={formik.errors['staff_category_id']}
                                               invalid={formik.errors['staff_category_id']}
                                               small={'Категория, к который Вы желаете отнести сотрудника в рамках выполнения показателей'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Категория в финансовую отчетность'}
                                               id={'finance_category'}
                                               name={'finance_category'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('finance_category') : false}
                                               disabled={true}
                                               value={formik.values ? formik.values?.finance_category : null}
                                               small={'Не заполняется'}
                                        />
                                    </Col>
                                </Row>
                                <Input handleChangeValue={formik.handleChange}
                                       disabled={disabled}
                                       label={'Отчет о реализации программы создания и развития центра'}
                                       id={'participant_info'} required={false}
                                       name={'participant_info'}
                                       value={formik.values ? formik.values?.participant_info : null}
                                       small={'Раздел годового отчета, над которым работает сотрудник'}
                                />
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>Наукометрические показатели</legend>
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Scopus ID'}
                                               id={'acc_scopus'}
                                               name={'acc_scopus'}
                                               required={false}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.acc_scopus : null}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Researcher ID'}
                                               id={'acc_researcher'} required={false}
                                               name={'acc_researcher'}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.acc_researcher : null}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Индекс Хирша'}
                                               id={'h_index'} required={false}
                                               disabled={disabled}
                                               name={'h_index'}
                                               value={formik.values ? formik.values?.h_index : null}
                                               small={'Любой из баз Scopus/WoS/РИНЦ/GoogleScholar'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'ORCID'}
                                               id={'orcid'} required={false}
                                               name={'orcid'}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.orcid : null}
                                        />
                                    </Col>
                                </Row>
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>Дополнительная информация</legend>
                                <ul>
                                    <li>для подтверждения <strong>ВУ</strong> – 1 статья Q1/Q2 или 1 патент на
                                        изобретение
                                        за
                                        рубежом за последние
                                        3 года
                                    </li>
                                    <li>для подтверждения <strong>МПИ</strong> – ученая степень, 2 статьи, рецензируемых
                                        в
                                        международных базах или
                                        2 патента на изобретение РФ/за рубежом за последние 3 года, возраст до 39 лет
                                        включительно
                                    </li>
                                    <li>для подтверждения <strong>АСПДС</strong> – указать наименование образовательной
                                        организации и наименование
                                        субъекта РФ
                                    </li>
                                    <li>для подтверждения <strong>АСП</strong> и <strong>ИАСП</strong> – указать
                                        реквизиты
                                        приказа об аспирантуре
                                    </li>
                                </ul>
                                <p className="lead">Публикации</p>
                                <p>Публикация 1</p>
                                <Row>
                                    <Col md={3}>
                                        <Input label={'Наименование публикации'}
                                               id={'pub_info.0.title'}
                                               name={'pub_info.0.title'}
                                               disabled={disabled}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values?.pub_info ? formik.values?.pub_info[0]?.title : '' : ''}/>
                                    </Col>
                                    <Col md={3}>
                                        <Input label={'DOI публикации'}
                                               id={'pub_info.0.doi'}
                                               name={'pub_info.0.doi'}
                                               mask={'10.9999/*???????????????????????'}
                                               formatChars={{9: '[0-9]', '?': '[A-Za-z0-9 ]', '*': '[A-Za-z0-9]'}}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.pub_info ? formik.values?.pub_info[0]?.doi : '' : ''}
                                               handleChangeValue={formik.handleChange}/>
                                    </Col>
                                    <Col md={3}>
                                        <Input label={'Год публикации'}
                                               id={'pub_info.0.year'}
                                               name={'pub_info.0.year'}
                                               type={'year'}
                                               disabled={disabled}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values?.pub_info ? formik.values?.pub_info[0]?.year : '' : ''}/>
                                    </Col>
                                    <Col md={3}>
                                        <Field component={FormikSelect} label={'Q1/Q2'}
                                               id={'pub_info.0.q1_q2'}
                                               name={'pub_info.0.q1_q2'}
                                               disabled={disabled}
                                               options={[
                                                   {value: 'off', label: 'Нет'},
                                                   {value: 'on', label: 'Да'}
                                               ]}
                                               placeholder={getPubInfoPlaceholder(0)}
                                        />
                                    </Col>
                                </Row>
                                <p>Публикация 2</p>
                                <Row>
                                    <Col md={3}>
                                        <Input label={'Наименование публикации'}
                                               id={'pub_info.1.title'}
                                               name={'pub_info.1.title'}
                                               disabled={disabled}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values?.pub_info ? formik.values?.pub_info[1]?.title : '' : ''}/>
                                    </Col>
                                    <Col md={3}>
                                        <Input label={'DOI публикации'}
                                               id={'pub_info.1.doi'}
                                               name={'pub_info.1.doi'}
                                               mask={'10.9999/*???????????????????????'}
                                               formatChars={{9: '[0-9]', '?': '[A-Za-z0-9 ]', '*': '[A-Za-z0-9]'}}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.pub_info ? formik.values?.pub_info[1]?.doi : '' : ''}
                                               handleChangeValue={formik.handleChange}/>
                                    </Col>
                                    <Col md={3}>
                                        <Input label={'Год публикации'}
                                               id={'pub_info.1.year'}
                                               name={'pub_info.1.year'}
                                               type={'year'}
                                               disabled={disabled}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values?.pub_info ? formik.values?.pub_info[1]?.year : '' : ''}/>
                                    </Col>
                                    <Col md={3}>
                                        <Field component={FormikSelect} label={'Q1/Q2'}
                                               id={'pub_info.1.q1_q2'}
                                               name={'pub_info.1.q1_q2'}
                                               disabled={disabled}
                                               options={[
                                                   {value: 'off', label: 'Нет'},
                                                   {value: 'on', label: 'Да'}
                                               ]}
                                               placeholder={getPubInfoPlaceholder(1)}
                                        />
                                    </Col>
                                </Row>
                                <p className="lead">Патенты</p>
                                <p>Патент 1</p>
                                <Row>
                                    <Col md={6}>
                                        <Input label={'Название'}
                                               id={'patent_info.0.title'}
                                               name={'patent_info.0.title'}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.patent_info ? formik.values?.patent_info[0]?.title : '' : ''}
                                               handleChangeValue={formik.handleChange}/>
                                    </Col>
                                    <Col>
                                        <Input label={'Год'}
                                               id={'patent_info.0.year'}
                                               name={'patent_info.0.year'}
                                               type={'year'}
                                               data_list_index={0}
                                               disabled={disabled}
                                               data_foreign_id={'patent_info'}
                                               value={formik.values ? formik.values?.patent_info ? formik.values?.patent_info[0]?.year : '' : ''}
                                               handleChangeValue={formik.handleChange}/>
                                    </Col>
                                </Row>
                                <p>Патент 2</p>
                                <Row>
                                    <Col md={6}>
                                        <Input label={'Название'}
                                               id={'patent_info.1.title'}
                                               name={'patent_info.1.title'}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.patent_info ? formik.values?.patent_info[1]?.title : '' : ''}
                                               handleChangeValue={formik.handleChange}/>
                                    </Col>
                                    <Col>
                                        <Input label={'Год'}
                                               id={'patent_info.1.year'}
                                               name={'patent_info.1.year'}
                                               type={'year'}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.patent_info ? formik.values?.patent_info[1]?.year : '' : ''}
                                               handleChangeValue={formik.handleChange}/>
                                    </Col>
                                </Row>
                                <p className="lead">Данные об аспирантуре</p>
                                <Row>
                                    <Col md={4}>
                                        <Input handleChangeValue={formik.handleChange}
                                               id={'postgraduate_info.organization'}
                                               name={'postgraduate_info.organization'}
                                               label={'Наименование образовательной организации'}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.postgraduate_info ? formik.values?.postgraduate_info?.organization : null : null}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Наименование субъекта РФ'}
                                               id={'postgraduate_info.subject'}
                                               name={'postgraduate_info.subject'}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.postgraduate_info ? formik.values?.postgraduate_info.subject : null : null}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Номер и дата приказа о зачислении в аспирантуру'}
                                               id={'postgraduate_info.order'}
                                               name={'postgraduate_info.order'}
                                               value={formik.values ? formik.values?.postgraduate_info ? formik.values?.postgraduate_info.order : null : null}
                                               disabled={true}/>
                                    </Col>
                                </Row>
                                <hr/>
                                <Input handleChangeValue={formik.handleChange}
                                       label={'Ссылка на сопутствующие документы'}
                                       id={'related_docs_url'}
                                       name={'related_docs_url'}
                                       value={formik.values ? formik.values?.related_docs_url : null}
                                       disabled={disabled}
                                       small={'Гранки публикаций, Свидетельства о получении патентов, Приказ о включении в рабочую группу, Приказ о зачислении в аспирантуру'}/>
                                <Area label={'Дополнительная информация'} id={'additional_info'}
                                      name={'additional_info'}
                                      handleChangeValue={formik.handleChange}
                                      required={false}
                                      disabled={disabled}
                                      value={formik.values ? formik.values?.additional_info : null}
                                />
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Ученая степень (внешний источник)'}
                                               disabled={true}
                                               small={'Не заполняется'}
                                               value={formik.values ? formik.values?.academic_degree ? formik.values?.academic_degree.title : null : null}
                                        />

                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Ученое звание (внешний источник)'}
                                               disabled={true}
                                               small={'Не заполняется'}
                                               value={formik.values ? formik.values?.academic_rank ? formik.values?.academic_degree.title : null : null}
                                        />
                                    </Col>
                                </Row>
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>Прикрепленные файлы</legend>
                                <hr/>
                                <MyFileBrowser path={`root/storage/staff/staff_${params.id}`}
                                               instanceId={`staff_${params.id}`}
                                               read_only={disabled}
                                />
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    );
}