import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import {getRequest, putRequest} from "../../../actions/common";
import {REQUIRED_FIELDS, STATUS_ACCEPT, STATUS_APPROVE, STATUS_NEW, STATUS_PROCESS} from "./constant";
import {Input, Select, Area, Check} from "../../../components/form/forms";
import {useAbac} from "react-abac";
import {PERMISSIONS} from "../../../rbac/constant";
import {AuthContext} from "../../../auth";
import {toast} from "react-toastify";
import {setChangedValue} from "../../../utils/utils";

export const StaffCreate = (props) => {
    const {state} = useLocation();
    const {userHasPermissions} = useAbac();
    const navigate = useNavigate();
    const user = useContext(AuthContext);

    const [input_values, setInputValues] = useState({});
    const [specialist, setSpecialist] = useState(null);
    const [select_data, setSelectData] = useState({});
    const [select_values, setSelectValues] = useState({project: user?.project?.id});
    const [errors, setErrors] = useState(() => {
        let temp = {};
        REQUIRED_FIELDS[STATUS_ACCEPT].forEach((field) => {
            temp[field] = null;
        })
        temp['project'] = null;
        return temp;
    })

    const select_fields = ['country', 'self_academic_degree', 'self_academic_rank', 'project', 'event', 'department', 'job_contract', 'staff_category'];

    useEffect(() => {
        if (!userHasPermissions(PERMISSIONS.MANAGER_PANEL)) {
            navigate('/access-error');
        } else {
            document.title = `Создание записи в БД Кадры | ИС «НЦМУ»`;
            if (user?.project?.id) {
                setSelectValues({...select_values, project: user?.project?.id})
            }
        }
    });

    useEffect(() => {
        if (!specialist && state?.person) {
            getRequest('person', setSpecialist, {}, state.person)
        }
    }, [state])

    useEffect(() => {
        if (Object.keys(select_data).length < select_fields.length) {
            for (let field of select_fields) {
                if (!Object.keys(select_data).includes(field)) {
                    getRequest(field, setSelectData, {type_data: 'all'}, '', select_data, field);
                    break
                }
            }
        }
    }, [select_data]);

    useEffect(() => {
        if (select_values['0_q1_q2']) {
            setChangedValue('common', 'q1_q2', select_values['0_q1_q2'], 'pub_info', 0);
        }
        if (select_values['1_q1_q2']) {
            setChangedValue('common', 'q1_q2', select_values['1_q1_q2'], 'pub_info', 1);
        }
    }, [select_values])


    const getStatusClassName = (status) => {
        if (status === 1) {
            return 'bg-primary'
        } else if (status < 1) {
            return 'bg-success'
        } else if (status > 1) {
            return 'bg-secondary'
        }

        return null;
    }

    const handleChangeValue = (e) => {
        e.preventDefault();
        let target = e.target.getAttribute('data_change_key');
        let sub_key = e.target.getAttribute('data_foreign_id');
        let list_index = e.target.getAttribute('data_list_index');
        setChangedValue(setInputValues, input_values, target, e.target.id, e.target.value, sub_key, list_index);
    }

    const generateOptions = (field) => {
        if (select_data && Object.keys(select_data).includes(field)) {
            let options = [];
            for (let element of select_data[field]) {
                options.push({value: element.id, label: element.title});
            }
            return options;
        }
        return null;
    }

    const handleSumitForm = (event) => {
        event.preventDefault();
        let new_errors = {};
        let has_errors = false;
        REQUIRED_FIELDS[STATUS_NEW].forEach((field) => {
            if (!event.target[field]?.value && !select_values[field]) {
                new_errors[field] = 'Обязательное поле!';
                has_errors = true;
            } else {
                new_errors[field] = null;
            }
        })
        setErrors({...errors, ...new_errors})
        if (has_errors) {
            toast.error('Ошибка в заполнении данных!');
            return null
        }

        let payload = {};
        for (let element in event.target) {
            if (event.target[element]?.value) {
                payload[event.target[element].id] = event.target[element].value;
            }
        }
        payload['status'] = state?.monitoring ? STATUS_ACCEPT : STATUS_NEW;
        payload = {...payload, ...select_values}
        putRequest('staff', payload).then(response => {
            if (response.status === 201) {
                navigate(`/staff/item/${response.data.id}?update=true`)
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <Form onSubmit={handleSumitForm}>
                <div className="section-header sticky-top">
                    <h1 className="page-header">
                        {`Coздание записи в БД Кадры`}
                    </h1>
                    <div className="button-actions">
                        <Button variant='success' type='submit'>Создать</Button>
                    </div>
                </div>
                <div
                    className="mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center">
                    <div className="mx-2 mb-2" style={{minWidth: "250px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_NEW)}`}>Новый</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{minWidth: "250px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_APPROVE)}`}>Рассматривается</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{minWidth: "250px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_PROCESS)}`}>Обрабатывается</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{minWidth: "250px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_ACCEPT)}`}>Принято</p>
                    </div>
                </div>
                <Card className={'bg-light mb-3 mt-3'}>
                    <Card.Body>
                        <p>
                            <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                            условные обозначения,
                            используемые в интерфейсе:
                        </p>
                        <ul>
                            <li>поля помеченные <strong className="text-danger">красной «*»</strong> являются <strong>обязательными
                                на текущем
                                этапе для перехода на следующий</strong>;
                            </li>
                            <li>поля помеченные <strong className="text-success">зелёной «*»</strong> являются <strong>обязательными
                                для
                                определенных условий</strong>;
                            </li>
                            <li>кнопка <span className="badge badge-success">Сохранить</span> выполняет сохранение
                                текущего состояния карточки (требуется
                                заполнения ключевых полей);
                            </li>
                            <li>кнопка <span
                                className="badge badge-success">Сохранить + отправить далее</span> сохраняет карточку и
                                проверяет выполнение
                                условий текущего этапа (при выполнении условий карточка переходит на следующий этап).
                            </li>
                        </ul>
                        <hr/>
                        <fieldset>
                            <legend>Личные данные</legend>
                            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
                                <Col>
                                    <Input handleChangeValue={handleChangeValue} label={'Фамилия'} id={'last_name'}
                                           required={true}
                                           error={errors['last_name']}
                                           invalid={errors['last_name']}
                                           value={specialist ? specialist.results[0].last_name : null}/>
                                </Col>
                                <Col>
                                    <Input handleChangeValue={handleChangeValue} label={'Имя'} id={'first_name'}
                                           required={true}
                                           error={errors['first_name']}
                                           invalid={errors['first_name']}
                                           value={specialist ? specialist.results[0].first_name : null}/>
                                </Col>
                                <Col>
                                    <Input handleChangeValue={handleChangeValue} label={'Отчество'} id={'middle_name'}
                                           value={specialist ? specialist.results[0].middle_name : null}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Дата рождения'}
                                           id={'birth_date'}
                                           required={true}
                                           type={'date'}
                                           error={errors['birth_date']}
                                           invalid={errors['birth_date']}
                                           value={specialist ? specialist.results[0].birth_date : null}/>
                                </Col>
                                <Col md={6}>
                                    <Input label={'Возраст'} id={'age'} disabled={true}
                                           value={specialist ? specialist.results[0].age : null}/>
                                </Col>
                                <Col md={12}>
                                    <Select label={'Гражданство'} id={'country'}
                                            options={generateOptions('country')}
                                            placeholder={specialist ? specialist.results[0]?.country?.title : ''}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            required={true}/>
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'СНИЛС'} id={'snils'}
                                           mask={'999-999-999 99'}
                                           value={specialist ? specialist.results[0].snils : null}/>
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'ИНН'} id={'inn'}
                                           value={specialist ? specialist.results[0].inn : null}/>
                                </Col>
                                <Col md={6}>
                                    <Select label={'Ученая степень'} id={'self_academic_degree'}
                                            options={generateOptions('self_academic_degree')}
                                            placeholder={specialist ? specialist.results[0]?.self_academic_degree?.title : ''}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            required={false}/>
                                </Col>
                                <Col md={6}>
                                    <Select label={'Ученое звание'} id={'self_academic_rank'}
                                            options={generateOptions('self_academic_rank')}
                                            placeholder={specialist ? specialist.results[0]?.self_academic_rank?.title : ''}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            required={false}/>
                                </Col>
                            </Row>
                            <hr/>
                        </fieldset>
                        <fieldset>
                            <legend>Данные о трудоустройстве</legend>
                            <Row>
                                <Col md={6}>
                                    <Select label={'Проект'} id={'project'}
                                            options={generateOptions('project')}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['project']}
                                            invalid={errors['project']}
                                            disabled={user ? user?.project?.id : false}
                                            required={true}/>
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Направление'} id={'direction'}
                                           value={user?.project?.direction}
                                           disabled={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Select label={'Мероприятие'} id={'event'}
                                            options={generateOptions('event')}
                                            placeholder={specialist ? specialist.results[0]?.event?.title : ''}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            required={false}/>
                                </Col>
                                <Col md={6}>
                                    <Select label={'Форма привлечения работника'} id={'job_contract'}
                                            options={generateOptions('job_contract')}
                                            placeholder={specialist ? specialist.results[0]?.job_contract?.title : ''}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            required={false}/>
                                </Col>
                            </Row>
                            <Check label={'Руководитель исследования'} id={'is_supervisor'}
                                   value={specialist ? specialist.results[0].is_supervisor : null}/>
                            <Row>
                                <Col md={6}>
                                    <Select label={'Подразделение'} id={'department'}
                                            required={false}
                                            options={generateOptions('department')}
                                            placeholder={specialist ? specialist.results[0]?.department?.title : ''}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            small={'Подразделение, за которым закреплен сотрудник в рамках договора НЦМУ'}/>
                                </Col>
                                <Col md={6}>
                                    <div className='form-group'>
                                        <Form.Label>Должность(и)</Form.Label>
                                        <Form.Control id={'middle_name'} disabled={true}/>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Дата первого приема в СПбПУ'}
                                           id={'employment_date'} required={false}
                                           type={'date'}
                                           value={specialist ? specialist.results[0].employment_date : null}
                                           small={'Для подтверждения работника, впервые устроившегося в СПбПУ'}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Select label={'Приказ о включении в рабочую группу'} id={'work_group_order'}
                                            options={generateOptions('work_group_order')}
                                            placeholder={specialist ? specialist.results[0]?.work_group_order?.title : ''}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            required={false}/>
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Номер договора'}
                                           id={'contract_number'}
                                           required={true}
                                           value={specialist ? specialist.results[0].contract_number : null}
                                           small={'Номер действующего договора о трудоустройстве работника НЦМУ'}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Дата договора'}
                                           id={'contract_date'} required={true}
                                           type={'date'}
                                           value={specialist ? specialist.results[0].contract_date : null}
                                           small={'Дата заключения действующего договора о трудоустройства работника НЦМУ'}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Табельный номер'}
                                           id={'employee_id'} required={false}
                                           value={specialist ? specialist.results[0].employee_id : null}/>
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Приказ о включении в рабочую группу'} id={'work_group_order'}
                                           required={false}
                                           disabled={true}
                                           value={specialist ? specialist.results[0].work_group_order : null}/>
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Лицевой счет'}
                                           id={'personal_account'} required={false}
                                           value={specialist ? specialist.results[0].personal_account : null}
                                           small={'Лицевой счет, с которого производится выплата заработной платы работника'}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Штатная единица (внешний источник)'} id={'work_group_order'}
                                           required={false}
                                           disabled={true}
                                           value={specialist ? specialist.results[0].work_group_order : null}
                                           small={'Не заполняется'}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Select label={'Возможные категории по методическим указаниям'}
                                            id={'staff_category'}
                                            required={false}
                                            options={generateOptions('staff_category')}
                                            placeholder={specialist ? specialist.results[0]?.staff_category?.title : ''}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            small={'Для внесения в это поле следует обратить внимание на заполнение полей «Возраст», «Гражданство», «Ученая степень», а также степень заполнения полей раздела «Дополнительная информация»\n'}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Дата увольнения'}
                                           id={'dismissal_date'} required={false}
                                           type={'date'}
                                           value={specialist ? specialist.results[0].dismissal_date : null}/>
                                </Col>
                                <Col md={6}>
                                    <Select label={'Категория в отчетность'} id={'report_category'}
                                            required={true}
                                            options={generateOptions('report_category')}
                                            placeholder={specialist ? specialist.results[0]?.report_category?.title : ''}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            small={'Категория, к который Вы желаете отнести сотрудника в рамках выполнения показателей'}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Категория в финансовую отчетность'}
                                           id={'finance_category'}
                                           required={false}
                                           disabled={true}
                                           value={specialist ? specialist.results[0].finance_category : null}
                                           small={'Не заполняется'}
                                    />
                                </Col>
                            </Row>
                            <Input handleChangeValue={handleChangeValue}
                                   label={'Отчет о реализации программы создания и развития центра'}
                                   id={'participant_info'} required={false}
                                   value={specialist ? specialist.results[0].participant_info : null}
                                   small={'Раздел годового отчета, над которым работает сотрудник'}
                            />
                            <hr/>
                        </fieldset>
                        <fieldset>
                            <legend>Наукометрические показатели</legend>
                            <Row>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Scopus ID'} id={'acc_scopus'}
                                           required={false}
                                           value={specialist ? specialist.results[0].acc_scopus : null}/>
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Researcher ID'}
                                           id={'acc_researcher'} required={false}
                                           value={specialist ? specialist.results[0].acc_researcher : null}/>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Индекс Хирша'}
                                           id={'h_index'} required={false}
                                           value={specialist ? specialist.results[0].h_index : null}
                                           small={'Любой из баз Scopus/WoS/РИНЦ/GoogleScholar'}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'ORCID'}
                                           id={'orcid'} required={false}
                                           value={specialist ? specialist.results[0].orcid : null}
                                    />
                                </Col>
                            </Row>
                            <hr/>
                        </fieldset>
                        <fieldset>
                            <legend>Дополнительная информация</legend>
                            <ul>
                                <li>для подтверждения <strong>ВУ</strong> – 1 статья Q1/Q2 или 1 патент на изобретение
                                    за
                                    рубежом за последние
                                    3 года
                                </li>
                                <li>для подтверждения <strong>МПИ</strong> – ученая степень, 2 статьи, рецензируемых в
                                    международных базах или
                                    2 патента на изобретение РФ/за рубежом за последние 3 года, возраст до 39 лет
                                    включительно
                                </li>
                                <li>для подтверждения <strong>АСПДС</strong> – указать наименование образовательной
                                    организации и наименование
                                    субъекта РФ
                                </li>
                                <li>для подтверждения <strong>АСП</strong> и <strong>ИАСП</strong> – указать реквизиты
                                    приказа об аспирантуре
                                </li>
                            </ul>
                            <p className="lead">Публикации</p>
                            <p>Публикация 1</p>
                            <Row>
                                <Col md={3}>
                                    <Input label={'Наименование публикации'}
                                           id={'title'}
                                           data_list_index={0}
                                           data_foreign_id={'pub_info'}
                                           value={specialist ? specialist.results[0].pub_info ? specialist.results[0]?.pub_info[0]?.title : '' : ''}
                                           handleChangeValue={handleChangeValue}/>
                                </Col>
                                <Col md={3}>
                                    <Input label={'DOI публикации'}
                                           id={'doi'}
                                           data_list_index={0}
                                           data_foreign_id={'pub_info'}
                                           value={specialist ? specialist.results[0].pub_info ? specialist.results[0]?.pub_info[0]?.doi : '' : ''}
                                           handleChangeValue={handleChangeValue}/>
                                </Col>
                                <Col md={3}>
                                    <Input label={'Год публикации'}
                                           id={'year'}
                                           data_list_index={0}
                                           data_foreign_id={'pub_info'}
                                           value={specialist ? specialist.results[0].pub_info ? specialist.results[0]?.pub_info[0]?.year : '' : ''}
                                           handleChangeValue={handleChangeValue}/>
                                </Col>
                                <Col md={3}>
                                    <Select label={'Q1/Q2'}
                                            id={'0_q1_q2'}
                                            data_list_index={0}
                                            data_foreign_id={'pub_info'}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            options={[
                                                {value: 'off', label: 'Нет'},
                                                {value: 'on', label: 'Да'}
                                            ]}
                                    />
                                </Col>
                            </Row>
                            <p>Публикация 2</p>
                            <Row>
                                <Col md={3}>
                                    <Input label={'Наименование публикации'}
                                           id={'title'}
                                           data_list_index={1}
                                           data_foreign_id={'pub_info'}
                                           value={specialist ? specialist.results[0].pub_info ? specialist.results[0]?.pub_info[1]?.title : '' : ''}
                                           handleChangeValue={handleChangeValue}/>
                                </Col>
                                <Col md={3}>
                                    <Input label={'DOI публикации'}
                                           id={'doi'}
                                           data_list_index={1}
                                           data_foreign_id={'pub_info'}
                                           value={specialist ? specialist.results[0].pub_info ? specialist.results[0]?.pub_info[1]?.doi : '' : ''}
                                           handleChangeValue={handleChangeValue}/>
                                </Col>
                                <Col md={3}>
                                    <Input label={'Год публикации'}
                                           id={'year'}
                                           data_list_index={1}
                                           data_foreign_id={'pub_info'}
                                           value={specialist ? specialist.results[0].pub_info ? specialist.results[0]?.pub_info[1]?.year : '' : ''}
                                           handleChangeValue={handleChangeValue}/>
                                </Col>
                                <Col md={3}>
                                    <Select label={'Q1/Q2'}
                                            id={'1_q1_q2'}
                                            data_list_index={1}
                                            data_foreign_id={'pub_info'}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            options={[
                                                {value: 'off', label: 'Нет'},
                                                {value: 'on', label: 'Да'}
                                            ]}
                                    />
                                </Col>
                            </Row>
                            <p className="lead">Патенты</p>
                            <p>Патент 1</p>
                            <Row>
                                <Col md={6}>
                                    <Input label={'Название'}
                                           id={'title'}
                                           data_list_index={0}
                                           data_foreign_id={'patent_info'}
                                           value={specialist ? specialist.results[0].patent_info ? specialist.results[0]?.patent_info[0]?.title : '' : ''}
                                           handleChangeValue={handleChangeValue}/>
                                </Col>
                                <Col>
                                    <Input label={'Год'}
                                           id={'year'}
                                           data_list_index={0}
                                           data_foreign_id={'patent_info'}
                                           value={specialist ? specialist.results[0].patent_info ? specialist.results[0]?.patent_info[0]?.year : '' : ''}
                                           handleChangeValue={handleChangeValue}/>
                                </Col>
                            </Row>
                            <p>Патент 2</p>
                            <Row>
                                <Col md={6}>
                                    <Input label={'Название'}
                                           id={'title'}
                                           data_list_index={1}
                                           data_foreign_id={'patent_info'}
                                           value={specialist ? specialist.results[0].patent_info ? specialist.results[0]?.patent_info[1]?.year : '' : ''}
                                           handleChangeValue={handleChangeValue}/>
                                </Col>
                                <Col>
                                    <Input label={'Год'}
                                           id={'year'}
                                           data_list_index={1}
                                           data_foreign_id={'patent_info'}
                                           value={specialist ? specialist.results[0].patent_info ? specialist.results[0]?.patent_info[1]?.year : '' : ''}
                                           handleChangeValue={handleChangeValue}/>
                                </Col>
                            </Row>
                            <p className="lead">Данные об аспирантуре</p>
                            <Row>
                                <Col md={4}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Наименование образовательной организации'}
                                           data_change_key={'common'}
                                           value={specialist ? specialist.results[0].postgraduate_info ? specialist.results[0].postgraduate_info.organization : null : null}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input handleChangeValue={handleChangeValue} label={'Наименование субъекта РФ'}
                                           data_change_key={'common'}
                                           value={specialist ? specialist.results[0].postgraduate_info ? specialist.results[0].postgraduate_info.subject : null : null}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Номер и дата приказа о зачислении в аспирантуру'}
                                           data_change_key={'common'}
                                           value={specialist ? specialist.results[0].postgraduate_info ? specialist.results[0].postgraduate_info.order : null : null}
                                           disabled={true}/>
                                </Col>
                            </Row>
                            <hr/>
                            <Input handleChangeValue={handleChangeValue} label={'Ссылка на сопутствующие документы'}
                                   value={specialist ? specialist.results[0].related_docs_url : null}
                                   small={'Гранки публикаций, Свидетельства о получении патентов, Приказ о включении в рабочую группу, Приказ о зачислении в аспирантуру'}/>
                            <Area label={'Дополнительная информация'} id={'additional_info'}
                                  required={false}
                                  value={specialist ? specialist.results[0].additional_info : null}
                            />
                            <Row>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Ученая степень (внешний источник)'}
                                           disabled={true}
                                           small={'Не заполняется'}
                                           value={specialist ? specialist.results[0].academic_degree ? specialist.results[0].academic_degree.title : null : null}
                                    />

                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Ученое звание (внешний источник)'}
                                           disabled={true}
                                           small={'Не заполняется'}
                                           value={specialist ? specialist.results[0].academic_rank ? specialist.results[0].academic_rank.title : null : null}
                                    />
                                </Col>
                            </Row>
                            <hr/>
                        </fieldset>
                    </Card.Body>
                </Card>
            </Form>
        </React.Fragment>
    );
}
