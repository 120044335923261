import * as React from "react";
import ReactBreadcrumb from "../../components/breadcrumbs";
import {useEffect, useState} from "react";
import {
    Button,
    Card,
    Col,
    Row
} from "react-bootstrap";
import {IconText} from "../../components/icon_txt";
import {getRequest} from "../../actions/common";
import ReactTable from "../../components/tables/react_table";
import {Input} from "../../components/form/forms";
import {ROLES_LABELS} from "../../rbac/constant";
import {Link} from "react-router-dom";

const Users = () => {
    const [users, setUsers] = useState(null);
    const [filters_value, setFiltersValue] = useState({});

    const filters_fields = ['id', 'username', 'email', 'status', 'is_active'];
    const labels = {
        'id': 'ID',
        'fio': 'ФИО',
        'username': 'Логин',
        'email': 'Email адрес',
        'is_active': 'Доступ',
        'status': 'Статус',
        'time_create': 'Время создания',
        'last_login': 'Время последнего визита',
        'ip_create': 'IP при создании',
        'ip_last': 'IP последнего визита'
    };

    React.useEffect(() => {
        document.title = 'Пользователи | ИС «НЦМУ»';
    });

    useEffect(() => {
        if (!users) {
            getRequest('users', setUsers);
        }
    }, [users]);

    const btnFilter = () => {
        let card = document.getElementById('card-filter');
        if (card && card.style.display === 'none') {
            card.style.display = 'block';
        } else if (card && card.style.display === 'block') {
            card.style.display = 'none';
        }
    }

    const refreshFilter = () => {
        filters_fields.forEach((field) => {
            document.getElementById(field).value = '';
        })
        getRequest('users', setUsers);
    }

    const onChangeInput = (e) => {
        e.preventDefault();
        filters_value[e.target.id] = e.target.value;
    }

    const onSubmitForm = (e) => {
        e.preventDefault();
        let data = {};
        const inputs = Array.from(e.target);
        inputs.forEach(({id, value}) => {
            if (value) {
                data[id] = value;
            }
        });
        setFiltersValue({});
        getRequest('users', setUsers, data);
    }

    const additionalCheckField = (field, key) => {
        if (key === 'status') {
            if (field === 1) {
                return <span className="badge badge-success">Зарегистрированный</span>
            } else {
                return <span className="badge badge-danger">Неопределенный</span>
            }
        }
        if (users && key === 'fio') {
            let find_user = null;
            for (let user in users.results) {
                if (users.results[user].fio === field) {
                    find_user = users.results[user];
                    break
                }
            }
            if (find_user) {
                let elements = [<div key={'fio'}>{field}</div>];
                find_user.roles.forEach((role) => {
                    elements.push(<span key={role}
                                        className={`badge ${role === 'admin' ? 'badge-danger' : 'badge-secondary'} mt-1 mb-1`}>
                        {ROLES_LABELS[role]}
                    </span>);
                })
                return <div>{elements}</div>
            }
            return field
        }
        return null;
    }

    const FilterInputs = () => {
        if (filters_fields) {
            let elements = [];
            filters_fields.forEach((field) => {
                elements.push(
                    <Col key={field}>
                        <Input label={labels[field]} id={field} handleChangeValue={onChangeInput}/>
                    </Col>
                )
            })
            return elements;
        }
        return null;
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={"page-header"}>
                Пользователи
            </h1>

            <div className="button-actions mb-3">
                <Button variant="secondary" onClick={btnFilter}>
                    <IconText text={'Форма поиска'} icon={'search'}/>
                </Button>
                <Link to={'/users/create'}>
                    <Button variant="success">
                        <IconText text={'Создать'} icon={'plus'}/>
                    </Button>
                </Link>
            </div>

            <Card style={{display: 'none'}} id='card-filter'>
                <form onSubmit={onSubmitForm}>
                    <Card.Body>
                        <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3'>
                            <FilterInputs/>
                        </Row>

                    </Card.Body>
                    <Card.Footer>
                        <div className={'button-actions'}>
                            <Button type='submit'>Найти</Button>
                            <Button variant='secondary' onClick={refreshFilter}>Сбросить</Button>
                        </div>
                    </Card.Footer>
                </form>
            </Card>
            <div style={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
                <ReactTable
                    data={users} labels={labels} bordered={true}
                    striped={true} hover={true} setFunc={setUsers} sort={true}
                    pageSize={1000}
                    get_title={'users'}
                    url_update={'/users/update'}
                    update_field={'fio'}
                    url_field={'/users/item'}
                    additionalCheckField={additionalCheckField}
                />
            </div>
        </React.Fragment>
    );
}

export default Users;
