import React from 'react';
import classnames from 'classnames';
import {usePagination, DOTS_RIGHT, DOTS_LEFT} from './utils';
import {PageItem, Pagination} from "react-bootstrap";

const ReactPagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 3,
        currentPage,
        pageSize
    } = props;

    const paginationRange = usePagination({
        totalCount,
        pageSize,
        siblingCount,
        currentPage
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <Pagination>
            <PageItem className={classnames('pagination-item', {
                disabled: currentPage === 1
            })} onClick={onPrevious}>«</PageItem>
            {paginationRange.map(pageNumber => {
                if (pageNumber === DOTS_RIGHT || pageNumber === DOTS_LEFT) {
                    return <PageItem key={pageNumber} className="pagination-item dots">&#8230;</PageItem>;
                }

                return (
                    <PageItem key={pageNumber}
                              className={classnames('pagination-item', {
                                  active: pageNumber === currentPage
                              })}
                              onClick={() => onPageChange(pageNumber)}>
                        {pageNumber}
                    </PageItem>
                );
            })}
            <PageItem className={classnames('pagination-item', {
                disabled: currentPage === lastPage
            })} onClick={onNext}>»</PageItem>
        </Pagination>
    );
};

export default ReactPagination;
