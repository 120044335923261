import * as React from "react";
import {Button, Card} from "react-bootstrap";
import {getRequest, putRequest} from "../../actions/common";
import ReactBreadcrumb from "../../components/breadcrumbs";
import {Check, Input, Select, Area} from "../../components/form/forms";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {Field, FormikProvider, useFormik} from "formik";
import {FormikSelect} from "../../components/form/formik";
import {ROLES_LABELS} from "../../rbac/constant";


const UserCreate = () => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            data: {
                first_name: '',
                last_name: '',
                middle_name: '',
                username: '',
                email: '',
                project_id: '',
                roles: [],
                description: '',
                is_active: 'on',
                status: 0,
            },
            additional: {
                not_notification: false,
                show_password: false,
            }
        },
        onSubmit: values => onSubmitForm(values),
        validateOnBlur: false,
        validateOnChange: false
    });
    const [select_values, setSelectValues] = useState({});
    const [projects, setProjects] = useState(null);
    const [errors, setErrors] = useState({last_name: null, first_name: null, email: null});
    const [roles, setRoles] = useState(null);

    React.useEffect(() => {
        document.title = 'Создать пользователя | ИС «НЦМУ»';
    });

    useEffect(() => {
        if (!projects) {
            getRequest('project', setProjects, {type_data: 'all'})
        }
    }, [projects])

    useEffect(() => {
        if (!projects) {
            getRequest('roles', setRoles, {type_data: 'all'})
        }
    }, [projects])

    const onSubmitForm = (e) => {
        e.preventDefault();
        // let data = {data: {}};
        // const inputs = Array.from(e.target);
        // for (let element in inputs) {
        //     console.log(inputs[element].id, inputs[element].value)
        //     let data_field = inputs[element].getAttribute('data_field');
        //     if (data_field) {
        //         data[data_field] = {...data[data_field], [inputs[element].id]: inputs[element].value}
        //     } else {
        //         if (inputs[element].id !== 'react-select-project-input' && inputs[element].id)
        //             data.data[inputs[element].id] = inputs[element].value;
        //     }
        // }
        // if (select_values?.project)
        //     data.data['project'] = select_values?.project;

        putRequest('user', formik.values).then((response) => {
            if (response.status === 400) {
                let err = response?.data;
                if (err) {
                    setErrors({...err});
                }
            } else {
                if (response.status === 201) {
                    setErrors({last_name: null, first_name: null, email: null});
                    let password = (response?.data?.password);
                    toast.success(`Пользователь успешно создан!\n${password ? `Установленный пароль: ${password}` : ''}`, {
                        position: "bottom-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        theme: "colored",
                    })
                    navigate('/users/');
                }
            }
        });
    }

    const generateOptions = (id) => {
        if (projects) {
            let options = [];
            projects.forEach((element) => {
                options.push({value: element.id, label: element.title_short});
            })
            return options;
        }
        return null;
    }

    const refreshFilter = () => {
        document.getElementById('id').value = '';
        document.getElementById('title').value = '';
        document.getElementById('description').value = '';
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={"page-header"}>
                Создать
            </h1>
            <form onSubmit={onSubmitForm}>
                <Card>
                    <FormikProvider value={formik}>
                        <Card.Body>
                            <Input label={'Фамилия'} id={'data.last_name'}
                                   value={formik.values.last_name}
                                   required={true}
                                   handleChangeValue={formik.handleChange}
                                   invalid={errors.last_name}
                                   error={errors.last_name}
                            />
                            <Input label={'Имя'} id={'data.first_name'} required={true}
                                   value={formik.values.first_name}
                                   handleChangeValue={formik.handleChange}
                                   invalid={errors.first_name}
                                   error={errors.first_name}
                            />
                            <Input label={'Отчество'} id={'data.middle_name'}
                                   handleChangeValue={formik.handleChange}
                                   value={formik.values.middle_name}
                            />
                            <Input label={'Email адрес'} required={true} id={'data.email'}
                                   value={formik.values.email}
                                   invalid={errors.email}
                                   handleChangeValue={formik.handleChange}
                                   error={errors.email}
                            />
                            <Field component={FormikSelect} label={'Проект'} id={'data.project'}
                                   name={'data.project'}
                                   handleChangeValue={formik.handleChange}
                                   options={generateOptions()}
                            />
                            <Field component={FormikSelect}
                                   label={'Роли'} id={'data.roles'}
                                   name={'data.roles'}
                                   isMulti={true}
                                   isClearable={false}
                                   handleChangeValue={formik.handleChange}
                                   options={roles?.map(item => {
                                       return {value: item.id, label: ROLES_LABELS?.[item.role]}
                                   })}
                            />
                            <Area label={'Описание'} id={'data.description'} handleChangeValue={formik.handleChange}/>
                            <Check label={'Показать пароль'} id={'additional.show_password'}
                                   handleChangeValue={formik.handleChange}
                                   value={formik.values.additional.show_password}
                                   props={{data_field: 'additional'}}/>
                            <Check label={'Не уведомлять пользователя'} id={'additional.not_notification'}
                                   value={formik.values.additional.not_notification}
                                   handleChangeValue={formik.handleChange}
                                   props={{data_field: 'additional'}}/>
                        </Card.Body>
                    </FormikProvider>
                    <Card.Footer>
                        <div className={'button-actions'}>
                            <Button variant='success' type='submit'>Сохранить</Button>
                            <Button variant='secondary' onClick={refreshFilter}>Сбросить</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </form>
        </React.Fragment>
    );
}

export default UserCreate;
