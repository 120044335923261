import * as React from "react";
import {useEffect, useState} from "react";

import {getChartConfig, renderChart} from "../../utils/highcharts/charts";
import {getRequest} from "../../actions/common";
import {Button, Form, Row} from "react-bootstrap";
import {SelectReact} from "../../components/form/select";
import {IconText} from "../../components/icon_txt";
import {useNavigate} from "react-router-dom";
import Dict from "../dict/common/dict";


const Dashboard = (props) => {
    let current_year = new Date().getFullYear();
    const select_fields = ['year', 'project'];
    const labels = {'year': 'Год', 'project': 'Проект'}
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'Дашборд | ИС «НЦМУ»';
        const meta = document.head.getElementsByTagName('meta');
        if (!meta?.['viewport']) {
            let viewport_doc = document.createElement('meta');
            viewport_doc.name = "viewport";
            viewport_doc.content = 'width=device-width, initial-scale=0';
            document.getElementsByTagName('head')[0].appendChild(viewport_doc);
        } else {
            meta['viewport']?.remove();
        }
    })

    const [data, setData] = useState(null);
    const [show_home, setShowHome] = useState(false);

    const [select_values, setSelectValues] = useState({year: current_year});
    const [select_data, setSelectData] = useState({});


    useEffect(() => {
        if ('project' in select_values) {
            getRequest('dashboard', setData, {
                year: select_values.year,
                project_name: select_values.project
            }).then(r => setShowHome(true));
        } else {
            getRequest('dashboard', setData, {
                year: select_values.year,
            }).then(r => setShowHome(true));
        }
    }, [select_values]);

    useEffect(() => {
        if (Object.keys(select_data).length === 0 && select_fields !== null) {
            getRequest(`dashboard/report_year`, setSelectData, {}, '', select_data, 'year');
        }
        if (Object.keys(select_data).length === 1 && select_fields !== null) {
            getRequest(`project`, setSelectData, {type_data: 'all'}, '', select_data, 'project');
        }
    }, [select_data]);

    useEffect(() => {
        if (data !== undefined && data !== null) {
            if (data.hasOwnProperty('lastReportDate')) {
                const config = getChartConfig({
                    type: 'common',
                    json: data,
                    name: data?.name ? data.name : 'Данные по показателям программы «Научный центр мирового уровня «Передовые цифровые технологии» (НЦМУ)',
                    subTitle: data.lastIndUpdatedAt ? data.lastReportDate + data.lastIndUpdatedAt + data.lastIndUpdatedBy : data.lastIndUpdatedAt,
                })
                renderChart(config);
            }
        }
    }, [data]);

    const FilterSelects = () => {
        if (select_fields && Object.keys(select_data).length === 2) {
            let elements = [];
            select_fields.forEach((field) => {
                let options = [];
                for (let i = 0; i < select_data[field].length; i++) {
                    if (select_data[field][i]) {
                        options.push({
                            value: 'year' === field ? select_data[field][i] : select_data[field][i].title_short,
                            label: 'year' === field ? select_data[field][i] : select_data[field][i].title_short
                        });
                    }
                }
                elements.push(
                    <div className='form-group ml-3' key={field} style={{maxWidth: '200px', minWidth: '160px'}}>
                        <Form.Label>{labels[field]}</Form.Label>
                        <SelectReact options={options} setSelectState={true} select_key={field}
                                     setState={setSelectValues}
                                     select_values={select_values}
                                     value={select_values[field]}
                                     selectID={`${field}_select`}
                                     isClearable={'year' !== field}
                        />
                    </div>
                )
            })
            if (show_home) {
                elements.push(
                    <div className='form-group ml-3' key={'home-btn'} style={{maxWidth: '200px'}}>
                        <Button variant={'info'} style={{marginTop: '29px'}} onClick={() => {
                            navigate(`/`)
                        }}>
                            <IconText icon={'fa-home'} text={'На главную'}/>
                        </Button>
                    </div>
                )
            }
            return elements;
        }
        return null;
    }


    return (
        <React.Fragment>
            <Row className={'position-absolute'} style={{zIndex: 2, left: '20px', top: '10px'}}>
                <FilterSelects/>
            </Row>
            <div className={'chart'} id={'chart'}
                 style={{width: '100vw', height: 'calc(100vh - 82px)', marginTop: '82px'}}/>
        </React.Fragment>
    );
}

export default Dashboard;
