import * as React from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {IconText} from "../../../components/icon_txt";
import {useContext, useEffect, useState} from "react";
import {deleteRequest, getRequest, putRequest, updateRequest} from "../../../actions/common";
import {
    REQUIRED_FIELDS,
    STATUS_ACCEPT, STATUS_APPLICATION_APPROVE,
    STATUS_APPLICATION_NEW,
    STATUS_APPROVE,
    STATUS_NEW,
    STATUS_PROCESS
} from "./constant";
import {useAbac} from "react-abac";
import {PERMISSIONS} from "../../../rbac/constant";
import {Area, Input, Select} from "../../../components/form/forms";
import {SPECIALIST_PARTICIPATION, SPECIALIST_TYPES} from "./constant";
import {AuthContext} from "../../../auth";
import {setChangedValue} from "../../../utils/utils";
import {toast} from "react-toastify";

export const SpecialistCreate = (props) => {
    const {state} = useLocation();
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();
    const navigate = useNavigate();

    const [status, setStatus] = useState(STATUS_APPLICATION_NEW);
    const [input_values, setInputValues] = useState({common: {}});
    const [select_data, setSelectData] = useState({});
    const [select_values, setSelectValues] = useState({});
    const [errors, setErrors] = useState(() => {
        let temp = {};
        REQUIRED_FIELDS[STATUS_ACCEPT].forEach((field) => {
            temp[field] = null;
        })
        return temp;
    });

    const select_fields = ['country', 'project', 'event', 'organization'];

    useEffect(() => {
        if (!userHasPermissions(PERMISSIONS.MANAGER_PANEL)) {
            navigate('/access-error');
        } else {
            document.title = `Создание записи в БД Специалисты | ИС «НЦМУ»`;
            if (user?.project?.id) {
                setSelectValues({...select_values, project: user?.project?.id})
            }
        }
    }, []);

    useEffect(() => {
        if (Object.keys(select_data).length < select_fields.length) {
            for (let field of select_fields) {
                if (!Object.keys(select_data).includes(field)) {
                    getRequest(field, setSelectData, {type_data: 'all'}, '', select_data, field);
                    break
                }
            }
        }
    }, [select_data]);

    useEffect(() => {
        setStatus(state?.is_educational ? STATUS_NEW : STATUS_APPLICATION_NEW);
    }, [state])

    const btnMessage = () => {
        let card = document.getElementById('card-message');
        if (card && card.style.display === 'none') {
            card.style.display = 'block';
        } else if (card && card.style.display === 'block') {
            card.style.display = 'none';
        }
    }

    const getStatusClassName = (check_status) => {
        if (check_status === status) {
            return 'bg-primary'
        } else if (check_status < status) {
            return 'bg-success'
        } else if (check_status > status) {
            return 'bg-secondary'
        }
        return null;
    }

    const handleChangeValue = (e) => {
        e.preventDefault();
        let target = e.target.getAttribute('data_change_key');
        let sub_key = e.target.getAttribute('data_foreign_id');
        setChangedValue(setInputValues, input_values, target, e.target.id, e.target.value, sub_key);
    }

    const generateOptions = (field, key) => {
        if (select_data && Object.keys(select_data).includes(field)) {
            let options = [];
            for (let element of select_data[field]) {
                options.push({value: element.id, label: element[key]});
            }
            return options;
        }
        return null;
    }

    const checkErrors = (status) => {
        let new_errors = {};
        let expression;
        let has_errors = false;

        REQUIRED_FIELDS[status].forEach((field) => {
            if (typeof select_values[field] === 'object') {
                expression = !select_values[field]?.length;
            } else {
                expression = !document.getElementById(field)?.value && (!select_values[`${field}_id`] && !select_values[field]);
            }
            if (expression) {
                new_errors[field] = 'Обязательное поле!';
                has_errors = true;
            } else {
                new_errors[field] = null;
            }
        })
        setErrors({...errors, ...new_errors})
        return has_errors
    }

    const saveSpecialist = () => {
        let payload = {...input_values['common'], ...(select_values)};
        payload['status'] = state?.monitoring ? STATUS_ACCEPT : status;
        putRequest('specialist', payload).then(response => {
            if (response.status === 201) {
                navigate(`/specialist/item/${response.data.id}`, {state: {update: true}})
            }
        })
    }

    const handleSumitForm = (e) => {
        e.preventDefault();
        if (checkErrors(status)) {
            toast.error('Ошибка в заполнении данных!');
            return null
        }
        saveSpecialist();
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <Form onSubmit={handleSumitForm}>
                <div className="section-header sticky-top">
                    <h1 className="page-header">
                        {`Создание записи в БД Специалисты`}
                    </h1>
                    <div className="button-actions">
                        <Button variant='success' type='submit'>Создать</Button>
                    </div>
                </div>
                <div
                    className="mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center">
                    <div className="mx-2 mb-2" style={{width: "180px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_APPLICATION_NEW)}`}>Новая заявка на
                            зачисление</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{width: "180px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_APPLICATION_APPROVE)}`}>
                            Рассмотрение заявки на зачисление</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{width: "180px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_NEW)}`}>Новый</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{width: "180px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_APPROVE)}`}>Рассматривается</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{width: "180px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_PROCESS)}`}>Обрабатывается</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{width: "180px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_ACCEPT)}`}>Принято</p>
                    </div>
                </div>

                <Card className={'bg-light mb-3 mt-3'}>
                    <Card.Body>
                        <p>
                            <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                            условные обозначения,
                            используемые в интерфейсе:
                        </p>
                        <ul>
                            <li>поля помеченные <strong className="text-danger">красной «*»</strong> являются <strong>обязательными
                                на текущем
                                этапе для перехода на следующий</strong>;
                            </li>
                            <li>поля помеченные <strong className="text-success">зелёной «*»</strong> являются <strong>обязательными
                                для
                                определенных условий</strong>;
                            </li>
                            <li>кнопка <span className="badge badge-success">Сохранить</span> выполняет сохранение
                                текущего состояния карточки (требуется
                                заполнения ключевых полей);
                            </li>
                            <li>кнопка <span
                                className="badge badge-success">Сохранить + отправить далее</span> сохраняет карточку и
                                проверяет выполнение
                                условий текущего этапа (при выполнении условий карточка переходит на следующий этап).
                            </li>
                        </ul>
                        <hr/>
                        <fieldset>
                            <legend>Персональные данные</legend>
                            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
                                <Col>
                                    <Input label={'Фамилия'} id={'last_name'}
                                           handleChangeValue={handleChangeValue}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('last_name')}
                                           error={errors['last_name']}
                                           invalid={errors['last_name']}
                                    />
                                </Col>
                                <Col>
                                    <Input label={'Имя'} id={'first_name'}
                                           handleChangeValue={handleChangeValue}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('first_name')}
                                           error={errors['first_name']}
                                           invalid={errors['first_name']}
                                    />
                                </Col>
                                <Col>
                                    <Input label={'Отчество'} id={'middle_name'}
                                           handleChangeValue={handleChangeValue}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Дата рождения'}
                                           id={'birth_date'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('birth_date')}
                                           type={'date'}
                                           error={errors['birth_date']}
                                           invalid={errors['birth_date']}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input label={'Возраст'} id={'age'} disabled={true}
                                    />
                                </Col>
                            </Row>
                            <Select label={'Гражданство'} id={'country'}
                                    options={generateOptions('country', 'title')}
                                    select_values={select_values}
                                    setSelectValues={setSelectValues}
                                    error={errors['country']}
                                    invalid={errors['country']}
                                    required={REQUIRED_FIELDS[STATUS_NEW].includes('country')}
                            />
                            <Row>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Номер телефона'}
                                           id={'phone'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('phone')}
                                           error={errors['phone']}
                                           invalid={errors['phone']}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input label={'СНИЛС'} id={'snils'}
                                           handleChangeValue={handleChangeValue}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('snils')}
                                           error={errors['snils']}
                                           invalid={errors['snils']}
                                    />
                                </Col>
                            </Row>
                            <hr/>
                        </fieldset>
                        <fieldset>
                            <legend>Специальные данные</legend>
                            <Select label={'Тип (форма подготовки)'} id={'type'}
                                    options={SPECIALIST_TYPES}
                                    select_values={select_values}
                                    setSelectValues={setSelectValues}
                            />
                            <Row>
                                <Col md={6}>
                                    <Select label={'Проект'} id={'project'}
                                            options={generateOptions('project', 'title')}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['project']}
                                            invalid={errors['project']}
                                            disabled={user ? user?.project?.id : false}
                                            required={true}/>
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Направление'} id={'direction'}
                                           disabled={true}
                                    />
                                </Col>
                            </Row>
                            <Select label={'Наименование программы'} id={'event'}
                                    options={generateOptions('event', 'title')}
                                    select_values={select_values}
                                    setSelectValues={setSelectValues}
                                    error={errors['event']}
                                    invalid={errors['event']}
                                    required={REQUIRED_FIELDS[STATUS_NEW].includes('event')}
                            />
                            <Row>
                                <Col md={6}>
                                    <Select label={'Наименование организации'} id={'organization'}
                                            handleChangeValue={handleChangeValue}
                                            options={generateOptions('organization', 'title')}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['organization']}
                                            invalid={errors['organization']}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('organization')}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Select label={'Форма участия штатных сотрудников НЦМУ'} id={'participation_form'}
                                            options={SPECIALIST_PARTICIPATION}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                    />
                                </Col>
                            </Row>
                        </fieldset>
                        <fieldset>
                            <legend>Подтверждающие документы</legend>
                            <Row>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Номер документа об образовании'}
                                           id={'edu_doc_number'}
                                           error={errors['edu_doc_number']}
                                           invalid={errors['edu_doc_number']}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('edu_doc_number')}
                                           small={'Обязательное поле для формы подготовки «Обучающийся». Документ об образовании, полученный в рамках НЦМУ'}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input label={'Дата документа об образовании'} id={'edu_doc_date'}
                                           handleChangeValue={handleChangeValue}
                                           type={'date'}
                                           error={errors['edu_doc_date']}
                                           invalid={errors['edu_doc_date']}
                                           small={'Документ об образовании, полученный в рамках НЦМУ'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('edu_doc_date')}
                                    />
                                </Col>
                            </Row>
                            <Select label={'Приказ'} id={'order'}
                                    options={SPECIALIST_PARTICIPATION}
                                    select_values={select_values}
                                    setSelectValues={setSelectValues}
                                    error={errors['order']}
                                    invalid={errors['order']}
                                    required={REQUIRED_FIELDS[STATUS_NEW].includes('order')}
                            />
                            <Row>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Номер приказа о зачислении / включении в рабочую группу'}
                                           id={'enrollment_order_number'}
                                           small={'Номер приказа о зачислении - для формы подготовки «Обучающийся», номер приказа о включении в рабочую группу - для формы подготовки «Молодой исследователь»'}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input label={'Дата приказа о зачислении / включении в рабочую группу'}
                                           id={'enrollment_order_date'}
                                           handleChangeValue={handleChangeValue}
                                           type={'date'}
                                           small={'Дата приказа о зачислении - для формы подготовки «Обучающийся», дата приказа о включении в рабочую группу - для типа «Молодой исследователь»'}
                                    />
                                </Col>
                            </Row>
                            <Input label={'Ссылка на сопутствующие документы'} id={'related_docs_url'}
                                   handleChangeValue={handleChangeValue}
                                   small={'Скан документа, подтверждающий поле «Вид документа»'}
                            />
                            <hr/>
                            <Area label={'Комментарий (дополнительная информация)'} id={'comment'}
                                  handleChangeValue={handleChangeValue}
                            />
                        </fieldset>
                    </Card.Body>
                </Card>
            </Form>
        </React.Fragment>
    );
}
