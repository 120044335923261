import {ROLES} from "../../../rbac/constant";
import {Input, InputDate, Select} from "../../../components/form/forms";
import * as Yup from "yup";
import {setRequiredField} from "../../../utils/utils";

export const STATUS_DRAFT = 0;                 // Черновик
export const STATUS_ACCEPT = 5;                 // Принято Д
export const STATUS_NEW = 1;                    // Новый МП
export const STATUS_APPROVE = 2;                // Рассматривается ДО
export const STATUS_ADDITIONAL_INFO = 3;
export const STATUS_PROCESS = 4;               // Обрабатывается Д
export const STATUS_TEMP = 6;                 // Черновик

export const STATUS_LABELS = {
    [STATUS_DRAFT]: 'Отклонено',
    [STATUS_NEW]: 'Новый',
    [STATUS_APPROVE]: 'Рассматривается',
    [STATUS_ADDITIONAL_INFO]: 'Запрос дополнительной информации',
    [STATUS_PROCESS]: 'Обрабатывается',
    [STATUS_ACCEPT]: 'Принято',
}

export const STATUS_OPTIONS = [
    {value: `${STATUS_DRAFT}`, label: 'Отклонено'},
    {value: STATUS_NEW, label: 'Новый'},
    {value: STATUS_APPROVE, label: 'Рассматривается'},
    {value: STATUS_ADDITIONAL_INFO, label: 'Запрос дополнительной информации'},
    {value: STATUS_PROCESS, label: 'Обрабатывается'},
    {value: STATUS_ACCEPT, label: 'Принято'},
]

export const STATUS_BADGE_CLASS = {
    [STATUS_DRAFT]: 'badge-danger',
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_ADDITIONAL_INFO]: 'badge-warning',
    [STATUS_PROCESS]: 'badge-primary',
    [STATUS_ACCEPT]: 'badge-success',
}

export const JOURNAL_QUARTILE = [
    {value: 'Q1', label: 'Q1'},
    {value: 'Q2', label: 'Q2'},
    {value: 'Q3', label: 'Q3'},
    {value: 'Q4', label: 'Q4'},
]

export const REPORT_YEAR = [
    {value: 2020, label: 2020},
    {value: 2021, label: 2021},
    {value: 2022, label: 2022},
    {value: 2023, label: 2023},
    {value: 2024, label: 2024},
    {value: 2025, label: 2025},
]

export const PUBLICATION_TYPE = {
    1: 'Статья в журнале'
}


export const LABELS = {
    "id": "ID",
    "order": "№",
    "country": "Страна журнала",
    "authors": "Авторы-сотрудники НЦМУ",
    "status": "Статус",
    "other_authors": "Прочие авторы",
    "title": "Заголовок",
    "type": "Тип публикации",
    "journal": "Наименование журнала",
    "publication_type": "Вид издания",
    "journal_country": "Страна журнала",
    "journal_quartile": "Квартиль журнала",
    "journal_issn": "ISSN",
    "journal_isbn": "ISBN",
    "impact_factor": "Импакт-фактор",
    "wos_indexed": "Индексируется в WoS",
    "scopus_indexed": "Индексируется Scopus",
    "receipt_date": "Дата первичного поступления в редакцию",
    "pub_date": "Дата публикации",
    "direction": "Направление",
    "project": "Проект",
    "report_year": "Год зачета публикации",
    "related_docs_url": "Ссылка на хранилище",
    "doi": "DOI",
    "message": "Комментарий",
    "messages": "Сообщения",
    'updated_by': 'Обновлено пользователем',
    "created_at": "Дата создания",
    "updated_at": "Дата обновления",
    "created_by": "Создано пользователем",
    "direction_by": "Дирекция"
};

export const FIELDS = {
    id: {
        type: Input,
        content: 'number'
    },
    order: {
        type: Input,
        content: 'number'
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS
    },
    other_authors: {
        type: Input,
        content: 'text'
    },
    title: {
        type: Input,
        content: 'text'
    },
    type: {
        type: Select,
        options: [
            {value: 1, label: 'Статья в журнале'}
        ]
    },
    journal: {
        type: Input,
        content: 'text'
    },
    publication_type: {
        type: Select,
        source: 'publication_type',
        key: (v) => v?.title
    },
    country: {
        type: Select,
        source: 'country',
        key: (v) => v?.title_short
    },
    journal_quartile: {
        type: Select,
        options: JOURNAL_QUARTILE
    },
    journal_issn: {
        type: Input,
        content: 'text'
    },
    journal_isbn: {
        type: Input,
        content: 'text'
    },
    impact_factor: {
        type: Input,
        content: 'text'
    },
    wos_indexed: {
        type: Select,
        options: [{value: 'False', label: 'Нет'}, {value: 'True', label: 'Да'}]
    },
    scopus_indexed: {
        type: Select,
        options: [{value: 'False', label: 'Нет'}, {value: 'True', label: 'Да'}]
    },
    receipt_date: {
        type: InputDate,
        content: 'date'
    },
    pub_date: {
        type: InputDate,
        content: 'date'
    },
    direction: {
        type: Input,
        content: 'text'
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short
    },
    report_year: {
        type: Input,
        content: 'number'
    },
    related_docs_url: {
        type: Input,
        content: 'text'
    },
    doi: {
        type: Input,
        content: 'text'
    },
    authors: {
        type: Select,
        source: 'staff_select',
        key: (v) => v?.fio
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
    created_at: {
        type: InputDate,
        content: 'date'
    },
    updated_at: {
        type: InputDate,
        content: 'date'
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
    direction_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
};

export const SEMI_REQUIRED_FIELDS = {
    [STATUS_NEW]: ['journal_issn', 'journal_isbn'],
    [STATUS_APPROVE]: ['journal_issn', 'journal_isbn'],
    [STATUS_PROCESS]: ['journal_issn', 'journal_isbn'],
    [STATUS_ADDITIONAL_INFO]: ['journal_issn', 'journal_isbn'],
    [STATUS_ACCEPT]: ['journal_issn', 'journal_isbn'],
    [STATUS_TEMP]: ['journal_issn', 'journal_isbn']
}

export const REQUIRED_FIELDS = {
    [STATUS_DRAFT]: [],
    [STATUS_NEW]: [
        'title',
    ],
    [STATUS_APPROVE]: [
        'title', 'project', 'journal', 'publication_type', 'country', 'journal_quartile', 'receipt_date', 'authors'
    ],
    [STATUS_ADDITIONAL_INFO]: [
        'title', 'project', 'journal', 'publication_type', 'country', 'journal_quartile', 'receipt_date', 'authors'
    ],
    [STATUS_PROCESS]: [
        'title', 'project', 'journal', 'publication_type', 'country', 'journal_quartile', 'receipt_date', 'authors', 'doi', 'pub_date'
    ],
    [STATUS_ACCEPT]: [
        'title', 'project', 'journal', 'publication_type', 'country', 'journal_quartile', 'receipt_date', 'authors', 'doi', 'pub_date', 'report_year'
    ],
    [STATUS_TEMP]: [
        'title', 'project', 'journal', 'publication_type', 'country', 'journal_quartile', 'receipt_date', 'authors', 'doi', 'pub_date', 'report_year'
    ]
}

const getSchema = () => {
    return {
        status: Yup.number(),
        message: Yup.string().when(['status', 'current_status'], (status, schema) => {
            return status[0] < status[1] ? schema.required('Обязательное поле!') : schema
        }),
        project_id: setRequiredField(Yup.number()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        title: Yup.string().required('Обязательное поле!'),
        publication_type_id:  setRequiredField(Yup.number().typeError('Обязательное поле!'), STATUS_APPROVE),
        journal_issn: Yup.string().test('check-issn', 'Укажите ISSN!', (value, context) => {
            return !(!value && !context.parent.journal_isbn && context.parent.status >= STATUS_APPROVE);
        }).nullable(),
        journal_isbn: Yup.string().test('check-issn', 'Укажите ISBN!', (value, context) => {
            return !(!value && !context.parent.journal_issn && context.parent.status >= STATUS_APPROVE);
        }).nullable(),
        journal: setRequiredField(Yup.string().nullable(), STATUS_APPROVE),
        country_id: setRequiredField(Yup.number()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        journal_quartile: setRequiredField(Yup.string().nullable(), STATUS_APPROVE),
        receipt_date: setRequiredField(Yup.date().nullable().default(undefined).typeError('Неправильная дата!'), STATUS_APPROVE),
        authors: setRequiredField(Yup.array(Yup.number())
                .nullable()
                .typeError('Обязательное поле!'),
            STATUS_APPROVE, true),
        doi: setRequiredField(Yup.string().nullable().typeError('Обязательное поле!'), STATUS_PROCESS),
        pub_date: setRequiredField(Yup.date()
            .nullable()
            .default(undefined)
            .typeError('Неправильная дата!'), STATUS_PROCESS),
        report_year: setRequiredField(Yup.number().typeError('Обязательное поле!').nullable(), STATUS_ACCEPT)
    }
}

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_DRAFT]: Yup.object().shape(getSchema()),
    [STATUS_NEW]: Yup.object().shape(getSchema()),
    [STATUS_APPROVE]: Yup.object().shape(getSchema()),
    [STATUS_ADDITIONAL_INFO]: Yup.object().shape(getSchema()),
    [STATUS_PROCESS]: Yup.object().shape(getSchema()),
    [STATUS_ACCEPT]: Yup.object().shape(getSchema()),
}

export const CAN_MANAGE_ROLES = {
    [STATUS_DRAFT]: [ROLES.admin],
    [STATUS_NEW]: [ROLES.admin, ROLES.direction, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction],
    [STATUS_ADDITIONAL_INFO]: [ROLES.admin, ROLES.direction, ROLES.project_manager],
    [STATUS_PROCESS]: [ROLES.admin, ROLES.direction],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction]
}
