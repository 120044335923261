import * as React from "react";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import ReactFilterTable from "../../../components/tables/react_table_filters";
import {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {LABELS, STATUS_LABELS, STATUS_BADGE_CLASS, FIELDS, STATUS_ACCEPT, STATUS_NEW} from "./constant";
import {PERMISSIONS, ROLES} from "../../../rbac/constant";
import {Button, Form, Modal} from "react-bootstrap";
import {IconText} from "../../../components/icon_txt";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Check, Select} from "../../../components/form/forms";
import {getRequest, putRequest} from "../../../actions/common";
import {AuthContext} from "../../../auth";
import {Messages} from "../../../utils/utils";
import {useAbac} from "react-abac";
import {toast} from "react-toastify";

const Staff = () => {
    const navigate = useNavigate();
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();

    const [staff, setStaff] = useState(null);
    const [show_create, setShowCreate] = useState(false);
    const [filtering, setFiltering] = useState(null);
    const [select_values, setSelectValues] = useState({});
    const [select_data, setSelectData] = useState({});

    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'fio', 'status', 'order', 'age', 'birth_date', 'self_academic_degree',
            'self_academic_rank', 'job_contract', 'country', 'project', 'employee_id'],
        'Скрытые / Фиксированные колонки': ['acc_scopus', 'acc_researcher', 'contract_number', 'contract_date',
            'additional_info', 'participant_info', 'personal_account', 'related_docs_url', 'finance_category',
            'h_index', 'orcid', 'dismissal_date', 'work_group_order', 'is_supervisor', 'snils', 'inn', 'department',
            'unit', 'event', 'direction_staff', 'academic_degree', 'direction', 'staff_category', 'employment_date',
            'created_by', 'updated_by', 'created_at', 'updated_at', 'staff_staff_category', 'staff_staff_position', 'messages',
            'ncmuspecialist'
        ]
    });

    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({status_not: STATUS_NEW})
        } else {
            setFiltering({})
        }
    }, [user])


    useEffect(() => {
        if (!Object.keys(select_data).length) {
            getRequest('person_staff', setSelectData, {type_data: 'all'}, '', select_data, 'person');
        }
    }, [select_data])

    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        } else if (['event', 'order', 'unit'].includes(key) && field) {
            return <span>{field.title}</span>
        } else if (['project', 'staff_category', 'country'].includes(key) && field) {
            return <span>{field.title_short}</span>
        } else if (key === 'job_contract') {
            if (field)
                return <span>{`${field?.title} (${field?.title_short})`}</span>
        } else if (key === 'order') {
            if (field)
                return <span>{`${field?.title}`}</span>
        } else if (['academic_degree', 'self_academic_degree', 'self_academic_rank', 'department'].includes(key) && field) {
            if (field && field.title_short)
                return <span>{`(${field.title_short}) ${field.title} `}</span>
            else if (field)
                return <span>{`${field.title} `}</span>
        } else if (key === 'messages') {
            return <Messages messages_={field}
                             status_labels={STATUS_LABELS}
                             source={'staff'}/>
        } else if (['staff_staff_category', 'staff_staff_position'].includes(key)) {
            if (!field?.length) {
                return <span className={'not-set'}>(не задано)</span>
            }
            let elements = [];
            for (let index in field) {
                elements.push(<span key={`staff_staff_position_${index}`}>
                {field === 'staff_staff_position' ? field[index]?.title : field[index]?.title_short}
                    <br/>
            </span>)
            }
            return elements
        } else if (key === 'ncmuspecialist') {
            if (field) {
                return <Link to={`/specialist/item/${field}/?update=false`}>
                    <FontAwesomeIcon icon={'external-link-alt'}/>
                </Link>
            } else {
                return <span></span>
            }
        }

        return null;
    }

    const generateOptions = () => {
        if (select_data && Object.keys(select_data).includes('person')) {
            let options = [];
            let index = 1;
            for (let element of select_data['person']) {
                options.push({
                    value: index,
                    label: `${element.fio}`
                });
                index += 1;
            }
            return options;
        }
        return null;
    }

    const addNewStaff = () => {
        let person = null;
        if (select_data?.person && select_values?.person) {
            person = select_data?.person[select_values?.person - 1];
        }
        putRequest('staff', {
            status: select_values?.monitoring ? STATUS_ACCEPT : STATUS_NEW,
            ...person,
            country: person ? person?.country : null,
            academic_rank: person ? person?.academic_rank : null,
            self_academic_degree: person ? person?.self_academic_degree : null,
            self_academic_rank: person ? person?.self_academic_rank : null,
            project: user?.project?.id
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/staff/item/${response.data.id}/?update=true`, {state: select_values});
            } else if (response?.status === 400 && response?.data?.message) {
                toast.error(response?.data?.message)
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={"page-header"}>
                Кадры
            </h1>
            {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction_staff)
            || user?.roles?.includes(ROLES.project_manager) || user?.roles?.includes(ROLES.direction) ?
                <React.Fragment>
                    <Button variant={'success'} className={'mb-3'} onClick={() => {
                        setShowCreate(true)
                    }}><IconText icon={'plus'} text={'Создать'}/></Button>

                    <Modal size={'md'} show={show_create} centered={true} onHide={() => setShowCreate(false)}>
                        <div className={'modal-content'}>
                            <Form>
                                <Modal.Header>
                                    <Modal.Title>
                                        Создание карточки в БД Кадры
                                    </Modal.Title>
                                    <button className={'close'} onClick={() => {
                                        setShowCreate(false)
                                    }}><FontAwesomeIcon icon={'times'}/></button>
                                </Modal.Header>
                                <Modal.Body>
                                    <Select label={'Поиск сотрудника'}
                                            isClearable={true}
                                            isSea
                                            id={'person'}
                                            options={generateOptions()}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            isSearchable={true}
                                    />
                                    <Check label={'Добавить в мониторинг'}
                                           disabled={!user?.roles?.includes(ROLES.admin) && !user?.roles?.includes(ROLES.direction_staff) && !user?.roles?.includes(ROLES.direction)}
                                           handleChangeValue={(e) => {
                                               setSelectValues({...select_values, monitoring: e.target.checked})
                                           }}/>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className={'button-actions'}>
                                        <Button variant={'success'} onClick={addNewStaff}>
                                            <IconText icon={'plus'} text={'Создать'}/>
                                        </Button>
                                        <Button variant={'warning'} onClick={() => {
                                            setShowCreate(false)
                                        }}>
                                            Отменить
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </Modal>
                </React.Fragment>
                : null}
            <div className={'button-actions'}>
                <Button variant={'success'} onClick={() => {
                    setFiltering({only_active: true})
                }}>Только активные</Button>
                <Button variant={'warning'} onClick={() => {
                    setFiltering({reset_filters: true})
                }}>Сбросить</Button>
            </div>
            <ReactFilterTable labels={LABELS}
                              groups={itemGroups}
                              setGroups={setItemGroups}
                              data={staff}
                              setFunc={setStaff}
                              page={1} url={'/staff'}
                              url_update={'/staff/item'}
                              get_title={'staff'}
                              checkField={checkField}
                              fields={FIELDS}
                              permission={PERMISSIONS.IS_MANAGER}
                              filtering={filtering}
                              colorExpression={(element) => element?.check_category}
            />

        </React.Fragment>
    );
}

export default Staff;
