import * as React from "react";
import DictUpdate from "./common/update";
import {Area, Check, Input, Select} from "../../components/form/forms";
import {DownloadFile, UploadFile} from "../../components/file_manager/file_handlers";
import {getRequest} from "../../actions/common";
import {useEffect, useState} from "react";
import {REPORT_YEAR} from "../database/publication/constant";

export const AcademicDegreeUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'academic_degree'}
            />
        </React.Fragment>
    );
}


export const AcademicRankUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'academic_rank'}
            />
        </React.Fragment>
    );
}


export const CountryUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'country'}
            />
        </React.Fragment>
    );
}

// export const DepartamentUpdate = () => {
//     const fields = [
//         'id', 'title', 'title_short', 'parent_id', 'is_active'
//     ];
//     const labels = {
//         'id': 'ID',
//         'title': 'Заголовок',
//         'title_short': 'Аббревиатура',
//         'sort_index': 'Порядковый №',
//         'ref_id': 'Внешняя ссылка',
//         'parent_id': 'ID родителя',
//         'is_active': 'Активно?',
//         'data_create': 'Дата открытия',
//         'data_close': 'Дата закрытия'
//     };
//
//     return (
//         <React.Fragment>
//             <DictUpdate labels={labels}  fields={fields}
//                   dict_title={'departament'}
//             />
//         </React.Fragment>
//     );
// }

export const DirectionUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'direction'}
            />
        </React.Fragment>
    );
}


export const EventCategoryUpdate = () => {
    const fields = [
        'title', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'event_category'}
            />
        </React.Fragment>
    );
}

export const EventsUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
        'category_id': 'Категория',
        'project_id': 'Проект',
        'is_educational': 'Образовательное'
    };

    const [event_category, setEventCategory] = useState(null);
    const [projects, setProjects] = useState(null);

    useEffect(() => {
        if (!event_category) {
            getRequest('event_category', setEventCategory, {type_data: 'all'})
        }
    }, [event_category])

    useEffect(() => {
        if (!projects) {
            getRequest('project', setProjects, {type_data: 'all'})
        }
    }, [projects])

    let custom_fields = {
        category_id: Select,
        project_id: Select,
        is_educational: Check
    }


    let select_options = {
        category_id: event_category ? event_category.map(v => {
            return {value: v.id, label: v.title}
        }) : null,
        project_id: projects ? projects.map(v => {
            return {value: v.id, label: v.title}
        }) : null,
    }

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'event'}
                        custom_fields={custom_fields}
                        select_options={select_options}
            />
        </React.Fragment>
    );
}

export const JobContractUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'job_contract'}
            />
        </React.Fragment>
    );
}

export const OrganizationUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'organization'}
            />
        </React.Fragment>
    );
}

export const PatentOfficeUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'patent_office'}
            />
        </React.Fragment>
    );
}

export const ProjectUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index', 'direction_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
        'direction_id': 'Направление'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'project'}
            />
        </React.Fragment>
    );
}

export const SelfAcademicDegreeUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'self_academic_degree'}
            />
        </React.Fragment>
    );
}

export const SelfAcademicRankUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'self_academic_rank'}
            />
        </React.Fragment>
    );
}

export const StaffCategoryUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'staff_category'}
            />
        </React.Fragment>
    );
}

export const StaffPositionUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'staff_position'}
            />
        </React.Fragment>
    );
}

export const StaffUnitUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'staff_unit'}
            />
        </React.Fragment>
    );
}

export const UnitUpdate = () => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'unit'}
            />
        </React.Fragment>
    );
}

export const PublicationTypeUpdate = () => {
    const fields = [
        'title', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictUpdate labels={labels} fields={fields}
                        dict_title={'publication_type'}
            />
        </React.Fragment>
    );
}

export const OrderUpdate = () => {
    const labels = {
        'number': 'Номер приказа',
        'date': 'Дата приказа',
        'description': 'Описание',
        'category': 'Категория'
    };

    let custom_fields = {
        number: Input,
        date: Input,
        category: Select,
        description: Area,
        filename: DownloadFile,
        new_file: UploadFile
    }

    let select_fields = ['category']

    let custom_props = {
        date: {type: 'date'},
    }

    let select_options = {
        category: [
            {value: 3, label: 'Не задано'},
            {value: 1, label: 'Зачисление на обучение'},
            {value: 2, label: 'Рабочая группа'}
        ]
    }

    return (
        <React.Fragment>
            <DictUpdate labels={labels}
                        custom_fields={custom_fields}
                        custom_props={custom_props}
                        dict_title={'order'}
                        select_fields={select_fields}
                        select_options={select_options}
                        header={'title'}
            />
        </React.Fragment>
    );
}

export const TemplateUpdate = () => {
    const labels = {
        'title': 'Заголовок',
        'description': 'Описание',
    };

    let custom_fields = {
        title: Input,
        description: Area,
        filename: DownloadFile,
        new_file: UploadFile
    }

    return (
        <React.Fragment>
            <DictUpdate custom_fields={custom_fields}
                        labels={labels}
                        dict_title={'template'}
                        header={'title'}
            />
        </React.Fragment>
    );
}


export const GrantUpdate = () => {

    const [events, setEvents] = useState(null);

    const labels = {
        'event_id': 'Мероприятие',
        'category': 'Категория',
        'grant': 'Грант',
        'account': 'Номер лицевого счёта',
        'year': 'Год',
    };

    useEffect(() => {
        if (!events) {
            getRequest('event', setEvents, {type_data: 'all'})
        }
    }, [events])

    let custom_props = {
        category: {disabled: true},
    }

    let custom_fields = {
        event_id: Select,
        category: Input,
        grant: Input,
        account: Input,
        year: Select,
    }

    let select_options = {
        event_id: events ? events.map(v => {
            let label = null;
            if (v.title_short) {
                label = `(${v.title_short}) ${v.title}`
            } else {
                label = v.title
            }
            return {value: v.id, label: label}
        }) : null,
        year: REPORT_YEAR
    }

    return (
        <React.Fragment>
            <DictUpdate custom_fields={custom_fields}
                        select_options={select_options}
                        labels={labels}
                        custom_props={custom_props}
                        dict_title={'grant'}
                        header={'year'}
            />
        </React.Fragment>
    );
}

