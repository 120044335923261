import * as React from 'react';
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {IconText} from "./icon_txt";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../auth";
import {useAbac} from "react-abac";
import {PERMISSIONS} from "../rbac/constant";
import {logoutSubmit} from "../actions/auth";
import {Link} from "react-router-dom";

const Header = () => {
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();

    const CustomNavDropDown = ({name, icon, children}) => {
        const title = <IconText text={name} icon={icon}/>;
        return (
            <React.Fragment>
                <NavDropdown title={title}>
                    {children}
                </NavDropdown>
            </React.Fragment>
        );
    }

    const logoutHandle = (e) => {
        e.preventDefault();
        logoutSubmit();
    }

    return (
        <React.Fragment>
            <Navbar bg="dark" variant="dark" expand="md">
                <Link to={'/'}><Navbar.Brand>ИС «НЦМУ»</Navbar.Brand></Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <CustomNavDropDown name={'Мониторинг'} icon={'desktop'}>
                            <Link to={'/indicator'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Показатели'} icon={'thermometer-half'}/>
                            </Link>
                            <Link to={'/indicator/report'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Отчеты'} icon={'file-alt'}/>
                            </Link>
                            <Link to={'/indicator/report_fin'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Отчеты софинансирования'} icon={'file-alt'}/>
                            </Link>
                        </CustomNavDropDown>
                        <CustomNavDropDown name={'Дашборд'} icon={'chart-line'}>
                            <Link to={'/dashboard'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Открыть'} icon={'sign-in-alt'}/>
                            </Link>
                            <Link to={'/dashboard-report'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Данные'} icon={'file-alt'}/>
                            </Link>
                        </CustomNavDropDown>
                        <CustomNavDropDown name={'Базы данных'} icon={'database'}>
                            <Link to={'/staff'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Кадры'} icon={'user-friends'}/>
                            </Link>
                            <Link to={'/specialist'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Специалисты'} icon={'user-graduate'}/>
                            </Link>
                            <Link to={'/publication'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Публикации'} icon={'book'}/>
                            </Link>
                            <Link to={'/rid'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'РИДы'} icon={'lightbulb'}/>
                            </Link>
                            <Link to={'/financing'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Софинансирование'} icon={'coins'}/>
                            </Link>
                            {userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                            || userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL) ?
                                <Link to={'/amortization'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Амортизация'} icon={'money-bill'}/>
                                </Link> : null}
                        </CustomNavDropDown>
                        <CustomNavDropDown name={'Справочники'} icon={'book'}>
                            <NavDropdown.Header>Собственные</NavDropdown.Header>
                            <Link to={'/dicts/self-academic-degree'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Ученые степени'} icon={'award'}/>
                            </Link>
                            <Link to={'/dicts/self-academic-rank'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Ученые звания'} icon={'graduation-cap'}/>
                            </Link>
                            <Link to={'/dicts/direction'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Направления'} icon={'map-signs'}/>
                            </Link>
                            <Link to={'/dicts/project'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Проекты'} icon={'project-diagram'}/>
                            </Link>
                            <Link to={'/dicts/unit'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Единицы измерения'} icon={'ruler-horizontal'}/>
                            </Link>
                            <Link to={'/dicts/staff-category'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Категории работников'} icon={'user-tag'}/>
                            </Link>
                            <Link to={'/dicts/job-contract'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Формы привлечения работника'} icon={'file-contract'}/>
                            </Link>
                            <Link to={'/dicts/event'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Мероприятия'} icon={'calendar-alt'}/>
                            </Link>
                            <Link to={'/dicts/organization'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Организации'} icon={'building'}/>
                            </Link>
                            <Link to={'/dicts/patent-office'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Платные ведомства'} icon={'shield-alt'}/>
                            </Link>
                            <Link to={'/dicts/order'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Приказы'} icon={'book'}/>
                            </Link>
                            <Link to={'/dicts/template'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Шаблоны'} icon={'book'}/>
                            </Link>
                            <Link to={'/dicts/publication-type'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Вид издания'} icon={'book'}/>
                            </Link>
                            <NavDropdown.Header>Внешние</NavDropdown.Header>
                            <Link to={'/dicts/department'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Подразделения'} icon={'sitemap'}/>
                            </Link>
                            <Link to={'/dicts/academic-degree'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Ученые степени'} icon={'award'}/>
                            </Link>
                            <Link to={'/dicts/academic-rank'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Ученые звания'} icon={'graduation-cap'}/>
                            </Link>
                            <NavDropdown.Header>Внешние (возможность редактирования)</NavDropdown.Header>
                            <Link to={'/dicts/country'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Страны'} icon={'globe'}/>
                            </Link>
                            <Link to={'/dicts/staff-position'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Должности'} icon={'street-view'}/>
                            </Link>
                            <Link to={'/dicts/staff-unit'} role={'button'} className={'dropdown-item'}>
                                <IconText text={'Штатные единицы'} icon={'user-shield'}/>
                            </Link>
                        </CustomNavDropDown>
                        {user?.roles.every(value => value !== 'project_manager') ?
                            <CustomNavDropDown name={'Импорт'} icon={'file-import'}>
                                <Link to={'/import-staff'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Кадры'} icon={'user-friends'}/>
                                </Link>
                                <Link to={'/import-specialist'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Специалисты'} icon={'user-graduate'}/>
                                </Link>
                            </CustomNavDropDown>
                            : null}
                    </Nav>
                    <Nav className="ml-auto">
                        <CustomNavDropDown name={`${user?.fio}`} icon={'user'}>
                            {userHasPermissions(PERMISSIONS.ADMIN_PANEL) ?
                                <Link to="/users" role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Пользователи'} icon={'users'}/>
                                </Link> : null
                            }
                            {userHasPermissions(PERMISSIONS.MANAGE_COMMON) ?
                                <React.Fragment>
                                    <Link to={'/file-manager'} role={'button'} className={'dropdown-item'}>
                                        <IconText text={'Файловый менеджер'} icon={'folder-open'}/>
                                    </Link>
                                    <Link to={'/'} role={'button'} className={'dropdown-item'} disabled={true}>
                                        <IconText text={'Настройки'} icon={'cog'}/>
                                    </Link>
                                </React.Fragment> : null
                            }
                            <Link to="/forget" role={'button'} className={'dropdown-item'}>
                                <IconText text={'Смена пароля'} icon={'key'}/>
                            </Link>
                            <NavDropdown.Item onClick={logoutHandle}>
                                <IconText text={'Выход'} icon={'sign-out-alt'}/>
                            </NavDropdown.Item>
                        </CustomNavDropDown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </React.Fragment>
    );
}

export default Header;
