import {ROLES} from "../../../rbac/constant";
import {Input, InputDate, Select} from "../../../components/form/forms";
import * as Yup from "yup";
import {setRequiredField} from "../../../utils/utils";

export const STATUS_DRAFT = -1;                 // Черновик
export const STATUS_ACCEPT = 6;                 // Принято Д
export const STATUS_APPLICATION_NEW = 1;       // Новая заявка на зачисление МП
export const STATUS_APPLICATION_APPROVE = 2;   // Рассмотрение заявки на зачисление ДО
export const STATUS_NEW = 3;                    // Новый МП
export const STATUS_APPROVE = 4;                // Рассматривается ДО
export const STATUS_PROCESS = 5;                // Обрабатывается Д
export const STATUS_TEMP = 7;

export const STATUS_LABELS = {
    [STATUS_APPLICATION_NEW]: 'Новая заявка на зачисление',
    [STATUS_APPLICATION_APPROVE]: 'Рассмотрение заявки на зачисление',
    [STATUS_NEW]: 'Новый',
    [STATUS_APPROVE]: 'Рассматривается',
    [STATUS_PROCESS]: 'Обрабатывается',
    [STATUS_ACCEPT]: 'Принято',
};

export const STATUS_OPTIONS = [
    {value: STATUS_APPLICATION_NEW, label: 'Новая заявка на зачисление'},
    {value: STATUS_APPLICATION_APPROVE, label: 'Рассмотрение заявки на зачисление'},
    {value: STATUS_NEW, label: 'Новый'},
    {value: STATUS_APPROVE, label: 'Рассматривается'},
    {value: STATUS_PROCESS, label: 'Обрабатывается'},
    {value: STATUS_ACCEPT, label: 'Принято'},
];

export const SPECIALIST_TYPES = [
    {value: 1, label: 'Молодой исследователь'},
    {value: 2, label: 'Обучающийся'}
]

export const SPECIALIST_PARTICIPATION = [
    {value: 1, label: 'Разработчик'},
    {value: 2, label: 'Преподователь'},
    {value: 3, label: 'Руководитель'}
]


export const STATUS_BADGE_CLASS = {
    [STATUS_DRAFT]: 'badge-danger',
    [STATUS_ACCEPT]: 'badge-success',
    [STATUS_APPLICATION_NEW]: 'badge-warning',
    [STATUS_APPLICATION_APPROVE]: 'badge-info',
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_PROCESS]: 'badge-primary',
    [STATUS_TEMP]: '',
};

export const LABELS = {
    'id': 'ID',
    'status': 'Статус',
    'type': 'Тип (форма подготовки)',
    'phone': 'Телефон',
    'snils': 'СНИЛС',
    'fio': 'ФИО',
    'first_name': 'Имя',
    'last_name': 'Фамилия',
    'middle_name': 'Отчество',
    'birth_date': 'Дата рождения',
    'age': 'Возраст',
    'edu_doc_number': 'Номер документа об образовании',
    'edu_doc_date': 'Дата документа об образовании',
    'enrollment_order_number': 'Номер приказа о зачислении/включении в рабочую группу',
    'enrollment_order_date': 'Дата приказа о зачислении/включении в рабочую группу',
    'participation_form': 'Форма участия штатных сотрудников НЦМУ',
    'comment': 'Комментарий',
    'organization': 'Наименование организации',
    'event': 'Наименование программы',
    'project': 'Проект',
    'country': 'Гражданство',
    'direction_edu': 'Дирекция Образование',
    'direction': 'Дирекция',
    'order': 'Приказ',
    'related_docs_url': 'Ссылка на сопутствующие документы',
    'staff': 'Кадры',
    "messages": "Сообщения",
    'message': 'Комментарий',
    'updated_by': 'Обновлено пользователем',
    "created_at": "Дата создания",
    "updated_at": "Дата обновления",
    "created_by": "Создано пользователем",
};

export const FIELDS = {
    id: {
        type: Input,
        content: 'number'
    },
    fio: {
        type: Input,
        content: 'text'
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS
    },
    type: {
        type: Select,
        options: SPECIALIST_TYPES
    },
    phone: {
        type: Input,
        content: 'text',
        props: {
            mask: '+7(999)999-99-99'
        }
    },
    snils: {
        type: Input,
        content: 'text',
        props: {
            mask: '999-999-999 99'
        }
    },
    first_name: {
        type: Input,
        content: 'text'
    },
    last_name: {
        type: Input,
        content: 'text'
    },
    middle_name: {
        type: Input,
        content: 'text'
    },
    birth_date: {
        type: InputDate,
        content: 'date'
    },
    age: {
        type: Input,
        content: 'number'
    },
    edu_doc_number: {
        type: Input,
        content: 'text'
    },
    edu_doc_date: {
        type: InputDate,
        content: 'date'
    },
    enrollment_order_number: {
        type: Input,
        content: 'number'
    },
    enrollment_order_date: {
        type: InputDate,
        content: 'date'
    },
    participation_form: {
        type: Select,
        options: SPECIALIST_PARTICIPATION
    },
    organization: {
        type: Select,
        source: 'organization',
        key: (v) => v?.title_short
    },
    event: {
        type: Select,
        source: 'event',
        key: (v) => `(${v?.title_short}) ${v?.title}`
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short
    },
    country: {
        type: Select,
        source: 'country',
        key: (v) => v?.title_short
    },
    direction_edu: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
    direction: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
    order: {
        type: Select,
        source: 'order'
    },
    staff: {
        type: Select,
        source: 'staff_select',
        key: (v) => v?.fio
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
    created_at: {
        type: InputDate,
        content: 'date'
    },
    updated_at: {
        type: InputDate,
        content: 'date'
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
}

export const SEMI_REQUIRED_FIELDS = {
    [STATUS_APPLICATION_NEW]: ['phone', 'snils',],
    [STATUS_APPLICATION_APPROVE]: ['phone', 'snils',],
    [STATUS_NEW]: ['phone', 'snils',],
    [STATUS_APPROVE]: ['phone', 'snils',],
    [STATUS_PROCESS]: ['phone', 'snils',],
    [STATUS_ACCEPT]: ['phone', 'snils',],
    [STATUS_TEMP]: ['phone', 'snils',],
}

export const REQUIRED_FIELDS = {
    [STATUS_APPLICATION_NEW]: [
        'first_name', 'last_name', 'birth_date', 'country_id',
    ],
    [STATUS_APPLICATION_APPROVE]: [
        'first_name', 'last_name', 'birth_date', 'country_id', 'project_id', 'organization_id', 'event_id'
    ],
    [STATUS_NEW]: [
        'first_name', 'last_name', 'birth_date', 'country_id',
    ],
    [STATUS_APPROVE]: [
        'first_name', 'last_name', 'birth_date', 'country_id', 'project_id', 'organization_id', 'event_id', 'edu_doc_date', 'edu_doc_number', 'order_id'
    ],
    [STATUS_PROCESS]: [
        'first_name', 'last_name', 'birth_date', 'country_id', 'project_id', 'organization_id', 'event_id', 'edu_doc_date', 'edu_doc_number', 'order_id'
    ],
    [STATUS_ACCEPT]: [
        'first_name', 'last_name', 'birth_date', 'country_id', 'project_id', 'organization_id', 'event_id', 'edu_doc_date', 'edu_doc_number', 'order_id'
    ],
    [STATUS_TEMP]: [
        'first_name', 'last_name', 'birth_date', 'country_id', 'project_id', 'organization_id', 'event_id', 'edu_doc_date', 'edu_doc_number', 'order_id'
    ]
}

const validateSnils = (snils) => {
    let error = null;
    let result = false;
    if (typeof snils === 'number') {
        snils = snils.toString();
    } else if (typeof snils !== 'string') {
        snils = '';
    }
    if (!snils.length) {
        error = 'СНИЛС пуст';
    } else if (/[^0-9]/.test(snils)) {
        error = 'СНИЛС может состоять только из цифр';
    } else if (snils.length !== 11) {
        error = 'СНИЛС может состоять только из 11 цифр';
    } else {
        let sum = 0;
        for (let i = 0; i < 9; i++) {
            sum += parseInt(snils[i]) * (9 - i);
        }
        let checkDigit = 0;
        if (sum < 100) {
            checkDigit = sum;
        } else if (sum > 101) {
            checkDigit = parseInt(sum % 101);
            if (checkDigit === 100) {
                checkDigit = 0;
            }
        }
        if (checkDigit === parseInt(snils.slice(-2))) {
            result = true;
        } else {
            error = 'Неправильное контрольное число';
        }
    }
    return [result, error];
}

const getSchema = () => {
    return {
        status: Yup.number(),
        message: Yup.string().when(['status', 'current_status'], (status, schema) => {
            return status[0] < status[1] ? schema.required('Обязательное поле!') : schema
        }),
        country_id: Yup.number()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!'),
        first_name: Yup.string().nullable().required('Обязательное поле!'),
        last_name: Yup.string().nullable().required('Обязательное поле!'),
        birth_date: Yup.string().nullable().required('Обязательное поле!').typeError('Обязательное поле!'),
        phone: Yup.string().nullable().when(['country_id', 'status'], (data, schema) => {
            if (data[1] === STATUS_NEW || data[1] === STATUS_APPLICATION_NEW)
                return schema
            else
                return data[0] !== 181
                    ? schema.required('Обязательное поле!')
                    : schema
        }),
        snils: Yup.string().nullable().when(['country_id'], (country_id, schema) => {
            return country_id[0] === 181 ? schema
                .required('Обязательное поле!')
                .test('test-snils',
                    'Проверьте корректность снилса!', (value, context) => {
                        let result_validation = validateSnils(value.replaceAll('-', '').replaceAll(' ', ''));
                        return result_validation[0]
                    }) : schema
        }),
        project_id: setRequiredField(Yup.number()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        event_id: setRequiredField(Yup.number()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        organization_id: setRequiredField(Yup.number()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        edu_doc_date: setRequiredField(Yup.string().nullable()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        edu_doc_number: setRequiredField(Yup.string().nullable()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        order_id: setRequiredField(Yup.number()
            .typeError('Обязательное поле!'), STATUS_APPROVE),

    }
}

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_APPLICATION_NEW]: Yup.object().shape(getSchema()),
    [STATUS_APPLICATION_APPROVE]: Yup.object().shape(getSchema()),
    [STATUS_NEW]: Yup.object().shape(getSchema()),
    [STATUS_APPROVE]: Yup.object().shape(getSchema()),
    [STATUS_PROCESS]: Yup.object().shape(getSchema()),
    [STATUS_ACCEPT]: Yup.object().shape(getSchema()),
}


export const CAN_MANAGE_ROLES = {
    [STATUS_APPLICATION_NEW]: [ROLES.admin, ROLES.direction, ROLES.direction_edu, ROLES.project_manager],
    [STATUS_APPLICATION_APPROVE]: [ROLES.admin, ROLES.direction, ROLES.direction_edu],
    [STATUS_NEW]: [ROLES.admin, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction_edu],
    [STATUS_PROCESS]: [ROLES.admin, ROLES.direction],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction]
}
