import * as React from "react";
import IndicatorSection from "./section/indicator_section";
import IndicatorProjects from "./projects/indicator_section";

export const IndicatorDirection = () => {

    const labels = {'indicator': 'Показатель', 'direction': 'Направление'};
    const select_fields = ['indicator', 'direction'];

    return (
        <React.Fragment>
            <IndicatorSection labels={labels} section_name={'direction'} title={'Показатели по направлениям'} select_fields={select_fields}/>
        </React.Fragment>
    );
}

export const IndicatorProject = () => {

    const labels = {'indicator': 'Показатель', 'project': 'Проект'};
    const select_fields = ['indicator', 'project'];

    return (
        <React.Fragment>
            <IndicatorProjects labels={labels} section_name={'project'} title={'Показатели по проектам'} select_fields={select_fields}/>
        </React.Fragment>
    );
}