import * as React from "react";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import ReactFilterTable from "../../../components/tables/react_table_filters";
import {useContext, useEffect, useState} from "react";
import {
    FIELDS,
    LABELS, SPECIALIST_PARTICIPATION, STATUS_APPLICATION_NEW,
    STATUS_BADGE_CLASS,
    STATUS_LABELS,
} from "./constant";
import {PERMISSIONS, ROLES} from "../../../rbac/constant";
import {Button, Card} from "react-bootstrap";
import {IconText} from "../../../components/icon_txt";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../../../auth";
import {putRequest} from "../../../actions/common";
import {STATUS_ACCEPT, STATUS_NEW} from "./constant";
import {getLabelFromOptions, Messages} from "../../../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAbac} from "react-abac";


const Specialist = () => {
    const navigate = useNavigate();
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();


    const [info, setInfo] = useState(false);
    const [filtering, setFiltering] = useState(null);
    const [specialist, setSpecialist] = useState(null);
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'fio', 'status', 'age', 'birth_date', 'type', 'organization', 'country', 'project'],
        'Скрытые / Фиксированные колонки': [
            "edu_doc_date",
            "edu_doc_number",
            "snils",
            "phone",
            "direction_edu",
            "direction",
            "staff",
            "event",
            "messages",
            "participation_form",
            "enrollment_order_date",
            "enrollment_order_number",
            'updated_by',
            "created_at",
            "updated_at",
            "created_by",
        ]
    });

    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({status_not: STATUS_NEW})
        } else {
            setFiltering({})
        }
    }, [user])

    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        } else if (['project', 'job_contract', 'organization', 'country'].includes(key) && field) {
            return <span>{field.title_short}</span>
        } else if (key === 'type') {
            if (field === 1) {
                return <span>Молодой исследователь</span>
            } else if (field === 2) {
                return <span>Обучающийся</span>
            }
        } else if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'specialist'}/>
        } else if (['created_by', 'updated_by'].includes(key) && field) {
            return <span>{field}</span>
        } else if (key === 'staff') {
            if (field) {
                return <Link to={`/staff/item/${field}/?update=false`}>
                    <FontAwesomeIcon icon={'external-link-alt'}/>
                </Link>
            } else {
                return <span></span>
            }
        } else if (key === 'event') {
            if (!field?.title_short || !field?.title) {
                return <span className={'not-set'}>(не задано)</span>
            }
            return `(${field?.title_short}) ${field?.title}`
        } else if (key === 'participation_form') {
            let to_return = getLabelFromOptions(SPECIALIST_PARTICIPATION, field)
            return to_return ? to_return : <span className={'not-set'}>(не задано)</span>
        }
        return null;
    }

    const showInfo = (event) => {
        event.preventDefault();
        setInfo(!info);
    }

    const addNewSpecialist = (state) => {
        putRequest('specialist', {
            status: state?.monitoring ? STATUS_ACCEPT : state?.is_educational ? STATUS_NEW : STATUS_APPLICATION_NEW,
            project: user?.project?.id,
            type: 2,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/specialist/item/${response.data.id}/?update=true`);
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={"page-header"}>
                Специалисты
            </h1>
            {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.project_manager)
            || user?.roles?.includes(ROLES.direction) ?
                <React.Fragment>
                    <div className={'button-actions'}>
                        {!user?.roles?.includes(ROLES.direction) ?
                            <React.Fragment>
                                <Button variant={'success'} className={'mb-3'} onClick={() => {
                                    addNewSpecialist()
                                }}>
                                    <IconText icon={'plus'} text={'Создать обучающегося'}/>
                                </Button>
                                <Button variant={'info'} className={'mb-3'} onClick={() => {
                                    addNewSpecialist({is_educational: true})
                                }}>
                                    <IconText icon={'plus'} text={'Создать обучившегося'}/>
                                </Button>
                            </React.Fragment> : null}
                        {userHasPermissions(PERMISSIONS.ADMIN_PANEL) || userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                        || userHasPermissions(PERMISSIONS.EDU_DIRECTION_PANEL)
                            ?
                            <Button variant={'secondary'} className={'mb-3'} onClick={() => {
                                addNewSpecialist({monitoring: true})
                            }}>
                                <IconText icon={'plus'} text={'Добавить в мониторинг'}/>
                            </Button> : null}
                    </div>
                    <Button variant={'info'} className={'mb-4'} onClick={showInfo}>
                        Справка
                    </Button>
                    <Card style={{display: info ? 'block' : 'none'}} className={'mb-4'}>
                        <Card.Body>
                            <p><strong>Создать обучающегося</strong> - сформировать карточку обучающегося для учета в
                                мониторинге
                                планового значения
                            </p>

                            <p><strong>Создать обучившегося</strong> - сформировать карточку обучившегося для
                                формирования и
                                подтверждения значения планового показателя
                            </p>

                            <p>При заполнении аспиранта в БД Кадры, специалист автоматически отображается в списке
                                специалистов, требуется войти в карточку такого специалиста и заполнить данные его
                                карточки
                            </p>
                        </Card.Body>
                    </Card>
                </React.Fragment> : null}
            <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                              filtering={filtering}
                              data={specialist} setFunc={setSpecialist} page={1} url={'/specialist'}
                              url_update={'/specialist/item'}
                              get_title={'specialist'}
                              checkField={checkField}
                              permission={PERMISSIONS.MANAGE_SPECIALIST}
                              fields={FIELDS}
            />
        </React.Fragment>
    );
}

export default Specialist;
