import * as React from 'react';
import './assets/css/App.css';
import './utils/fontawesome';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import SiteRoutes from "./utils/routes/routes";
import {AuthContextProvider} from "./auth";
import {CookiesProvider} from "react-cookie";
import {Loader} from "./utils/loader";
import {useEffect} from "react";


export const App = () => {

    useEffect(() => {
        const lastVersion = localStorage.getItem('x-version')

        if (lastVersion !== process.env.REACT_APP_VERSION) {
            localStorage.setItem('x-version', process.env.REACT_APP_VERSION)
            location.reload();
        }
    }, [])

    return (
        <Loader>
            <AuthContextProvider>
                <CookiesProvider>
                    <SiteRoutes/>
                </CookiesProvider>
            </AuthContextProvider>
        </Loader>
    )
}

export default App;




