import * as React from 'react';
import {AbstractImport} from "./abstract_import";

export const SpecialistImport = () => {
    const fields = {
        "fio": 'ФИО',
        'type': 'Тип (форма подготовки)',
        'edu_doc_number': 'Номер документа об образовании',
        'edu_doc_date': 'Дата документа об образовании',
        'enrollment_order_number': 'Номер приказа о зачислении / включении в рабочую группу',
        'enrollment_order_date': 'Дата приказа о зачислении / включении в рабочую группу',
        'organization': 'Наименование организации',
        'project': 'Проект',
        'country': 'Гражданство',
        'program': 'Наименование программы',
    };

    return <AbstractImport title={'Импорт в БД Специалисты (только новые)'}
                           fields={fields}
                           post_title={'specialist_import'}
    />
}
