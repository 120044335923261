import Highcharts from 'highcharts'
import HCData from 'highcharts/modules/data'
import HCDrilldown from 'highcharts/modules/drilldown'
import HCExporting from 'highcharts/modules/exporting'
import HCExportData from 'highcharts/modules/export-data'
import HCAccessibilit from 'highcharts/modules/accessibility'


HCData(Highcharts)
HCDrilldown(Highcharts)
HCExporting(Highcharts)
HCExportData(Highcharts)
HCAccessibilit(Highcharts)


export const DASHBOARD_COLOR_TYPES = {
    red: 'red',
    orange: 'orange',
    green: 'green',
    black: 'black',
};


export const DASHBOARD_BRAND_COLORS = {
    red: {dark: '#DB3519', light: '#ED9A8C'},
    orange: {dark: '#F0961D', light: '#F8CB8E'},
    green: {dark: '#37B34A', light: '#9BD9A5'},
    black: {dark: '#000000', light: '#111111'},
};

export const setStyles = function (series_data) {
    return series_data.map(item => {
        const y = item.valueRelative;
        let color = DASHBOARD_BRAND_COLORS.red.dark
        if (y >= 50 && y < 100) {
            color = DASHBOARD_BRAND_COLORS.orange.dark
        } else if (y >= 100) {
            color = DASHBOARD_BRAND_COLORS.green.dark
        }
        return {
            ...item,
            color
        }
    })
}

export const makeStack = function (series) {
    return {
        ...series,
        data: series.data.flatMap(value => {
            const delta = value.valueReal - value.valuePlan;
            const y = value.valueRelative;
            let colorType = DASHBOARD_COLOR_TYPES.red;
            if (y >= 50 && y < 100) {
                colorType = DASHBOARD_COLOR_TYPES.orange;
            } else if (y >= 100) {
                colorType = DASHBOARD_COLOR_TYPES.green;
            }
            return (delta > 0) ? [
                {
                    ...value,
                    y: value.valuePlan,
                    color: DASHBOARD_BRAND_COLORS[colorType].dark,
                    valueStack: value.value,
                    value: undefined,
                },
                {
                    ...value,
                    y: Math.abs(delta),
                    color: DASHBOARD_BRAND_COLORS[colorType].light,
                    valueStack: value.value,
                    value: undefined,
                },
            ] : [
                {
                    ...value,
                    y: value.valueReal,
                    color: DASHBOARD_BRAND_COLORS[colorType].dark,
                    valueStack: value.value,
                    value: undefined,
                },
                {
                    ...value,
                    y: Math.abs(delta),
                    color: DASHBOARD_BRAND_COLORS[colorType].light,
                    valueStack: value.value,
                    value: undefined,
                },
            ]
        }),
        stacking: 'normal',
    }
}

export const getChartConfig = (config) => {

    const json = config.json;

    const chartConfig = {
        chart: {
            type: 'column',
        },
        title: {
            text: config.name
        },
        subtitle: {
            text: config.subTitle
        },
        lang: {
            viewFullscreen: 'Отобразить во весь экран',
            exitFullscreen: 'Выйти из режима «во весь экран»',
            printChart: 'Распечатать график',

            downloadPNG: 'Скачать PNG-файл',
            downloadJPEG: 'Скачать JPEG-файл',
            downloadPDF: 'Скачать PDF-файл',
            downloadSVG: 'Скачать SVG-файл',

            downloadCSV: 'Скачать CSV-файл',
            downloadXLS: 'Скачать XLS-файл',

            viewData: 'Показать исходные данные',
            hideData: 'Скрыть исходные данные',

            drillUpText: '◁ Вернуться к {series.name}',

            exportData: {
                annotationHeader: 'Аннотация',
                categoryDatetimeHeader: 'Дата и время',
                categoryHeader: 'Наименование',
            },
        },
        xAxis: {type: 'category', labels: {style: {whiteSpace: 'wrap', height: '100px'}}},
        yAxis: {
            title: {text: '% достижения'},
            softMax: 110,
            plotLines: [
                {
                    id: 'plan',
                    value: 100,
                    color: DASHBOARD_BRAND_COLORS.orange.dark,
                    dashStyle: 'LongDash',
                    width: 1,
                    label: {
                        text: '100%'
                    },
                    zIndex: 5,
                }
            ],
            stackLabels: {
                enabled: true,
                formatter: function () {
                    const x = this.x
                    const data = this.axis.series[0].data.filter(item => item.x === x)[0]

                    return data.valueStack;
                }
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.value:.1f}'
                }
            },
        },
        exporting: {
            filename: 'Дашборд на ' + json.lastReportDate,
            sourceHeight: 1080,
            sourceWidth: 1920,
            buttons: {
                reloadButton: {
                    text: 'Обновить',
                    onclick: function () {
                        window.location.reload()
                    }
                },
            }
        },
        navigation: {
            menuItemHoverStyle: {
                background: DASHBOARD_BRAND_COLORS.green.dark
            }
        },
        tooltip: {
            enabled: false,
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<b>{point.name}</b>: <b>{point.y:.2f}%</b><br/>'
        },
    };

    if (config.type === 'table' || config.type === 'group') {
        chartConfig.title.text = config.name;
        chartConfig.exporting.filename = json.name + ' на ' + json.lastReportDate;
        chartConfig.series = [{
            name: config.name,
            unitTitle: '% достижения',
            plan: 100,
            planText: '100%',
            data: setStyles(json),
        }];
        chartConfig.plotOptions.series = {
            ...chartConfig.plotOptions.series,
            cursor: 'pointer'
        }
    } else {
        chartConfig.chart.events = {
            drilldown: function (e) {
                if (!e.seriesOptions) {
                    return;
                }
                const subTitleSuffix = e.seriesOptions.subtitle_suffix
                const localSubTitle = subTitleSuffix ? ` — <b>${subTitleSuffix}</b>` : config.subTitle

                this.setTitle({text: e.seriesOptions.name});
                this.setSubtitle({text: localSubTitle})
                this.yAxis[0].setTitle({text: e.seriesOptions.unitTitle}, false);
                this.yAxis[0].removePlotLine('plan');
                this.yAxis[0].addPlotLine({
                    id: 'plan',
                    value: e.seriesOptions.plan,
                    color: DASHBOARD_BRAND_COLORS.orange.dark,
                    dashStyle: 'LongDash',
                    width: 1,
                    label: {
                        text: e.seriesOptions.planText,
                    },
                    zIndex: 5,
                });
                this.yAxis[0].update({
                    softMax: e.seriesOptions.plan + (e.seriesOptions.plan * 0.1),
                }, false);
            },
            drillup: function (e) {
                if (!e.seriesOptions) {
                    return;
                }
                const subTitleSuffix = e.seriesOptions.subtitle_suffix
                const localSubTitle = subTitleSuffix ? ` — <b>${subTitleSuffix}</b>` : config.subTitle

                this.setTitle({text: e.seriesOptions.name});
                this.setSubtitle({text: localSubTitle});
                this.yAxis[0].setTitle({text: e.seriesOptions.unitTitle}, false);
                this.yAxis[0].removePlotLine('plan');
                this.yAxis[0].addPlotLine({
                    id: 'plan',
                    value: e.seriesOptions.plan,
                    color: DASHBOARD_BRAND_COLORS.orange.dark,
                    dashStyle: 'LongDash',
                    width: 1,
                    label: {
                        text: e.seriesOptions.planText,
                    },
                    zIndex: 5,
                });
                this.yAxis[0].update({
                    softMax: e.seriesOptions.plan + (e.seriesOptions.plan * 0.1),
                }, false);
            }
        };
        chartConfig.series = [{
            name: config.name,
            unitTitle: '% достижения',
            plan: 100,
            planText: '100%',
            data: setStyles(json.kpi),
        }];

        chartConfig.drilldown = {
            series: json.series.map(series => {
                const type = series.id.split('_')[0]

                return (type === 'directions' || type === 'groups') ? makeStack(series) : {
                    ...series,
                    data: setStyles(series.data)
                }
            }),
            breadcrumbs: {
                buttonTheme: {
                    fill: '#f7f7f7',
                    padding: 8,
                    stroke: '#cccccc',
                    style: {
                        color: '#000000',
                    },
                    'stroke-width': 1
                },
                floating: true,
                position: {
                    align: 'right'
                },
                // format: '◁ &laquo;{level.breadcrumbsTitle}&raquo;',
                format: '◁ Вернуться назад',
                showFullPath: false,
            },
            activeDataLabelStyle: {
                color: DASHBOARD_BRAND_COLORS.black.dark,
                textDecoration: 'none'
            }
        }
    }
    return chartConfig;
}


export function renderChart(config) {
    if (config) {
        return Highcharts.chart('chart', config);
    }
}