import * as React from "react";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import ReactFilterTable from "../../../components/tables/react_table_filters";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PERMISSIONS, ROLES} from "../../../rbac/constant";
import {Button} from "react-bootstrap";
import {IconText} from "../../../components/icon_txt";
import {AuthContext} from "../../../auth";
import {FIELDS, FINANCING_DOCUMENT_TYPES, LABELS, STATUS_BADGE_CLASS, STATUS_LABELS} from "./constant";
import {putRequest} from "../../../actions/common";
import {STATUS_ACCEPT, STATUS_NEW} from "../publication/constant";
import {getLabelFromOptions, Messages} from "../../../utils/utils";
import {useAbac} from "react-abac";


const Financing = () => {
    const navigate = useNavigate();
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();

    const [financing, setFinancing] = useState(null);
    const [filtering, setFiltering] = useState(null);
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'project', 'status', 'person', 'customer', 'contract_number', 'work_date_start', 'work_date_end'],
        'Скрытые / Фиксированные колонки': [
            "contract_type",
            "account",
            "contract_date",
            "event",
            "contract_subject",
            "total_cost",
            "created_at",
            "updated_at",
            "created_by",
            "updated_by",
            "direction_by",
            "messages"
        ]
    });

    const checkField = (field, key) => {
        if (key === 'status') {
            if (key === 'status') {
                return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
            }
        }
        if (key === 'project' && field) {
            return <span>{field.title_short}</span>
        }
        if (key === 'event' && field) {
            return <span>{field.title} ({field.title_short})</span>
        }
        if (key === 'person' && field) {
            return <span>{field.fio}</span>
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'financing'}/>
        }
        if (key === 'contract_type') {
            let label = getLabelFromOptions(FINANCING_DOCUMENT_TYPES, field);
            return label ? label : <span className={'not-set'}>(не задано)</span>
        }

        return null;
    }


    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({status_not: STATUS_NEW})
        } else {
            setFiltering({})
        }
    }, [user])

    const addNewFinancing = (state) => {
        putRequest('financing', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW,
            project: user?.project?.id
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/financing/item/${response.data.id}/?update=true`);
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={"page-header"}>
                Софинансирование
            </h1>
            {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction)
            || user?.roles?.includes(ROLES.project_manager) || user?.roles?.includes(ROLES.direction_financing) ?
                <React.Fragment>
                    <div className={'button-actions'}>
                        {userHasPermissions(PERMISSIONS.CONTROL_FINANCING) ?
                            <React.Fragment>
                                <Button variant={'secondary'} className={'mb-3'} onClick={() => {
                                    addNewFinancing({monitoring: true})
                                }}>
                                    <IconText icon={'plus'} text={'Добавить в мониторинг'}/>
                                </Button>
                                {userHasPermissions(PERMISSIONS.ADMIN_PANEL) ?
                                    <Button variant={'success'} className={'mb-3'} onClick={() => {
                                        addNewFinancing()
                                    }}>
                                        <IconText icon={'plus'} text={'Создать'}/>
                                    </Button> : null}
                            </React.Fragment>
                            : <React.Fragment>
                                <Button variant={'success'} className={'mb-3'} onClick={() => {
                                    addNewFinancing()
                                }}>
                                    <IconText icon={'plus'} text={'Создать'}/>
                                </Button>
                            </React.Fragment>
                        }
                    </div>
                </React.Fragment> : null}
            <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                              filtering={filtering}
                              data={financing} setFunc={setFinancing} page={1} url={'/financing'}
                              url_update={'/financing/item'}
                              get_title={'financing'}
                              checkField={checkField}
                              permission={PERMISSIONS.MANAGE_RID}
                              fields={FIELDS}
            />
        </React.Fragment>
    );
}

export default Financing;
