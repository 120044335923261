import * as React from "react";
import * as Yup from "yup";

import {
    Button,
    Card, Col, Row,
} from "react-bootstrap";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {Input} from "../../../components/form/forms";
import {putRequest} from "../../../actions/common";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Field, FormikProvider, useFormik} from "formik";
import {FormikDate} from "../../../components/form/formik";

const ReportCreate = () => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            title: null,
            start_date: null,
            end_date: null,
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required("Обязательное поле!"),
            start_date: Yup.date()
                .nullable()
                .typeError("Обязательное поле!")
                .required("Обязательное поле!").when(['end_date'],
                    (end_date, schema) => {
                        return end_date[0] ?
                            schema.max(end_date[0], 'Дата начала периода не может быть больше даты окончания периода') : schema
                    }),
            end_date: Yup.date()
                .nullable()
                .typeError("Обязательное поле!")
                .required("Обязательное поле!"),
        }),
        onSubmit: values => saveReport(),
        validateOnChange: false,
        validateOnBlur: false
    })

    React.useEffect(() => {
        if (!formik.isValid) {
            toast.error('Ошибка в заполнении данных!')
        }
    }, [formik.isValid])

    const saveReport = () => {
        putRequest('report', formik.values).then(response => {
            if (response.status === 201) {
                navigate(`/indicator/report`)
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={"page-header"}>
                Сгенерировать отчет
            </h1>

            <Card>
                <FormikProvider value={formik}>
                    <Card.Body>
                        <Input required={true}
                               id={'title'}
                               name={'title'}
                               value={formik.values.title}
                               label={'Заголовок'}
                               handleChangeValue={formik.handleChange}
                               error={formik.errors.title}
                               invalid={formik.errors.title}
                        />
                        <Row>
                            <Col md={6}>
                                <Field component={FormikDate} required={true}
                                       id={'start_date'}
                                       name={'start_date'}
                                       label={'Начало периода'}
                                       handleChangeValue={formik.handleChange}
                                       error={formik.errors.start_date}
                                       invalid={formik.errors.start_date}
                                />
                            </Col>
                            <Col md={6}>
                                <Field component={FormikDate} required={true}
                                       id={'end_date'}
                                       name={'end_date'}
                                       label={'Окончание периода'}
                                       handleChangeValue={formik.handleChange}
                                       error={formik.errors.end_date}
                                       invalid={formik.errors.end_date}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </FormikProvider>
                <Card.Footer>
                    <div className={'button-actions'}>
                        <Button variant={'success'} onClick={formik.submitForm}>Сгенерировать отчет</Button>
                        <Button variant={'secondary'} onClick={() => {
                            formik.setValues(formik.initialValues);
                        }}>Сбросить</Button>
                    </div>
                </Card.Footer>
            </Card>
        </React.Fragment>
    );
}

export default ReportCreate;
