import {DatabaseItem} from "./common";
import {StaffItem} from "./staff/item";
import {SpecialistItem} from "./specialist/item";
import {RidItem} from "./rid/item";
import {PublicationItem} from "./publication/item";
import {FinancingItem} from "./financing/item";
import Amortization from "./amortization/amortization";
import {AmortizationItem} from "./amortization/item";

export const DatabaseItemStaff = () => {
    return <DatabaseItem title={'staff'}>
        <StaffItem/>
    </DatabaseItem>
}

export const DatabaseItemSpecialist = () => {
    return <DatabaseItem title={'specialist'}>
        <SpecialistItem/>
    </DatabaseItem>
}

export const DatabaseItemRid = () => {
    return <DatabaseItem title={'rid'}>
        <RidItem/>
    </DatabaseItem>
}

export const DatabaseItemPublication = () => {
    return <DatabaseItem title={'publication'}>
        <PublicationItem/>
    </DatabaseItem>
}

export const DatabaseItemFinancing = () => {
    return <DatabaseItem title={'financing'}>
        <FinancingItem/>
    </DatabaseItem>
}

export const DatabaseItemAmortization = () => {
    return <DatabaseItem title={'amortization'}>
        <AmortizationItem/>
    </DatabaseItem>
}

