import * as React from 'react';
import {UploadFile} from "../../components/file_manager/file_handlers";
import {uploadFile} from "../../actions/file_manager";
import {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {setChangedValue} from "../../utils/utils";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import Breadcrumbs from "../../components/breadcrumbs";
import {Input} from "../../components/form/forms";
import {LoaderContext} from "../../utils/loader";
import {toast} from "react-toastify";

export const AbstractImport = ({
                                   title,
                                   fields,
                                   post_title,
                               }) => {
    const [input_values, setInputValues] = useState({...fields, worksheet: 'Лист 1'});
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const setShowLoading = useContext(LoaderContext);

    const handleChangeValue = (e) => {
        e.preventDefault();
        let target = e.target.getAttribute('data_change_key');
        let value = e.target.type === 'file' ? e.target.files[0].name : e.target.value;
        let key = e.target.id === 'new_file' ? 'filename' : e.target.id;
        if (e.target.type === 'file') {
            setFile(e.target.files[0]);
        } else {
            setChangedValue(setInputValues, input_values, target, key, value);
        }
    }

    const onSubmitForm = (e) => {
        e.preventDefault();
        let payload = {...input_values, ...input_values?.common};
        let worksheet = input_values?.common?.worksheet ? input_values?.common?.worksheet : input_values?.worksheet;
        delete payload?.worksheet;
        delete payload?.common;

        let formData = new FormData();
        formData.append('file', file);
        formData.append('worksheet', worksheet);
        formData.append('keys', Object.keys(payload));
        formData.append('labels', Object.values(payload));
        setShowLoading(true);
        uploadFile(`/api/${post_title}/`, formData, () => {
        }).then((response) => {
            if (response?.status === 200) {
                toast.success('Данные успешно сохранены');
            } else if (!response) {
                toast.warning('Время ожидания истекло');
            }
            setShowLoading(false);
            navigate(`/`);
        });
    }

    const Fields = () => {
        let elements = [];
        if (fields) {
            for (let key in fields) {
                elements.push(<Col key={key}>
                    <Input
                        handleChangeValue={handleChangeValue}
                        id={key}
                        label={fields[key]}
                        value={fields[key]}
                    />
                </Col>)
            }
        }
        return <Row className={'row-cols-1 row-cols-md-3 row-cols-xl-4'}>
            {elements}
        </Row>;
    }

    return <React.Fragment>
        <Breadcrumbs/>
        <div className="section-header sticky-top">
            <h1 className="page-header">
                {title}
            </h1>
        </div>
        <Form onSubmit={onSubmitForm}>
            <Card>
                <Card.Body>
                    <div className="alert alert-info">
                        Названия столбца должно совпадать со значением в соответствующем поле, либо это значение
                        необходимо изменить самостоятельно.
                    </div>
                    <Input id={'worksheet'} label={'Название листа'}
                           handleChangeValue={handleChangeValue}
                           value={'Лист 1'}
                    />
                    <hr/>
                    <Fields/>
                    <hr/>
                    <label>Файл импорта</label>
                    <UploadFile
                        handleChangeValue={handleChangeValue}
                        file_name={file?.name}
                        id={'upload_file'}
                    />
                </Card.Body>
                <Card.Footer>
                    <div className={'button-actions'}>
                        <Button variant={'success'} type={'submit'}>Далее</Button>
                        <Button variant={'secondary'} onClick={(e) => {
                            e.preventDefault();
                            window.location.reload()
                        }}>
                            Сбросить
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
        </Form>
    </React.Fragment>
}
