import {BaseLayout} from "../../components/base";
import * as React from "react";

export const NotFound = () => {
    return <BaseLayout>
        <section>
            <h1 className="h2 pb-2 mt-2 mb-2 border-bottom">
                Произошла ошибка
            </h1>
            <p className="lead">
                Страница не найдена.
            </p>
            <p>При повторении ошибки по адресу <a href="mailto:ccm@spbstu.ru">ccm@spbstu.ru</a></p>
        </section>
    </BaseLayout>
}