import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import {getRequest, putRequest} from "../../../actions/common";
import {STATUS_ACCEPT, STATUS_APPROVE, STATUS_NEW, STATUS_PROCESS} from "./constant";
import {Area, Input, Select} from "../../../components/form/forms";
import {PERMISSIONS} from "../../../rbac/constant";
import {useAbac} from "react-abac";
import {RID_DOCUMENT_TYPES, RID_STATUS, RID_TYPES} from "./constant";
import {setChangedValue} from "../../../utils/utils";
import {AuthContext} from "../../../auth";
import {REQUIRED_FIELDS} from "./constant";
import {toast} from "react-toastify";

export const RidCreate = (props) => {
    const {state} = useLocation();
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();
    const navigate = useNavigate();

    const [input_values, setInputValues] = useState({common: {}});
    const [select_data, setSelectData] = useState({});
    const [select_values, setSelectValues] = useState({});
    const [errors, setErrors] = useState(() => {
        let temp = {};
        REQUIRED_FIELDS[STATUS_ACCEPT].forEach((field) => {
            temp[field] = null;
        })
        return temp;
    });

    const select_fields = ['project', 'event', 'patent_office', 'organization', 'staff'];

    useEffect(() => {
        if (!userHasPermissions(PERMISSIONS.MANAGER_PANEL)) {
            navigate('/access-error');
        } else {
            document.title = `Создание записи в БД РИД | ИС «НЦМУ»`;
            if (user?.project?.id) {
                setSelectValues({...select_values, project: user?.project?.id})
            }
        }
    });

    useEffect(() => {
        if (Object.keys(select_data).length < select_fields.length) {
            for (let field of select_fields) {
                if (!Object.keys(select_data).includes(field)) {
                    getRequest(field, setSelectData, {type_data: 'all'}, '', select_data, field);
                    break
                }
            }
        }
    }, [select_data]);

    const getStatusClassName = (status) => {
        if (status === STATUS_NEW) {
            return 'bg-primary'
        } else if (status < STATUS_NEW) {
            return 'bg-success'
        } else if (status > STATUS_NEW) {
            return 'bg-secondary'
        }

        return null;
    }

    const handleChangeValue = (e) => {
        e.preventDefault();
        let target = e.target.getAttribute('data_change_key');
        let sub_key = e.target.getAttribute('data_foreign_id');
        setChangedValue(setInputValues, input_values, target, e.target.id, e.target.value, sub_key);
    }

    const generateOptions = (field, key) => {
        if (select_data && Object.keys(select_data).includes(field)) {
            let options = [];
            for (let element of select_data[field]) {
                options.push({value: element.id, label: element[key]});
            }
            return options;
        }
        return null;
    }

    const checkErrors = (status) => {
        let new_errors = {};
        let expression;
        let has_errors = false;

        REQUIRED_FIELDS[status].forEach((field) => {
            if (typeof select_values[field] === 'object') {
                expression = !select_values[field]?.length;
            } else {
                expression = !document.getElementById(field)?.value && (!select_values[`${field}_id`] && !select_values[field]);
            }
            if (expression) {
                new_errors[field] = 'Обязательное поле!';
                has_errors = true;
            } else {
                new_errors[field] = null;
            }
        })
        setErrors({...errors, ...new_errors})
        return has_errors
    }

    const saveRid = () => {
        let payload = {...input_values['common'], ...(select_values)};
        payload['status'] = state?.monitoring ? STATUS_ACCEPT : STATUS_NEW;
        putRequest('rid', payload).then(response => {
            if (response.status === 201) {
                navigate(`/rid/item/${response.data.id}?update=true`)
            }
        })
    }

    const handleSumitForm = (e) => {
        e.preventDefault();
        if (checkErrors(STATUS_NEW)) {
            toast.error('Ошибка в заполнении данных!');
            return null
        }
        saveRid();
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <Form onSubmit={handleSumitForm}>
                <div className="section-header sticky-top">
                    <h1 className="page-header">
                        {`Создание записи в БД РИДы`}
                    </h1>
                    <div className="button-actions">
                        <Button variant='success' type='submit'>Создать</Button>
                    </div>
                </div>
                <div
                    className="mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center">
                    <div className="mx-2 mb-2" style={{minWidth: "250px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_NEW)}`}>Новый</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{minWidth: "250px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_APPROVE)}`}>Рассматривается</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{minWidth: "250px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_PROCESS)}`}>Обрабатывается</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className="mx-2 mb-2" style={{minWidth: "250px"}}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_ACCEPT)}`}>Принято</p>
                    </div>
                </div>

                <Card className={'bg-light mb-3 mt-3'}>
                    <Card.Body>
                        <p>
                            <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                            условные обозначения,
                            используемые в интерфейсе:
                        </p>
                        <ul>
                            <li>поля помеченные <strong className="text-danger">красной «*»</strong> являются <strong>обязательными
                                на текущем
                                этапе для перехода на следующий</strong>;
                            </li>
                            <li>поля помеченные <strong className="text-success">зелёной «*»</strong> являются <strong>обязательными
                                для
                                определенных условий</strong>;
                            </li>
                            <li>кнопка <span className="badge badge-success">Сохранить</span> выполняет сохранение
                                текущего состояния карточки (требуется
                                заполнения ключевых полей);
                            </li>
                            <li>кнопка <span
                                className="badge badge-success">Сохранить + отправить далее</span> сохраняет карточку и
                                проверяет выполнение
                                условий текущего этапа (при выполнении условий карточка переходит на следующий этап).
                            </li>
                        </ul>
                        <hr/>
                        <fieldset>
                            <legend>Личные данные</legend>
                            <Row>
                                <Col md={6}>
                                    <Input label={'Наименование охранного документа на русском'} id={'title_ru'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('title_ru')}
                                           error={errors['title_ru']}
                                           invalid={errors['title_ru']}
                                           handleChangeValue={handleChangeValue}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input label={'Наименование охранного документа на английском'} id={'title_en'}
                                           handleChangeValue={handleChangeValue}
                                           small={'Если патент получен не в рамках ФИПС'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Select label={'Вид охранного документа'} id={'type'}
                                            options={RID_TYPES}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['type']}
                                            invalid={errors['type']}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('type')}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Select label={'Вид документа'} id={'document_type'}
                                            options={RID_DOCUMENT_TYPES}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['document_type']}
                                            invalid={errors['document_type']}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('document_type')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Select label={'Проект'} id={'project'}
                                            options={generateOptions('project', 'title')}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['project']}
                                            invalid={errors['project']}
                                            disabled={user ? user?.project?.id : false}
                                            required={true}/>
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Направление'} id={'direction'}
                                           value={user?.project?.direction}
                                           disabled={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Select label={'Связь с программой'} id={'event'}
                                            options={generateOptions('event', 'title')}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['event']}
                                            invalid={errors['event']}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('event')}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Select label={'Статус подачи'} id={'application_status'}
                                            options={RID_STATUS}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['application_status']}
                                            invalid={errors['application_status']}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('application_status')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Select label={'Заявленный правообладатель'} id={'organization'}
                                            options={generateOptions('organization', 'title_short')}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['organization']}
                                            invalid={errors['organization']}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('organization')}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Select label={'Страна действия патента'} id={'patent_office'}
                                            options={generateOptions('patent_office', 'title')}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['patent_office']}
                                            invalid={errors['patent_office']}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('patent_office')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Input label={'Дата получения охранного документа'} id={'date'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('date')}
                                           handleChangeValue={handleChangeValue}
                                           type={'date'}
                                           error={errors['patent_office']}
                                           invalid={errors['patent_office']}
                                           small={'Дата регистрации охранного документа'}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input label={'Номер'} id={'number'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('number')}
                                           error={errors['patent_office']}
                                           invalid={errors['patent_office']}
                                           handleChangeValue={handleChangeValue}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue} label={'Год зачета публикации'}
                                           id={'report_year'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('report_year')}
                                           error={errors['report_year']}
                                           invalid={errors['report_year']}
                                           type={'number'}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input label={'Дата подачи заявки'} id={'application_date'}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('application_date')}
                                           handleChangeValue={handleChangeValue}
                                           type={'application_date'}
                                           error={errors['application_date']}
                                           invalid={errors['application_date']}
                                    />
                                </Col>
                            </Row>
                            <Select label={'Авторы-сотрудники НЦМУ'}
                                    id={'authors'}
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    options={generateOptions('staff', 'fio')}
                                    select_values={select_values}
                                    setSelectValues={setSelectValues}
                                    required={REQUIRED_FIELDS[STATUS_NEW].includes('authors')}
                                    error={errors['authors']}
                                    invalid={errors['authors']}
                            />

                            <Area label={'Прочие авторы'} id={'other_authors'}
                                  handleChangeValue={handleChangeValue}
                            />
                            <Input label={'Ссылка на сопутствующие документы'} id={'related_docs_url'}
                                   handleChangeValue={handleChangeValue}
                                   small={'Скан документа, подтверждающий поле «Вид документа»'}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Прикрепленные файлы</legend>
                            <hr/>
                        </fieldset>
                    </Card.Body>
                </Card>
            </Form>
        </React.Fragment>
    );
}
