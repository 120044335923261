import React, {useEffect, useState} from "react";
import {
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {sortableKeyboardCoordinates} from "@dnd-kit/sortable";

import Droppable from "./droppable";
import Item from "./item";
import {arrayMove, insertAtIndex, removeAtIndex} from "../../utils/array";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Sortable = ({groups, labels, setGroups}) => {
    const [activeId, setActiveId] = useState(null);

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = ({active}) => setActiveId(active.id);

    const handleDragCancel = () => setActiveId(null);

    const handleDragOver = ({active, over}) => {
        const overId = over?.id;

        if (!overId || !over?.data?.current?.sortable || !over) {
            return;
        }

        const activeContainer = active.data.current.sortable.containerId;
        const overContainer = over.data.current.sortable.containerId || over.id;

        if (activeContainer !== overContainer) {
            setGroups((groups) => {
                const activeIndex = active.data.current.sortable.index;
                const overIndex =
                    over.id in groups
                        ? groups[overContainer].length + 1
                        : over.data.current.sortable.index;

                return moveBetweenContainers(
                    groups,
                    activeContainer,
                    activeIndex,
                    overContainer,
                    overIndex,
                    active.id
                );
            });
        }
    };

    const handleDragEnd = ({active, over}) => {
        if (!over || !over?.data?.current?.sortable || !over?.id) {
            setActiveId(null);
            return;
        }

        if (active.id !== over.id) {
            const activeContainer = active.data.current.sortable.containerId;
            const overContainer = over.data.current.sortable.containerId || over.id;
            const activeIndex = active.data.current.sortable.index;
            const overIndex =
                over.id in groups
                    ? groups[overContainer].length + 1
                    : over.data.current.sortable.index;

            setGroups((groups) => {
                let newItems;
                if (activeContainer === overContainer) {
                    newItems = {
                        ...groups,
                        [overContainer]: arrayMove(
                            groups[overContainer],
                            activeIndex,
                            overIndex
                        ),
                    };
                } else {
                    newItems = moveBetweenContainers(
                        groups,
                        activeContainer,
                        activeIndex,
                        overContainer,
                        overIndex,
                        active.id
                    );
                }

                return newItems;
            });
        }

        setActiveId(null);
    };

    const moveBetweenContainers = (
        items,
        activeContainer,
        activeIndex,
        overContainer,
        overIndex,
        item
    ) => {
        return {
            ...items,
            [activeContainer]: removeAtIndex(items[activeContainer], activeIndex),
            [overContainer]: insertAtIndex(items[overContainer], overIndex, item),
        };
    };
    let visible = false;

    return (
        <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
        >
            <div className="container">
                <Row>
                    {Object.keys(groups).map((group) => {
                        visible = !visible;
                        if (visible) {
                            return <React.Fragment>
                                <Droppable
                                    id={group}
                                    items={groups[group]}
                                    activeId={activeId}
                                    key={group}
                                    labels={labels}
                                    visible={visible}
                                />
                                <Col sm={2}>
                                    <div className={'sortable-separator'}>
                                        <FontAwesomeIcon icon={'arrows-alt-h'}/>
                                    </div>
                                </Col>
                            </React.Fragment>
                        } else {
                            return <Droppable
                                id={group}
                                items={groups[group]}
                                activeId={activeId}
                                key={group}
                                labels={labels}
                                visible={visible}
                            />
                        }
                    })}
                </Row>
            </div>
            <DragOverlay>{activeId ? <Item id={activeId} dragOverlay/> : null}</DragOverlay>
        </DndContext>
    );
}

export default Sortable;
