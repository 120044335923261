import * as React from "react";
import {Form, Table} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getRequest} from "../../actions/common";
import {Link} from "react-router-dom";
import {Input, InputDate, Select} from "../form/forms";
import {isInt, setChangedValue} from "../../utils/utils";
import {AuthContext} from "../../auth";
import {Field, FieldArray, FormikProvider, useFormik} from "formik";
import {FormikDate, FormikSelect} from "../form/formik";
import {useAbac} from "react-abac";
import {PERMISSIONS} from "../../rbac/constant";

const ReactTable = ({
                        id, data, labels, page, pageSize,
                        bordered, striped, hover,
                        setFunc, sort, get_title,
                        url_update = '',
                        url_field = '',
                        update_field = 'title',
                        update_params = null,
                        is_update = true,
                        is_show = false,
                        is_see_field = true,
                        additionalCheckField = null,
                        show_result = true,
                        update_left = true,
                        permission = null,
                        fields = null,
                        is_reset = false,
                        colorExpression,
                        ordering,
                        setOrdering,
                        setFiltering
                    }) => {
    const getShowArrayLength = () => {
        return data ? data.results.length : 0;
    }
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();

    const filtering = useFormik({
        initialValues: localStorage.getItem(`filtering_${get_title}`) ?
            JSON.parse(localStorage.getItem(`filtering_${get_title}`)) :
            {},
        enableReinitialize: true,
        onSubmit: values => handleSubmitFormOnBlur(values),
        validateOnChange: false, validateOnBlur: false
    })

    const [ordering_direction, setOrderingDirection] = useState('');
    const [ordering_icon, setOrderingIcon] = useState('');
    const [show_array, setShowArray] = useState(new Array(getShowArrayLength()).fill(false));
    const [first_init, setFirstInit] = useState(false);

    const [select_data, setSelectData] = useState({});

    useEffect(() => {
        if (ordering) {
            let index = Object.keys(labels).indexOf(ordering.replace('-', ''));
            if (index !== -1) {
                let new_show = [...show_array];
                new_show[index] = true;
                if (ordering[0] !== '-') {
                    setOrderingDirection('-');
                    setOrderingIcon("fas fa-sort-amount-down-alt");
                } else {
                    setOrderingDirection('');
                    setOrderingIcon("fas fa-sort-amount-up-alt");
                }
                setShowArray(new_show);
            }
        } else {
            let new_show = [...show_array].fill(false);
            setShowArray(new_show);
        }
    }, [ordering])

    useEffect(() => {
        for (let field in labels) {
            if (fields && !Object.keys(select_data).includes(field) && fields[field]?.type === Select && fields[field]?.source) {
                let params_req = {type_data: 'all'}
                if (user.roles.every((v) => v === 'project_manager') && field === 'project')
                    params_req['id'] = user?.project?.id;
                else if (user.roles.every((v) => v === 'project_manager') && field === 'event')
                    params_req['project'] = user?.project?.id;
                field === 'staff' || field === 'person' ?
                    getRequest(`${field}_select`, setSelectData, params_req, '', select_data, field)
                    :
                    getRequest(fields[field]?.source, setSelectData, params_req, '', select_data, field);
                break
            }
        }
    }, [select_data, labels]);

    const generateOptions = (field, key) => {
        if (data && select_data && Object.keys(select_data).includes(field)) {
            let options = [];
            for (let element of select_data[field]) {
                let label = null;
                if (typeof key === 'string') {
                    label = element[key]
                } else {
                    label = key(element);
                }
                options.push({value: element.id, label: label});
            }
            return options;
        }
        return null;
    }

    const appendCustomColumn = (array, element) => {
        if ((is_update || is_show) && update_left) {
            array.push(element);
        } else if ((is_update || is_show) && !update_left) {
            array.unshift(element);
        }
    }

    const checkField = (field, key, element) => {
        if (data && labels) {
            let response = null;
            if (additionalCheckField) {
                response = additionalCheckField(field, key, element);
                if (response !== null) {
                    field = response;
                }

            }
            if (field === true) {
                return <span className="badge badge-success">Да</span>
            } else if (field === false) {
                return <span className="badge badge-danger">Нет</span>
            } else if (String(field).indexOf('None') !== -1) {
                let res = String(field).replaceAll('None', '');
                if (res.replaceAll(' ', '')) {
                    return <span>{String(field).replaceAll('None', '')}</span>
                } else {
                    return <span className={'not-set'}>(не задано)</span>
                }
            } else if (typeof field === 'number' && !isInt(field)) {
                return Math.round(field * 100) / 100;
            } else if (key.includes('_date') && field && response === null) {
                let date = new Date(field);
                let date_str = field && date instanceof Date && !isNaN(date) ?
                    date.toISOString().split('T')[0].split('-')
                    : null
                return date_str ?
                    `${date_str[2]}.${date_str[1]}.${date_str[0]}` : field
            } else if (field !== null) {
                return field
            } else {
                return <span className={'not-set'}>(не задано)</span>
            }
        }
    }

    const TableHeader = () => {
        if (labels) {
            let elements = [];
            let i = 0;

            for (let key in labels) {
                if (!sort) {
                    elements.push(
                        <th key={key}>{labels[key]}</th>
                    )
                } else {
                    elements.push(
                        <th key={key}><Link to={'#'} onClick={orderField} data-filter={key}
                                            data-index={i}>{labels[key]}
                            {show_array[i] && ordering_icon ? <FontAwesomeIcon icon={ordering_icon}/> : null}
                        </Link>
                        </th>
                    )
                }
                i += 1;
            }
            if (url_update || is_show) {
                appendCustomColumn(elements, <th key={'update'}></th>);
            }
            return <tr>{elements}</tr>
        }
        return null;
    }

    const TableBody = () => {
        if (data && labels) {
            let rows = [];
            let elements = [];

            if (data?.count === 0) {
                return <tr>
                    <td colSpan={Object.keys(labels).length + 1}>Ничего не найдено.</td>
                </tr>
            }

            for (let element in data.results) {
                for (let key in labels) {
                    if (Object.keys(data.results[element]).includes(key)) {
                        let to_add;
                        if (key === update_field && is_see_field) {
                            to_add = <td key={key} className={key === update_field ? 'text-left' : ''}>
                                <Link to={`${url_field}/${data.results[element].id}`}>
                                    {checkField(data.results[element][key], key, data.results[element])}
                                </Link>
                            </td>
                        } else {
                            to_add = <td key={key} className={key === update_field ? 'text-left' : ''}>
                                {checkField(data.results[element][key], key, data.results[element])}
                            </td>;
                        }
                        if (key in labels) {
                            elements.push(to_add);
                        }
                    } else {
                        elements.push(<td key={key}/>)
                    }
                }
                if (url_update || is_show) {
                    appendCustomColumn(elements, <td key={`update-${element}`}>
                        {url_update && is_update ?
                            <Link to={`${url_update}/${data.results[element].id}/?update=true`}>
                                <FontAwesomeIcon icon={'pencil-alt'}/>
                            </Link> : null}
                        {is_show ? <Link to={`${url_update}/${data.results[element].id}/?update=false`}>
                                <FontAwesomeIcon icon={'eye'}/>
                            </Link>
                            : null}
                    </td>);
                }
                let style = null;
                if (colorExpression && !colorExpression(data.results[element])) {
                    style = {backgroundColor: '#ffc10740'}
                }
                rows.push(<tr style={style} key={element}>{elements}</tr>);
                elements = [];
            }
            return rows;
        }
    }

    const ShowResults = () => {
        if (data) {
            let page_ = page ? page : 1;
            let mult = data.results.length < pageSize ? data.count - data.results.length : pageSize;
            let to = data.results.length < pageSize ? data.count : (page_ * mult);
            let from = data.results.length < pageSize ? data.count - data.results.length + 1 : to - mult + 1;
            return <div className="pb-1">
                Позиции <b>{from}-{to}</b> из <b>{data.count}</b>
            </div>
        }
        return null
    }

    const orderField = (e) => {
        e.preventDefault()
        let order = '';
        let copy_show_array = [...show_array];
        copy_show_array.fill(false);
        if (ordering_icon === 'fas fa-sort-amount-up-alt') {
            order = null;
            setOrderingIcon('');
        } else {
            order = `${ordering_direction}${e.target.getAttribute('data-filter')}`;
            copy_show_array[Number(e.target.getAttribute('data-index'))] = true;
        }
        setShowArray(copy_show_array);
        setOrdering(order);
    }

    useEffect(() => {
        if (!first_init && setFiltering) {
            setFirstInit(true);
            setFiltering(filtering.values)
        }
    }, [filtering.values])

    useEffect(() => {
        if (is_reset) {
            filtering.setValues({});
        }
    }, [is_reset])

    const handleSubmitForm = (e) => {
        if (e.key === 'Enter' && setFiltering) {
            setFiltering(filtering.values);
        }
    }

    const handleSubmitFormOnBlur = (e) => {
        if (setFiltering) {
            setFiltering(filtering.values);
        }
    }

    return <React.Fragment>
        {show_result ? <ShowResults/> : null}
        <Form>
            <Table id={id} striped={striped} bordered={bordered} hover={hover} size={'sm'} responsive="xl">
                <thead><TableHeader/></thead>
                <tbody>
                <FormikProvider value={filtering}>
                    <FieldArray name={'filtering'} render={arrayHelper => {
                        let rows = [];
                        let elements = [];
                        if (fields && labels) {
                            for (let key in labels) {
                                let element = null;
                                let name = fields[key]?.id ? fields[key]?.id : key;
                                let props = fields[key]?.props ? {...fields[key]?.props} : null;
                                if (fields[key]?.type === Input) {
                                    let InputType = fields[key]?.type;
                                    element = <InputType
                                        className={'d-block'}
                                        show_zero={false}
                                        id={name}
                                        name={name}
                                        {...props}
                                        onBlur={handleSubmitFormOnBlur}
                                        onKeyPress={handleSubmitForm}
                                        value={filtering.values?.[name] ? filtering.values?.[name] : ''}
                                        handleChangeValue={filtering.handleChange}
                                        type={fields[key]?.content}
                                    />
                                } else if (fields[key]?.type === InputDate) {
                                    element = <Field component={FormikDate}
                                                     className={'d-block'}
                                                     id={name}
                                                     name={name}
                                                     submit_on_change={true}
                                                     onBlur={handleSubmitFormOnBlur}
                                                     onChange={handleSubmitFormOnBlur}
                                                     onKeyPress={handleSubmitForm}
                                                     value={filtering.values?.[name]}
                                                     handleChangeValue={filtering.handleChange}
                                                     type={fields[key]?.content}
                                    />
                                } else if (fields[key]?.type === Select) {
                                    element = <Field component={FormikSelect}
                                                     id={key}
                                                     name={key}
                                                     {...props}
                                                     submit_on_change={true}
                                                     style={{
                                                         menu: (baseStyles, state) => ({
                                                             ...baseStyles,
                                                             minWidth: '160px',
                                                         })
                                                     }}
                                                     isClearable={true}
                                                     isSearchable={true}
                                                     menuPortalTarget={document.body}
                                                     options={fields[key]?.options
                                                         ? fields[key]?.options
                                                         : generateOptions(key, fields[key]?.key ? fields[key]?.key : 'title')}
                                    />
                                }
                                elements.push(<td key={`${key}_filter`}>
                                    {element}
                                </td>)
                            }
                            if (url_update || is_show) {
                                appendCustomColumn(elements, <td key={Math.random()}/>)
                            }
                            rows.push(<tr key={'filter_row'}>{elements}</tr>);
                        }
                        return rows;
                    }}/>
                </FormikProvider>
                <TableBody/>
                </tbody>
            </Table>
        </Form>
    </React.Fragment>
}

export default ReactTable;
