import * as React from "react";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {Button, Card, Form} from "react-bootstrap";
import {useEffect, useState} from "react";
import {getRequest, putRequest} from "../../../actions/common";
import {SelectReact} from "../../../components/form/select";
import {Input} from "../../../components/form/forms";

const IndicatorDirectionCreate = () => {
    const [indicator, setIndicator] = useState(null);
    const [direction, setDirection] = useState(null);
    const [select_data, setSelectData] = useState({});
    const [select_values, setSelectValues] = useState({});

    const labels = {'indicator': 'Показатель', 'direction': 'Направление'};
    const select_fields = ['indicator', 'direction'];

    React.useEffect(() => {
        document.title = 'Создать | ИС «НЦМУ»';
    });

    useEffect(() => {
        if (!indicator) {
            getRequest('indicator', setIndicator, {type_data: 'all'});
        }
    }, [indicator]);

    useEffect(() => {
        if (!direction) {
            getRequest('direction', setDirection, {type_data: 'all'});
        }
    }, [direction]);

    useEffect(() => {
        if (Object.keys(select_data).length === 0 && indicator && direction) {
            setSelectData({indicator: indicator, direction: direction});
        }

    }, [direction, indicator]);

    const FilterSelects = () => {
        if (Object.keys(select_data).length !== 0) {
            let elements = [];
            select_fields.forEach((field) => {
                let options = [];
                select_data[field].forEach((element) => {
                    options.push({
                        value: element.id,
                        label: element.title
                    });
                })
                elements.push(
                    <div className='form-group' key={field}>
                        <Form.Label>{labels[field]}</Form.Label>
                        <SelectReact options={options} setSelectState={true} select_key={field}
                                     value={select_values[field]}
                                     setState={setSelectValues}
                                     select_values={select_values}
                                     required={true}
                                     selectID={`${field}_select`}/>
                    </div>
                )
            })
            return elements;
        }
        return null;
    }

    const onSubmitForm = (e) => {
        e.preventDefault();
        let data = {};
        const inputs = Array.from(e.target);
        inputs.forEach(({id, value}) => {
            if (value) {
                data[id] = value;
            }
        });
        if (Object.keys(data).length !== 0 && Object.keys(select_values).length !== 0) {
            select_fields.forEach((field) => {
                data[`${field}_id`] = select_values[field];
            })
            putRequest('indicator_direction', data);
            // window.location.href = '/indicator-direction/';
        }
    }

    const refreshFilter = () => {
        setSelectValues({});
        setSelectData({});
        getRequest('indicator', setIndicator, {type_data: 'all'});
        getRequest('direction', setDirection, {type_data: 'all'});
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={"page-header"}>
                Создать
            </h1>
            <form onSubmit={onSubmitForm}>
                <Card>
                    <Card.Body>
                        <FilterSelects/>
                        <Input label={'Значение'} type={'number'} value={0} id='value'/>
                        <Input label={'Ожидается'} type={'number'} value={0} id={'value_plan'}/>
                        <Input label={'Паспорт'} type={'number'} value={0} id={'value_passport'}/>
                    </Card.Body>
                    <Card.Footer>
                        <div className={'button-actions'}>
                            <Button variant='success' type='submit'>Сохранить</Button>
                            <Button variant='secondary' onClick={refreshFilter}>Сбросить</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </form>
        </React.Fragment>
    );
}

export default IndicatorDirectionCreate;
