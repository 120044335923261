import {useContext} from "react";
import {AuthContext} from "../auth";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAbac} from "react-abac";
import {PERMISSIONS} from "../rbac/constant";
import {deleteRequest} from "../actions/common";

export const setChangedValue = (setChanges, changes, target, key, value, sub_key = '', list_index = null) => {
    let new_changes = changes;
    if (!new_changes[target] && target) {
        new_changes[target] = {};
    }
    if (sub_key) {
        if (list_index === null) {
            new_changes[target][sub_key] ? new_changes[target][sub_key][key] = value : new_changes[target][sub_key] = {}
        } else {
            let temp = [];
            new_changes[target][sub_key] ? temp[list_index] = {...new_changes[target][sub_key][list_index], ...{[key]: value}} : null;
            new_changes[target][sub_key] ? new_changes[target][sub_key][list_index] = {...new_changes[target][sub_key][list_index], ...{[key]: value}} : new_changes[target][sub_key] = temp;
        }
    } else if (target) {
        new_changes[target][key] = value;
    } else {
        new_changes[key] = value;
    }
    setChanges(new_changes);
}

export const isValue = (value) => {
    return value ? true : false
}

export const isInt = (n) => {
    return n % 1 === 0;
}

export const convertBackendDate = (date_str) => {
    if (typeof date_str === 'string' && date_str.includes('.')) {
        let split_date = date_str.split('.');
        let date = new Date(`${split_date[2]}-${split_date[1]}-${split_date[0]}`);
        return date ? date : null
    }
}

export const btnMessage = () => {
    let card = document.getElementById('card-message');
    if (card && card.style.display === 'none') {
        card.style.display = 'block';
    } else if (card && card.style.display === 'block') {
        card.style.display = 'none';
    }
}


export const btnHistory = () => {
    let card = document.getElementById('card-history');
    if (card && card.style.display === 'none') {
        card.style.display = 'block';
    } else if (card && card.style.display === 'block') {
        card.style.display = 'none';
    }
}


export const getValueByKey = (dict, key, element = null) => {
    if (dict) {
        for (let index in dict) {
            if (dict[index].key === key) {
                return element ? dict[index][element] : dict[index];
            }
        }
    }
}

export const getLabelFromOptions = (options, value) => {
    for (let index in options) {
        if (options[index]?.value === value) {
            return options[index]?.label
        }
    }
}


export const findInSelectData = (data, key, value) => {
    for (let index in data) {
        if (data[index][key] === value) {
            return data[index]
        }
    }
}

export const Messages = ({messages_, status_labels, source, setMessages}) => {
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();

    const deleteMessage = (message) => {
        deleteRequest(`${source}_messages`, message.id).then();
        if (setMessages) {
            setMessages(messages_.filter(m => m?.id !== message.id))
        }
    }

    let blockquotes = [];
    if (messages_) {
        for (let message in messages_) {
            let status_name = `${source}_status`;
            blockquotes.push(<blockquote key={messages_[message].id} className={`blockquote 
                ${messages_[message].created_by === user?.fio ? 'blockquote_self' : 'blockquote_interlocutor'}`}>
                {messages_[message].content}
                <footer className="blockquote-footer">
                    <strong>{messages_[message].created_by} </strong>
                    от {messages_[message].created_at}.
                    ({status_labels[messages_[message][status_name]]})
                </footer>
                {userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && (messages_[message].created_by === user?.fio) &&
                    <button className={'close delete-comm'} onClick={(e) => {
                    e.preventDefault();
                    deleteMessage(messages_[message]);
                }}>
                    <FontAwesomeIcon icon={'trash-alt'}/>
                    </button>
                }
            </blockquote>)
        }
    }
    if (!blockquotes.length) {
        blockquotes = <blockquote className="m-2">Нет сообщений</blockquote>;
    }
    return <div className="message-history">
        {blockquotes}
    </div>
}


export const setRequiredField = (field, status_required, is_array = false) => {
    if (field && !isNaN(status_required)) {
        if (is_array)
            return field?.when(['status'], (status, schema) => {
                return (status[0] >= status_required) ? schema?.required('Обязательное поле!').min(1, 'Обязательное поле!') : schema
            })
        else return field?.when(['status'], (status, schema) => {
            return (status[0] >= status_required) ? schema?.required('Обязательное поле!') : schema
        })
    } else {
        return null
    }
}

export const formatDate = (date_str) => {
    if (date_str) {
        let date = new Date(date_str);
        if (date && !isNaN(date)) {
            return ('0' + date.getDate()).slice(-2) + '.'
                + ('0' + (date.getMonth() + 1)).slice(-2) + '.'
                + date.getFullYear();
        }
    }
}
