import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {Card, Col, Form, Row} from "react-bootstrap";
import {useContext, useEffect, useRef, useState} from "react";
import {getRequest, updateRequest} from "../../../actions/common";
import {
    STATUS_NEW,
} from "./constant";
import {Input} from "../../../components/form/forms";
import {PERMISSIONS, ROLES} from "../../../rbac/constant";
import {useAbac} from "react-abac";
import {findInSelectData} from "../../../utils/utils";
import {Field, FieldArray, FormikProvider, useFormik} from "formik";
import {REQUIRED_FIELDS_TEMP} from "./constant";
import {FormikDate, FormikSelect} from "../../../components/form/formik";
import {MyFileBrowser} from "../../../components/file_manager/file_manager";
import {AuthContext} from "../../../auth";


export const AmortizationItem = ({
                                     data,
                                     disabled,
                                     generateOptions,
                                     ActionButtons,
                                     DisableButton,
                                     setData,
                                     handleSubmit,
                                     ErrorsBlock
                                 }) => {
    const params = useParams();
    const {userHasPermissions} = useAbac();
    const user = useContext(AuthContext);
    const navigate = useNavigate();
    const project_ref = useRef();

    const [select_data, setSelectData] = useState({});
    const select_fields = ['project', 'event'];

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'full_sum': 0,
        'quarters': [{}, {}, {}, {}].fill({
            date: undefined,
            number: null,
            sum: null,
        }, 0, 3)
    });

    const formik = useFormik({
        validationSchema: REQUIRED_FIELDS_TEMP[STATUS_NEW],
        initialValues: input_fields,
        onSubmit: values => {
            return saveAmortization()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })


    useEffect(() => {
        handleSubmit(formik, saveAmortization);
    }, [formik.values])

    useEffect(() => {
        if (data && !user?.roles?.includes(ROLES.management) && !userHasPermissions(PERMISSIONS.CONTROL_FINANCING) && !userHasPermissions(PERMISSIONS.MANAGE_OWN_FINANCING, data)) {
            navigate('/access-error');
        } else if (data) {
            document.title = `Редактирование записи №${params.id} в БД Софинансирование | ИС «НЦМУ»`;
            let set_selected = {};
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id;
            })

            let set_inputs = {};
            for (let field in input_fields) {
                if (field !== 'quarters') {
                    set_inputs[field] = data[field]
                } else if (data[field]) {
                    set_inputs[field] = data[field]
                }
            }
            formik.setValues({...input_fields, ...set_inputs, ...set_selected, current_status: data.status});
        }

    }, [data]);

    useEffect(() => {
        if (data) {
            if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
                for (let field of select_fields) {
                    if (!Object.keys(select_data).includes(field) && field !== 'event') {
                        getRequest(field, setSelectData, {type_data: 'all'}, '', select_data, field);
                        break
                    }

                    if (!select_data?.event?.length && field === 'event' && formik.values?.project_id) {
                        getRequest('event', setSelectData, {
                            type_data: 'all',
                            project: formik.values?.project_id
                        }, '', select_data, 'event').then(r => {
                            if (formik.values?.event_id && !findInSelectData(r.data, 'id', formik.values?.event_id)) {
                                setInputFields({...formik.values, event_id: null})
                            }
                        });
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values]);

    useEffect(() => {
        if (formik.values?.project_id !== project_ref.current && Object.keys(select_data).includes('event')) {
            // formik.setFieldValue('event_id', null);
            setSelectData({...select_data, event: []})
        }
        if (formik.values?.project_id)
            project_ref.current = formik.values?.project_id;
    }, [formik.values])


    const saveAmortization = () => {
        formik.setFieldValue('save_form', false);
        updateRequest('amortization', {'common': formik.values}, params.id).then(response => {
            if (response.status === 200) {
                setData(response.data);
            }
        })

        return Promise.resolve('is_saved');
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <FormikProvider value={formik}>
                <Form>
                    <div className="section-header sticky-top">
                        <h1 className="page-header">
                            {`Редактирование записи №${params.id} в БД Амортизации`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}/>
                    </div>

                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className="text-danger">красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className="text-success">зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className="badge badge-success">Сохранить</span> выполняет сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className="badge badge-success">Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <legend>Данные аммортизации</legend>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'project_id'} label={'Проект'}
                                               id={'project_id'}
                                               required={true}
                                               isSearchable={true}
                                               isClearable={true}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               error={formik?.errors['project']}
                                               invalid={formik?.errors['project']}
                                               disabled={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Направление'}
                                               id={'direction'}
                                               value={data ? data.project?.direction : null}
                                               disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'event_id'} label={'Мероприятие'}
                                               id={'event_id'}
                                               required={true}
                                               isClearable={true}
                                               isSearchable={true}
                                               disabled={disabled}
                                               options={generateOptions('event', (element) => {
                                                   if (element?.title_short)
                                                       return `(${element.title_short}) ${element.title}`
                                                   else
                                                       return `${element.title}`
                                               }, select_data)}
                                               error={formik?.errors['event']}
                                               invalid={formik?.errors['event']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input label={'Общая сумма по всем кварталам'}
                                               type={'number'}
                                               small={'Высчитывается автоматически'}
                                               id={'full_sum'}
                                               name={'full_sum'}
                                               value={formik.values?.full_sum}
                                               disabled={true}
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                            <FieldArray name={'quarters'} render={arrayHelpers => (<div>
                                {arrayHelpers.form.values?.quarters?.map((stage, index) => (
                                    <fieldset>
                                        <legend>Квартал №{index + 1}</legend>
                                        <Row>
                                            <Col md={6}>
                                                <Input label={'Номер справки'}
                                                       id={`quarters.${index}.number`}
                                                       name={`quarters.${index}.number`}
                                                       disabled={disabled}
                                                       handleChangeValue={formik.handleChange}
                                                       error={formik?.errors?.quarters?.[index]?.['number']}
                                                       invalid={formik?.errors?.quarters?.[index]?.['number']}
                                                       value={stage?.number}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Field component={FormikDate}
                                                       label={'Дата справки'}
                                                       id={`quarters.${index}.date`}
                                                       name={`quarters.${index}.date`}
                                                       disabled={disabled}
                                                       handleChangeValue={formik.handleChange}
                                                       error={formik?.errors?.quarters?.[index]?.['date']}
                                                       invalid={formik?.errors?.quarters?.[index]?.['date']}
                                                       value={stage?.date}
                                                />
                                            </Col>
                                        </Row>
                                        <Input handleChangeValue={formik.handleChange}
                                               id={`quarters.${index}.sum`}
                                               name={`quarters.${index}.sum`}
                                               type={'number'}
                                               disabled={disabled}
                                               label={'Сумма'}
                                               error={formik?.errors?.quarters?.[index]?.['sum']}
                                               invalid={formik?.errors?.quarters?.[index]?.['sum']}
                                               value={stage?.sum}
                                        />
                                        <hr/>
                                        <label>Файл амортизации</label>
                                        <MyFileBrowser
                                            path={`root/storage/amortization/amortization_${params.id}/quarter_${index}`}
                                            instanceId={`quarter_${index}`}
                                            read_only={disabled}
                                            height={250}
                                        />
                                        <hr/>
                                    </fieldset>
                                ))}
                            </div>)}/>
                            <fieldset>
                                <legend>Прикрепленные файлы</legend>
                                <hr/>
                                <MyFileBrowser path={`root/storage/amortization/amortization_${params.id}`}
                                               read_only={disabled}
                                               instanceId={`amortization_${params.id}`}/>
                            </fieldset>

                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
        ;
}
