import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const IconText = ({text, icon, icon_color=null, props}) => {
    return (
        <React.Fragment>
            <div className={'d-inline-block svg-30-container'} {...props}>
                <FontAwesomeIcon icon={icon} color={icon_color}/>
            </div>
            {text}
        </React.Fragment>
    );
}