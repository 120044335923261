import React from "react";
import {useDroppable} from "@dnd-kit/core";
import {rectSortingStrategy, SortableContext} from "@dnd-kit/sortable";

import SortableItem from "./sortable_item";
import {Col} from "react-bootstrap";

const Droppable = ({id, items, labels, visible}) => {
    const {setNodeRef} = useDroppable({id});
    return (
        <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
            <Col sm={5}>
                <ul className="droppable" ref={setNodeRef}>
                    <li className={'alert-info'}>{id}</li>
                    {items.length === 0 ?
                        <SortableItem key={'hidden'}
                                      id={'hidden'}
                                      label={' '}
                                      disabled={true}/> : null}
                    {items.map((element) => {
                        return <SortableItem key={element} id={element} label={labels[element]} visible={visible}/>
                    })}
                </ul>
            </Col>
        </SortableContext>
    );
};

export default Droppable;
