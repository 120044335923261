import * as React from "react";
import DictField from "./common/item";
import {downloadRequest} from "../../actions/common";
import {Link} from "react-router-dom";
import {IconText} from "../../components/icon_txt";
import {DownloadFile} from "../../components/file_manager/file_handlers";
import Dict from "./common/dict";

export const AcademicDegreeField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       dict_title={'academic_degree'}
                       url_update={'/dicts/academic-degree/update'}
            />
        </React.Fragment>
    );
}


export const AcademicRankField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/academic-rank/update'}
                       dict_title={'academic_rank'}
            />
        </React.Fragment>
    );
}


export const CountryField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/country/update'}
                       dict_title={'country'}
            />
        </React.Fragment>
    );
}

export const DepartamentField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'parent', 'is_active', 'date_create', 'date_close', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
        'parent': 'ID родителя',
        'is_active': 'Активно?',
        'date_create': 'Дата открытия',
        'date_close': 'Дата закрытия'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       dict_title={'department'}
            />
        </React.Fragment>
    );
}

export const DirectionField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/direction/update'}
                       dict_title={'direction'}
            />
        </React.Fragment>
    );
}


export const EventCategoryField = (props) => {
    const fields = [
        'id', 'title', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/event-category/update'}
                       dict_title={'event_category'}
            />
        </React.Fragment>
    );
}


export const EventsField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id', 'category', 'project', 'is_educational'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
        'category': 'Категория',
        'project': 'Проект',
        'is_educational': 'Образовательное'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/event/update'}
                       dict_title={'event'}
            />
        </React.Fragment>
    );
}

export const JobContractField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/job-contract/update'}
                       dict_title={'job_contract'}
            />
        </React.Fragment>
    );
}

export const OrganizationField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/organization/update'}
                       dict_title={'organization'}
            />
        </React.Fragment>
    );
}

export const PatentOfficeField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/patent-office/update'}
                       dict_title={'patent_office'}
            />
        </React.Fragment>
    );
}

export const ProjectField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id', 'direction'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
        'direction': 'Направление'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/project/update'}
                       dict_title={'project'}
            />
        </React.Fragment>
    );
}

export const SelfAcademicDegreeField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/self-academic-degree/update'}
                       dict_title={'self_academic_degree'}
            />
        </React.Fragment>
    );
}

export const SelfAcademicRankField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/self-academic-rank/update'}
                       dict_title={'self_academic_rank'}
            />
        </React.Fragment>
    );
}

export const StaffCategoryField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/staff-category/update'}
                       dict_title={'staff_category'}
            />
        </React.Fragment>
    );
}

export const StaffPositionField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/staff-position/update'}
                       dict_title={'staff_position'}
            />
        </React.Fragment>
    );
}

export const StaffUnitField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/staff-unit/update'}
                       dict_title={'staff_unit'}
            />
        </React.Fragment>
    );
}

export const UnitField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/unit/update'}
                       dict_title={'unit'}
            />
        </React.Fragment>
    );
}

export const PublicationTypeField = (props) => {
    const fields = [
        'id', 'title', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/publication-type/update'}
                       dict_title={'publication_type'}
            />
        </React.Fragment>
    );
}

export const PersonField = (props) => {
    const fields = [
        'id', 'employee_id', 'last_name', 'first_name', 'middle_name', 'birth_date',
        'employment_date', 'dismissal_date', 'scientific_accounts', 'country', 'department',
        'self_academic_degree', 'academic_rank', 'created_at', 'updated_at'
    ];
    const labels = {
        'id': 'ID',
        'employee_id': 'Внешняя ссылка',
        'last_name': 'Фамилия',
        'first_name': 'Имя',
        'middle_name': 'Отчество',
        'age': 'Возраст',
        'birth_date': 'Дата рождения',
        'employment_date': 'Дата приема',
        'dismissal_date': 'Дата увольнения',
        'scientific_accounts': 'Научные идентификаторы',
        'country': 'Гражданство',
        'department': 'Основное подразделение',
        'self_academic_degree': 'Ученая степень',
        'academic_rank': 'Ученое звание',
        'created_at': 'Время создания',
        'updated_at': 'Время обновления'
    };

    const additional_labels = {
        'position': 'Должность',
        'department': 'Основное подразделение',
        'unit': 'Штатная единица',
        'account_num': 'Лицевой счет',
        'is_main': 'Основная должность',
        'created_at': 'Время создания',
        'cupdated_at': 'Время обновления',
    }

    const additionalCheckField = (field, key) => {
        if (['country', 'self_academic_degree', 'academic_rank'].includes(key)) {
            return field ? field.title : null
        } else if (key === 'scientific_accounts') {
            let elements = [];
            for (let account in field) {
                elements.push(
                    <div key={account}>
                        <span className="badge badge-secondary">{account}</span>
                        {` ${field[account]}`}
                    </div>
                )
            }
            return field ? elements : null
        }
        return null;
    }

    const tableCheckField = (field, key) => {
        if (['unit', 'department', 'position'].includes(key)) {
            return field ? field.title : null
        }
        return null;
    }

    return (
        <React.Fragment>
            <DictField labels={labels}
                       fields={fields}
                       url_update={''}
                       dict_title={'person'}
                       document_header={'fio'}
                       header={'fio'}
                       additional_table={'person_position'}
                       additional_table_header={'Должности'}
                       additional_field={'person_id'}
                       additional_labels={additional_labels}
                       tableCheckField={tableCheckField}
                       additionalCheckField={additionalCheckField}
            />
        </React.Fragment>
    );
}

export const OrderField = (props) => {
    const fields = [
        'id', 'title', 'description', 'filename'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'description': 'Описание',
        'filename': 'Файл',
    };

    const additionalCheckField = (field, key) => {
        if (key === 'filename') {
            return <DownloadFile folder={'orders'} file={field} title={'order'}/>
        }
        return null;
    }

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/order/update'}
                       additionalCheckField={additionalCheckField}
                       dict_title={'order'}
            />
        </React.Fragment>
    );
}

export const TemplateField = (props) => {
    const fields = [
        'id', 'title', 'description', 'filename', 'created_at', 'updated_at'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'description': 'Описание',
        'filename': 'Файл',
        'created_at': 'Время создания',
        'updated_at': 'Время обновления'
    };

    const additionalCheckField = (field, key) => {
        if (key === 'filename') {
            return <DownloadFile folder={'templates'} file={field} title={'template'}/>
        }
        return null;
    }

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/template/update'}
                       additionalCheckField={additionalCheckField}
                       dict_title={'template'}
            />
        </React.Fragment>
    );
}


export const GrantField = (props) => {
    const fields = [
        'event',
        'category',
        'grant',
        'account',
        'year',
    ];

    const labels = {
        'event': 'Мероприятие',
        'category': 'Категория',
        'grant': 'Грант',
        'account': 'Номер лицевого счёта',
        'year': 'Год',
    };

    const additionalCheckField = (field, key) => {
        if (key === 'event') {
            let label = null;
            if (field.title_short) {
                label = `(${field.title_short}) ${field.title}`
            } else {
                label = field.title
            }
            return label
        }
        return null;
    }

    return (
        <React.Fragment>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/grant/update'}
                       additionalCheckField={additionalCheckField}
                       dict_title={'grant'}
            />
        </React.Fragment>
    );
}