import * as React from "react";
import {Button, Card, Form} from "react-bootstrap";
import {useEffect, useState} from "react";
import {getRequest, putRequest} from "../../../actions/common";
import {SelectReact} from "../../../components/form/select";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {Area, Check, Input, Select} from "../../../components/form/forms";
import {useNavigate} from "react-router-dom";
import {UploadFile} from "../../../components/file_manager/file_handlers";
import {setChangedValue} from "../../../utils/utils";
import {uploadFile} from "../../../actions/file_manager";
import {toast} from "react-toastify";


const DictCreate = ({
                        dict_title, fields, labels,
                        custom_fields = null, custom_props,
                        select_fields = null, select_options = null,
                        checkFields,
                    }) => {
    const [changes, setChanges] = useState({common: {}});
    const [select_data, setSelectData] = useState({});
    const [select_values, setSelectValues] = useState({});
    const [errors, setErrors] = useState({});
    const [file, setFile] = useState(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        document.title = 'Создать | ИС «НЦМУ»';
    });

    useEffect(() => {
        if (Object.keys(select_data).length === 0 && select_fields !== null) {
            for (let field in select_fields) {
                if (!Object.keys(select_data).includes(select_fields[field])
                    && !Object.keys(select_options).includes(select_fields[field])) {
                    getRequest(select_fields[field], setSelectData, {}, '', select_data, select_fields[field]);
                }
            }
        }
    }, [select_data]);

    const handleChangeValue = (e) => {
        if (e.target.type !== 'checkbox')
            e.preventDefault();

        let target = e.target.getAttribute('data_change_key');
        let value = e.target.type === 'file' ? e.target.files[0].name : e.target.value;
        let key = e.target.id === 'new_file' ? 'filename' : e.target.id;
        if (e.target.type === 'file') {
            setFile(e.target.files[0]);
        }
        setChangedValue(setChanges, changes, target, key, value);
    }

    const refreshFilter = () => {
        if (fields) {
            fields.forEach((field) => {
                document.getElementById(field).value = '';
            })
        }
        setSelectValues({});
    }

    const sendFile = () => {
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('path', `root/${dict_title}s`);
            uploadFile('/api/file/', formData, () => {
            });
        }
    }

    const onSubmitForm = (e) => {
        e.preventDefault();
        let er = null;
        if (checkFields) {
            er = checkFields({...changes['common'], ...select_values, file: file});
            setErrors(er);
        }
        if (!er) {
            let payload = {...changes['common'], ...select_values};
            putRequest(dict_title, payload).then();
            sendFile();
            navigate(`/dicts/${dict_title.replaceAll('_', '-')}`);
        } else {
            toast.error('Ошибка в заполнении данных!');
        }
    }


    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={"page-header"}>
                Создать
            </h1>
            <form onSubmit={onSubmitForm}>
                <Card>
                    <Card.Body>
                        {fields ? fields.map(field => <Input id={field} key={field} label={labels[field]}
                                                             handleChangeValue={handleChangeValue}
                                                             value={changes.common[field]}
                                                             invalid={errors?.[field]}
                                                             error={errors?.[field]}
                                                             data_change_key={'common'}
                        />) : null}

                        {custom_fields ? Object.keys(custom_fields).map(key => {
                            let Field = custom_fields[key];
                            let props = custom_props ? custom_props[key] ? custom_props[key] : {} : {};
                            if (Field === Input || Field === Area || Field === Check) {
                                props['label'] = labels[key];
                                props['value'] = changes?.common?.[key];
                                props['error'] = errors?.[key];
                                props['invalid'] = errors?.[key];
                                props['handleChangeValue'] = handleChangeValue;
                            } else if (Field === Select) {
                                props['label'] = labels[key];
                                props['error'] = errors?.[key];
                                props['invalid'] = errors?.[key];
                                props['select_values'] = select_values;
                                props['setSelectValues'] = setSelectValues;
                                props['options'] = select_options ? select_options[key] ? select_options[key] : null : null;
                            } else if (Field === UploadFile) {
                                props['handleChangeValue'] = handleChangeValue;
                                props['error'] = errors?.[key];
                                props['file_name'] = file?.name;
                            }

                            return (<Field {...props}
                                           key={key}
                                           id={key}
                                           data_change_key={'common'}
                            />)
                        }) : null}

                        {select_fields && Object.keys(select_data).length !== 0 ?
                            select_fields.map((field) => {
                                let options = [];
                                for (let i = 0; i < select_data[field].results.length; i++) {
                                    if (select_data[field].results[i]) {
                                        options.push({
                                            value: select_data[field].results[i].id,
                                            label: select_data[field].results[i].title
                                        });
                                    }
                                }
                                return (
                                    <div className='form-group' key={field}>
                                        <Form.Label>{labels[field]}</Form.Label>
                                        <SelectReact options={options} setSelectState={true} select_key={field}
                                                     setState={setSelectValues}
                                                     select_values={select_values}
                                                     value={select_values[field]}
                                                     selectID={`${field}_select`}/>
                                    </div>
                                )
                            })
                            : null}
                        {/*<CustomFields/>*/}
                        {/*<FilterSelects/>*/}
                    </Card.Body>
                    <Card.Footer>
                        <div className={'button-actions'}>
                            <Button variant='success' type='submit'>Сохранить</Button>
                            <Button variant='secondary' onClick={refreshFilter}>Сбросить</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </form>
        </React.Fragment>
    );
}

export default DictCreate;
