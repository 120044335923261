import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {AuthContext} from "../../auth";
import {useAbac} from "react-abac";
import {deleteRequest, getRequest} from "../../actions/common";
import {Button, Modal} from "react-bootstrap";
import {IconText} from "../../components/icon_txt";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Messages} from "../../utils/utils"
import {toast} from "react-toastify";


export const DatabaseItem = (props) => {
    const [constant, setConstant] = useState(null);
    useEffect(() => {
        if (!constant) {
            import(`./${props.title}/constant`).then(result => {
                setConstant(result);
            });
        }
    }, [constant])

    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();
    const navigate = useNavigate();
    const [show_delete, setShowDelete] = useState(false);

    const [data, setData] = useState(null);
    const [messages, setMessages] = useState(null);

    const [show_buttons, setDisabledButtons] = useState(searchParams.has('update') ? searchParams.get('update') !== 'true' : false);
    const [disabled, setDisabled] = useState(searchParams.has('update') ? searchParams.get('update') !== 'true' : false);
    const [view, setView] = useState(null);


    useEffect(() => {
        if (!messages) {
            getRequest(`${props.title}_messages`, setMessages, {type_data: 'all', [props.title]: params.id}).then();
        }
    }, [messages]);

    useEffect(() => {
        if (data && !view && user && constant) {
            setView(!user.roles.some((role) => constant.CAN_MANAGE_ROLES[data.status].includes(role)));
        } else if (view) {
            setDisabled(true);
        }
    }, [data, view]);

    useEffect(() => {
        if (!data) {
            getRequest(props.title, setData, {}, params.id, null, null, true);
        }
    }, [data]);

    const generateOptions = (field, key, select_data, permission_control) => {
        if (data && select_data && Object.keys(select_data).includes(field)) {
            let options = [];
            for (let element of select_data[field]) {
                let label = null;
                if (typeof key === 'string') {
                    label = element[key]
                } else {
                    label = key(element);
                }

                if (field === 'staff_category'
                    && constant?.CHECK_CATEGORY.hasOwnProperty(element?.title_short)
                    && !userHasPermissions(permission_control)) {
                    options.push({
                        value: element.id,
                        label: label,
                        isDisabled: constant?.CHECK_CATEGORY ? !constant?.CHECK_CATEGORY[element.title_short](data) : false
                    })
                } else {
                    options.push({value: element.id, label: label});
                }
            }
            return options;
        }
        return null;
    }

    const getStatusClassName = (status) => {
        if (data) {
            if (status === data.status) {
                return 'bg-primary'
            } else if (status < data.status) {
                return 'bg-success'
            } else if (status > data.status) {
                return 'bg-secondary'
            }
        }
        return null;
    }

    const handleSaveAndMove = (move_index, form) => {
        form.setFieldValue('status', data.status + move_index);
        form.setFieldValue('save_form', true);

        getRequest(`${props.title}_messages`, setMessages, {type_data: 'all', [props.title]: params.id}).then();
    }

    const handleDelete = () => {
        deleteRequest(props.title, params.id).then((response) => {
            if (response.status) {
                navigate(`/${props.title}`)
            }
        });
    }

    const handleReset = (e) => {
        getRequest(props.title, setData, {}, params.id, null, null, true).then();
        getRequest(`${props.title}_messages`, setMessages, {type_data: 'all', [props.title]: params.id}).then();
    }

    const handleSubmit = (formik, saveFunction) => {
        if (data && formik.values?.save_form === true) {
            if (formik.values?.status < data.status) {
                if (formik.values?.message || formik.values?.without_message) {
                    saveFunction().then(result => {
                        if (result !== 'is_saved') {
                            toast.error('Ошибка в заполнении данных!');
                        }
                    });
                } else {
                    formik.setFieldError('message', 'Обязательное поле!');
                    toast.error('Ошибка в заполнении данных!');
                }
            } else {
                let errors = {...formik.errors};
                delete errors?.message;
                formik.setErrors(errors);

                formik.submitForm().then(result => {
                    if (result !== 'is_saved') {
                        toast.error('Ошибка в заполнении данных!');
                    }
                })
            }
        }
        if (formik.values?.save_form === true) {
            formik.setFieldValue('save_form', false);
            setTimeout(() => {
                getRequest(`${props.title}_messages`, setMessages, {type_data: 'all', [props.title]: params.id}).then();
            }, 600)
        }
    }

    const DisableButton = () => {
        if (!view) {
            return <Link id="w0" className="btn btn-link" onClick={() => {
                setDisabledButtons(!show_buttons)
                setDisabled(!disabled);
            }} to={`#`}>
                {!disabled || !show_buttons ? <FontAwesomeIcon icon={'eye'}/> :
                    <FontAwesomeIcon icon={'pencil-alt'}/>}
            </Link>
        } else {
            return null
        }
    }

    const ActionButtons = ({
                               form,
                               permission_control,
                               permission_manage,
                               additional_buttons = [],
                               can_back = false
                           }) => {
        let delete_btn = null;
        let to_return = [];

        if ((data && constant) && (!show_buttons && !view)) {
            let to_return = [<Button key={'save'} variant='success' onClick={() => {
                form.setFieldValue('status', data.status);
                form.setFieldValue('save_form', true);
            }}>
                <IconText icon={'save'} text={'Сохранить'}/>
            </Button>, <Button key={'reset'} variant={'secondary'} onClick={() => {
                form.setFieldValue('reset_form', true);
                handleReset();
            }}>
                <IconText icon={'ban'} text={'Сбросить'}/>
            </Button>]
            if (data.status < constant?.STATUS_ACCEPT) {
                to_return.push(<Button key={'save_plus'} variant={'success'} onClick={() => {
                    handleSaveAndMove(1, form)
                }}>
                    <IconText icon={'save'}
                              text={`Сохранить + ${data?.status + 1 < constant?.STATUS_ACCEPT
                                  ? 'отправить далее' : 'принять'}`}/>
                </Button>)
            }
            if (userHasPermissions(permission_manage)
                && data?.status < constant?.STATUS_ACCEPT
                && data?.status > constant?.STATUS_NEW
                || can_back) {
                to_return.push(<Button key={'undo'} variant={'warning'} onClick={() => {
                    form.setFieldValue('is_back', true);
                    handleSaveAndMove(-1, form);
                }}>
                    <FontAwesomeIcon icon={'save'}/> +<IconText icon={'redo-alt'} text={'на доработку'}/>
                </Button>)
            }
            if (data.status <= constant?.STATUS_NEW || userHasPermissions(permission_control) || constant?.STATUS_ADDITIONAL_INFO) {
                delete_btn = (<Button key={'delete'} variant={'danger'} onClick={() => {
                    setShowDelete(true)
                }}>
                    <FontAwesomeIcon icon={'trash-alt'}/>
                </Button>)
            }
            return <React.Fragment>
                <Modal size={'lg'} show={show_delete} centered={true} onHide={() => setShowDelete(false)}>
                    <div className={'modal-content'}>
                        <Modal.Header style={{backgroundColor: '#bd2130', color: 'white'}}>
                            <Modal.Title>
                                Вы действительно хотите удалить данную запись?
                            </Modal.Title>
                            <button className={'close'} onClick={() => {
                                setShowDelete(false)
                            }}><FontAwesomeIcon icon={'times'}/></button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={'button-actions'}>
                                <Button key={'delete'} variant={'danger'} onClick={handleDelete}>
                                    <FontAwesomeIcon icon={'trash-alt'}/> Удалить
                                </Button>
                                <Button variant={'secondary'} onClick={() => setShowDelete(false)}>
                                    Отменить
                                </Button>
                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
                <div className={'button-actions'}>
                    {[...to_return, ...additional_buttons, delete_btn]}
                </div>
            </React.Fragment>
        }
    }

    const MessageHistory = () => {
        return <Messages messages_={messages}
                         setMessages={setMessages}
                         status_labels={constant?.STATUS_LABELS}
                         source={props.title}/>
    }

    const ErrorsBlock = ({form}) => {

        const generateErrorDict = (err) => {
            let temp = [];
            if (err)
                for (const [key, value] of Object?.entries(err)) {
                    if (value !== '' && value) {
                        if (value instanceof Array) {
                            temp = temp.concat(generateErrorList(value));
                        } else if (['status', 'is_checked'].includes(key)) {
                            if (err[key])
                                temp.push(<li key={key.replace('_id', '')}>
                                    {`${err[key]}`}
                                </li>)
                        } else if (key !== 'message') {
                            let label = null;
                            if (constant?.ERROR_LABELS
                                && constant?.ERROR_LABELS[key.replace('_id', '')]) {
                                label = constant?.ERROR_LABELS[key.replace('_id', '')]
                            } else if (constant?.LABELS[key.replace('_id', '')]) {
                                label = constant?.LABELS[key.replace('_id', '')]
                            }
                            if (label)
                                temp.push(<li key={key.replace('_id', '')}>
                                    {`Необходимо заполнить: "${label}"`}
                                </li>)
                        }
                    }
                }
            return temp
        }

        const generateErrorList = (err) => {
            let temp = [];
            for (const value of err) {
                temp = temp.concat(generateErrorDict(value))
            }
            return temp
        }

        if (form.errors) {
            let temp = generateErrorDict(form.errors)
            return <div className="alert alert-danger" style={{display: temp.length > 0 ? "block" : "none"}}>
                <p>Исправьте следующие ошибки:</p>
                <ul>
                    {generateErrorDict(form.errors)}
                </ul>
            </div>
        } else return null
    }

    const HeaderStatus = ({style}) => {
        let elements = [];
        if (constant?.STATUS_LABELS) {
            for (let index in constant.STATUS_LABELS) {
                let status = Number(index);
                if (status >= 1) {
                    if (status !== 1) {
                        elements.push(
                            <FontAwesomeIcon key={`arrow_${status}`} icon={'long-arrow-alt-right'}
                                             className={'text-dark mb-2 fa-2x'}/>
                        )
                    }
                    elements.push(
                        <div key={`status_${status}`} className="mx-2 mb-2" style={style}>
                            <p className={`m-0 p-2 rounded ${getStatusClassName(status)}`}>{constant.STATUS_LABELS[status]}</p>
                        </div>
                    );
                }
            }
        }
        return elements;
    }

    return React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                data,
                setData,
                user,
                disabled,
                messages,
                getStatusClassName,
                handleSubmit,
                handleSaveAndMove,
                generateOptions,
                ErrorsBlock,
                MessageHistory,
                ActionButtons,
                DisableButton,
                HeaderStatus,
                setDisabled,
                setView,
            });
        }
        return child;
    })
}

