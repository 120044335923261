import * as React from "react";
import {Button, Card, Col, Dropdown, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactPagination from "../pagination/pagination";
import {useContext, useEffect, useState} from "react";
import {IconText} from "../icon_txt";
import Sortable from "../sortable/sortable";
import {getRequest} from "../../actions/common";
import ReactTable from "./react_table";
import {useAbac} from "react-abac";
import {AuthContext} from "../../auth";
import {Input} from "../form/forms";
import * as XLSX from 'xlsx/xlsx.mjs';
import {LoaderContext} from "../../utils/loader";
import {delete_cols, delete_row, format_excel} from "../../utils/excel";
import {ROLES} from "../../rbac/constant";


const ReactFilterTable = (props) => {
    const {userHasPermissions} = useAbac();
    const user = useContext(AuthContext);
    const setShowLoading = useContext(LoaderContext);

    const [ordering, setOrdering] = useState(localStorage.getItem(`ordering_${props.get_title}`));
    const [filtering, setFiltering] = useState(localStorage.getItem(`filtering_${props.get_title}`) ?
        JSON.parse(localStorage.getItem(`filtering_${props.get_title}`)) :
        {});
    const [currentPage, setCurrentPage] = useState(Number(props.page));

    const [show_filter, setShowFilter] = useState(false);
    const [show_sort, setShowSort] = useState(false);
    const [show_settings, setShowSettings] = useState(false);
    const [show_all, setShowAll] = useState(false);

    const [first_initial, setFirstInitial] = useState(false);

    const [page_limit, setPageLimit] = useState(10);
    const [groups, setGroups] = useState(localStorage.getItem(`groups_${props.get_title}`) ?
        JSON.parse(localStorage.getItem(`groups_${props.get_title}`)) :
        props.groups);

    useEffect(() => {
        if (props?.filtering?.reset_filters) {
            setFiltering({});
            setOrdering('');
            setPageLimit(10);
        }
    }, [props.filtering])

    const getActiveFields = () => {
        let labels = {};
        props.groups['Отображаемые колонки'].forEach((element) => {
            labels[element] = props.labels[element];
        })
        return labels;
    }

    useEffect(() => {
        if (localStorage.getItem(`groups_${props.get_title}`) && !first_initial) {
            props.setGroups(groups);
        }
        setFirstInitial(true);
        localStorage.setItem(`groups_${props.get_title}`, JSON.stringify(groups));
    }, [groups])

    useEffect(() => {
        if (filtering instanceof Object && props.filtering instanceof Object) {

            if (page_limit > 10) {
                setShowLoading(true);
            }
            if (user && !user?.roles?.every((v) => v === 'project_manager')) {
                getRequest(props.get_title, props.setFunc, {
                    page: currentPage,
                    ordering: ordering,
                    page_size: page_limit,
                    ...filtering,
                    ...props?.filtering
                }, '', null, null, false, false).then(r => {
                    if (r?.response?.status === 404 && r?.response?.data?.detail === 'Неправильная страница')
                        setCurrentPage(1);
                    setShowLoading(false)
                });
            } else if (user) {
                delete filtering?.project;
                getRequest(props.get_title, props.setFunc, {
                    page: currentPage,
                    ordering: ordering,
                    project: user?.project?.id,
                    page_size: page_limit,
                    ...filtering,
                    ...props?.filtering
                }, '', null, null, false, false).then(r => {
                    if (r?.response?.status === 404 && r?.response?.data?.detail === 'Неправильная страница')
                        setCurrentPage(1);
                    setShowLoading(false)
                });
            }
            localStorage.setItem(`filtering_${props.get_title}`, JSON.stringify(filtering))
            localStorage.setItem(`ordering_${props.get_title}`, ordering)
        }

    }, [ordering, currentPage, filtering, props.filtering, page_limit])

    const downloadPage = (type) => {
        if (type !== 'json') {
            let data = document.getElementById('tblToExcl');
            let excelFile = XLSX.utils.table_to_book(data, {
                sheet: "sheet1",
                dateNF: 'mm.dd.yyyy',
                cellDates: false,
                raw: true
            });
            let ws = excelFile.Sheets["sheet1"];
            delete_row(ws, 1);
            delete_cols(ws, 0, 1);
            // format_excel(ws);
            // XLSX.write(excelFile, {bookType: type, bookSST: true, type: 'base64', dateNF:'dd.mm.yyyy'});
            XLSX.writeFile(excelFile, `${props.get_title}.${type}`);
        }
    }

    const ShowResults = () => {
        let data = props.data;
        let page = props.page;

        if (data) {
            let page_ = page ? page : 1;
            let mult = data.results.length < page_limit ? data.count - data.results.length : page_limit;
            let to = data.results.length < page_limit ? data.count : (page_ * mult);
            let from = data.results.length < page_limit ? data.count - data.results.length + 1 : to - mult + 1;
            return <div
                className="summary">Показаны <b>{from}-{to}</b> из <b>{props.data ? props.data.count : null}</b> записи.
            </div>
        }
        return null
    }

    const TableModal = ({setFunc, show, icon, title, body, footer}) => {
        return <Modal size={'lg'} show={show} centered={true} onHide={() => setFunc(false)}>
            <div className={'modal-content'}>
                <Modal.Header><Modal.Title>
                    <IconText icon={icon} text={title}/></Modal.Title>
                    <button className={'close'} onClick={() => {
                        setFunc(false)
                    }}><FontAwesomeIcon icon={'times'}/></button>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>{footer}</Modal.Footer>
            </div>
        </Modal>
    }

    return <React.Fragment>
        <TableModal setFunc={setShowFilter} show={show_filter} icon={'filter'}
                    title={'Сохранить / изменить таблицу Filter'}/>
        <TableModal setFunc={setShowSort} show={show_sort} icon={'sort'}
                    title={'Сохранить / изменить таблицу Sort'}/>

        <Modal size={'lg'} show={show_settings} centered={true} onHide={() => setShowSettings(false)}>
            <div className={'modal-content'}>
                <Modal.Header><Modal.Title>
                    <IconText icon={'wrench'} text={'Настройка таблицы'}/></Modal.Title>
                    <button className={'close'} onClick={() => {
                        setShowSettings(false)
                    }}><FontAwesomeIcon icon={'times'}/></button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={4}>
                            <Input label={'Записей на странице'}
                                   id={'table_page_size'}
                                   small={'Число от 0 и до 100'}
                                   type={'number'}
                                   max={100}
                                   min={10}
                                   value={page_limit}
                            />
                        </Col>
                        <div className={'sortable-column-label'}> Настроить порядок и отображение колонок</div>
                        <Sortable groups={groups} labels={props.labels} setGroups={setGroups}/>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'danger'} onClick={() => {
                        window.location.reload();
                        localStorage.removeItem(`groups_${props.get_title}`);
                    }}><IconText text={'Удалить'} icon={'trash-alt'}/></Button>
                    <Button variant={'secondary'} onClick={() => {
                        setGroups(props.groups);
                    }}><IconText text={'Сбросить'} icon={'redo'}/></Button>
                    <Button onClick={() => {
                        props.setGroups(groups);
                        let page_size = document.getElementById('table_page_size');
                        if (page_size) {
                            setPageLimit(Number(page_size.value))
                        }
                        setShowSettings(false);
                    }}><IconText text={'Применить'} icon={'save'}/></Button>
                </Modal.Footer>
            </div>
        </Modal>

        <Modal size={'lg'} show={show_all} centered={true} onHide={() => setShowAll(false)}>
            <div className={'modal-content'}>
                <Modal.Header style={{backgroundColor: '#ffc107', borderColor: '#ffc107'}}>
                    <Modal.Title>
                        Подтверждение
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {`Всего записей: ${props?.data?.count}. Хотите отобразить их все?`}
                </Modal.Body>
                <Modal.Footer>
                    <div className={'button-actions'}>
                        <Button variant={'secondary'} onClick={() => setShowAll(false)}><IconText icon={'ban'}
                                                                                                  text={'Отмена'}/></Button>
                        <Button variant={'warning'} onClick={(event) => {
                            event.preventDefault();
                            setPageLimit(Number(props.data?.count));
                            setCurrentPage(1);
                            setShowAll(false);
                        }}><IconText icon={'check'} text={'Ok'}/></Button>
                    </div>
                </Modal.Footer>
            </div>
        </Modal>


        <Card>
            <Card.Header className={'text-right'}>
                <ShowResults/>
            </Card.Header>
            <Card.Body>
                <div className={'text-right mb-3'}>
                    <Button variant={'outline-secondary'} className={'mr-1'}
                            onClick={() => setShowSettings(true)}><FontAwesomeIcon
                        icon={'wrench'}/></Button>
                    <Dropdown className={'mr-1 d-inline-block'}>
                        <Dropdown.Toggle variant={'outline-secondary'}>
                            <FontAwesomeIcon icon={'external-link-alt'}/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>
                                Экспорт данных с текущей страницы
                            </Dropdown.Header>
                            <Dropdown.Item onClick={() => downloadPage('xlsx')}>
                                <IconText text={'Excel'} icon={'file-excel'} icon_color={'green'}/>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => downloadPage('csv')}>
                                <IconText text={'CSV'} icon={'file-csv'} icon_color={'#007bff'}/>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => downloadPage('json')} disabled={true}>
                                <IconText text={'JSON'} icon={'file-code'} icon_color={'#ffc107'}/>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant={'outline-secondary'} className={'mr-1'}
                            onClick={(event) => {
                                page_limit === 10 ? setShowAll(true) : setPageLimit(10)
                            }}>
                        <FontAwesomeIcon icon={'expand'}/>
                        {page_limit === 10 ? ' Все' : ' Страница'}
                    </Button>
                </div>
                <div className="table-responsive kv-grid-container">
                    <ReactTable
                        id={'tblToExcl'}
                        data={props.data} bordered={true} page={currentPage} pageSize={10}
                        fields={props.fields}
                        labels={getActiveFields()}
                        update_left={false}
                        show_result={false}
                        url_update={props.url_update}
                        is_show={true}
                        is_see_field={false}
                        sort={true}
                        get_title={props.get_title}
                        update_params={{state: null}}
                        setFunc={props.setFunc}
                        additionalCheckField={props.checkField}
                        permission={props.permission}
                        is_reset={props?.filtering?.reset_filters}
                        ordering={ordering}
                        is_update={!user?.roles?.includes(ROLES.management)}
                        setOrdering={setOrdering}
                        setFiltering={setFiltering}
                        colorExpression={props.colorExpression}
                    />
                </div>
            </Card.Body>
            <Card.Footer>
                <ReactPagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={props.data ? props.data.count : null}
                    pageSize={page_limit}
                    onPageChange={page => setCurrentPage(page)}
                />
            </Card.Footer>
        </Card>
    </React.Fragment>
}

export default ReactFilterTable;
