import {ROLES} from "../../../rbac/constant";
import {Input, InputDate, Select} from "../../../components/form/forms";
import * as Yup from 'yup';
import {setRequiredField} from "../../../utils/utils";

export const STATUS_DRAFT = -1;             // Черновик
export const STATUS_NEW = 1;                // Новая сущность в БД
export const STATUS_APPROVE = 2;            // Рассматривается Дирекцией
export const STATUS_ACTS = 3;               // Запрос актов
export const STATUS_CHECK_ACTS = 4;         // Проверка актов
export const STATUS_ACCEPT = 5;             // Принято
export const STATUS_TEMP = 7;

export const STATUS_LABELS = {
    [STATUS_NEW]: 'Новый',
    [STATUS_APPROVE]: 'Рассматривается',
    [STATUS_ACTS]: 'Запрос актов',
    [STATUS_CHECK_ACTS]: 'Проверка актов',
    [STATUS_ACCEPT]: 'Принято',
    // [STATUS_ACCOUNTING]: 'Ведётся учёт по расходам',
}

export const STATUS_OPTIONS = [
    {value: STATUS_NEW, label: 'Новый'},
    {value: STATUS_APPROVE, label: 'Рассматривается'},
    {value: STATUS_ACTS, label: 'Запрос актов'},
    {value: STATUS_CHECK_ACTS, label: 'Проверка актов'},
    {value: STATUS_ACCEPT, label: 'Принято'},
    // {value: STATUS_ACCOUNTING, label: 'Ведётся учёт по расходам'},
]

export const STATUS_BADGE_CLASS = {
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_ACTS]: 'badge-warning',
    [STATUS_CHECK_ACTS]: 'badge-info',
    [STATUS_ACCEPT]: 'badge-success',
    // [STATUS_ACCOUNTING]: 'badge-success',
}

export const FINANCING_DOCUMENT_TYPES = [
    {value: 1, label: 'Закупка'},
    {value: 2, label: 'Услуги'},
    {value: 3, label: 'Пожертвование'},
]


export const LABELS = {
    "id": "ID",
    "status": "Статус",
    "project": "Проект",
    "account": "Лицевой счет",
    "event": "Мероприятие",
    "person": "Ответственный исполнитель",
    "customer": "Заказчик (Контрагент)",
    "contract_number": "Номер договора",
    "contract_date": "Дата договора",
    "work_date_start": "Дата начала работ/услуг по договору",
    "work_date_end": "Дата окончания работ/услуг по договору",
    "contract_type": "Вид договора",
    "contract_subject": "Предмет договора",
    "total_cost": "Общая стоимость договора",
    "created_by": "Создано",
    "updated_by": "Обновлено",
    "created_at": "Дата создания",
    "updated_at": "Дата обновления",
    "direction_by": "Дирекция",
    "message": "Комментарий",
    "messages": "Сообщения",
    "is_checked": "Проверено",
    "is_contract": 'Файл договора',
    "is_notes": 'Файл служебной записки',
    "act_number": '№ акта',
    "date_act": 'Дата акта',
    "actual_income": 'Фактический приход денежных средств по акту',
};

export const FIELDS = {
    id: {
        type: Input,
        content: 'number'
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short
    },
    account: {
        type: Input,
        source: 'text'
    },
    event: {
        type: Select,
        source: 'event',
        key: (v) => `(${v?.title_short}) ${v?.title}`
    },
    person: {
        type: Select,
        source: 'person',
        key: (v) => `${v?.fio}`
    },
    customer: {
        type: Input,
        content: 'text'
    },
    contract_number: {
        type: Input,
        content: 'text'
    },
    contract_date: {
        type: InputDate,
        content: 'date'
    },
    work_date_start: {
        type: InputDate,
        content: 'date'
    },
    work_date_end: {
        type: InputDate,
        content: 'date'
    },
    contract_type: {
        type: Select,
        options: FINANCING_DOCUMENT_TYPES
    },
    contract_subject: {
        type: Input,
        content: 'text'
    },
    total_cost: {
        type: Input,
        content: 'text'
    },
    direction_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`
    },
    created_at: {
        type: InputDate,
        content: 'date'
    },
    updated_at: {
        type: InputDate,
        content: 'date'
    },
};

export const REQUIRED_FIELDS = {
    [STATUS_NEW]: [
        'project', 'customer', 'contract_date', 'work_date_start', 'work_date_end', 'person', 'event',
        'account',
        'contract_number',
        'total_cost',
    ],
    [STATUS_APPROVE]: [
        'project', 'customer', 'contract_date', 'work_date_start', 'work_date_end', 'person', 'event',
        'account',
        'contract_number',
        'total_cost',
    ],
    [STATUS_ACTS]: [
        'project', 'customer', 'contract_date', 'work_date_start', 'work_date_end', 'person', 'event',
        'account',
        'contract_number',
        'total_cost',
    ],
    [STATUS_CHECK_ACTS]: [
        'project', 'customer', 'contract_date', 'work_date_start', 'work_date_end', 'person', 'event',
        'account',
        'contract_number',
        'total_cost',
        'act_number', 'date_act', 'actual_income',
    ],
    [STATUS_ACCEPT]: [
        'project', 'customer', 'contract_date', 'work_date_start', 'work_date_end', 'person', 'event',
        'account',
        'contract_number',
        'total_cost',
        'act_number', 'date_act', 'actual_income',
    ],
    // [STATUS_ACCOUNTING]: [
    //     'project', 'customer', 'contract_date', 'work_date_start', 'work_date_end', 'person'
    // ],
}

export const CAN_MANAGE_ROLES = {
    [STATUS_NEW]: [ROLES.admin, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction, ROLES.direction_financing],
    [STATUS_ACTS]: [ROLES.admin, ROLES.project_manager, ROLES.direction, ROLES.direction_financing],
    [STATUS_CHECK_ACTS]: [ROLES.admin, ROLES.direction, ROLES.direction_financing],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction, ROLES.direction_financing],
}

const contractStages = (field) => {
    return field?.when(['current_status'], (status, schema) => {
        return Yup?.array().of(Yup.object({
            cost: Yup.number().required('Обязательное поле!')
                .typeError('Введите число!')
                .min(0, 'Минимально возможная стоимость 0!'),
            date_start: Yup.string()
                .required('Обязательное поле!')
                .nullable()
                .default(undefined),
            date_end: Yup.string()
                .required('Обязательное поле!')
                .nullable()
                .default(undefined),
            is_checked: Yup.bool().test('conditional-is-checked', `Необходимо проверить данные в полях «Акты и платежные поручения»`,
                function (val, context) {
                    if (status[0] >= STATUS_CHECK_ACTS)
                        return val && context.parent.act_number && context.parent.date_act && context.parent.actual_income;
                    else
                        return true
                }),
            act_number: Yup.string().nullable().test('conditional-act_number', `Заполните обязательные поля!`,
                function (val, context) {
                    return context.parent.is_checked
                    && !val ? context.createError({message: "Обязательное поле!"}) : true
                }),
            date_act: Yup.string()
                .nullable()
                .default(undefined).test('conditional-date_act', `Заполните обязательные поля!`,
                    function (val, context) {
                        return context.parent.is_checked
                        && !val ? context.createError({message: "Обязательное поле!"}) : true
                    }),
            actual_income: Yup.number()
                .typeError('Введите число!')
                .min(0, 'Минимально возможный приход 0!').test('conditional-actual_income', `Заполните обязательные поля!`,
                    function (val, context) {
                        return context.parent.is_checked
                        && !val ? context.createError({message: "Обязательное поле!"}) : true
                    }),
        }).typeError('Минимально возможное количeство этапов - 1!'))
            .typeError('Минимально возможное количeство этапов - 1!')
            .min(1, 'Минимально возможное количeство этапов - 1!')
    })
}

const Schema = Yup.object().shape({
    status: Yup.number().test('conditional-status', ``,
        function (val, context) {
            return !(val === STATUS_ACCEPT && context.parent?.contract_stages?.some(v => !v?.is_checked));
        }),
    message: Yup.string().when(['status', 'current_status'], (status, schema) => {
        return (status[0] < status[1]) && status[0] !== STATUS_ACTS ? schema.required('Обязательное поле!') : schema
    }),
    project_id: Yup.number()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!'),
    event_id: Yup.number()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!'),
    customer: Yup.string()
        .required('Обязательное поле!'),
    account: Yup.string()
        .required('Обязательное поле!'),
    contract_number: Yup.string()
        .required('Обязательное поле!').nullable(),
    contract_date: Yup.date()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!')
        .when(['work_date_start'],
            (work_date_start, schema) => {
                return work_date_start[0] ?
                    schema.max(work_date_start[0], 'Дата договора не может быть больше даты начала работ') : schema
            })
        .nullable()
        .default(undefined)
        .test('test-on-null', 'Обязательное поле!', (value, context) => {
            return value
        }),
    work_date_start: Yup.date()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!')
        .when(['work_date_end'],
            (work_date_end, schema) => {
                return work_date_end[0] ?
                    schema.max(work_date_end[0], 'Дата начала работ не может быть больше даты окончания работ') : schema
            })
        .nullable()
        .default(undefined)
        .test('test-on-null', 'Обязательное поле!', (value, context) => {
            return value
        }),
    work_date_end: Yup.date()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!')
        .nullable()
        .default(undefined)
        .test('test-on-null', 'Обязательное поле!', (value, context) => {
            return value
        }),
    person_id: Yup.number()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!'),
    contract_stages: contractStages(Yup.array()),
    total_cost: Yup.number()
        .required('Обязательное поле!')
        .typeError('Сумма по всем этапам должна совпадать!')
        .when(['contract_stages', 'status'],
            (fields, schema) => {
                if (fields?.[0]) {
                    let result = fields[0].reduce((a, curr) => a + curr.cost, 0);
                    return schema
                        .min(result, 'Сумма по всем этапам должна совпадать!')
                        .max(result, 'Сумма по всем этапам должна совпадать!')
                } else return schema
            }),
    is_contract: Yup.boolean().test('conditional-is-contract', `Загрузите файл договора!`,
        function (val, context) {
            return !val;
        }).nullable(),
    is_notes: Yup.boolean().test('conditional-is-notes', 'Загрузите файл служебной записки!',
        function (val, context) {
            return !val;
        }).nullable()
});

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_NEW]: Schema,
    [STATUS_APPROVE]: Schema,
    [STATUS_ACTS]: Schema,
    [STATUS_CHECK_ACTS]: Schema,
    [STATUS_ACCEPT]: Schema,
}
