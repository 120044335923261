import * as React from "react";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import ReactFilterTable from "../../../components/tables/react_table_filters";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PERMISSIONS, ROLES} from "../../../rbac/constant";
import {Button} from "react-bootstrap";
import {IconText} from "../../../components/icon_txt";
import {AuthContext} from "../../../auth";
import {FIELDS, LABELS, STATUS_BADGE_CLASS, STATUS_LABELS} from "./constant";

import {useAbac} from "react-abac";
import {STATUS_NEW} from "../publication/constant";


const Amortization = () => {
    const navigate = useNavigate();
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();


    const [amortization, setAmortization] = useState(null);
    const [filtering, setFiltering] = useState(null);
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'event', 'project', 'full_sum'],
        'Скрытые / Фиксированные колонки': []
    });

    const checkField = (field, key) => {
        if (key === 'status') {
            if (key === 'status') {
                return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
            }
        }
        if (key === 'project' && field) {
            return <span>{field.title_short}</span>
        }
        if (key === 'event' && field) {
            return <span>{field.title} ({field.title_short})</span>
        }

        return null;
    }

    const addNewAmortization = (state) => {
        navigate(`/amortization/create`);
    }

    useEffect(() => {
        setFiltering({})
    }, [user])

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={"page-header"}>
                Амортизация
            </h1>
            {(userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                || userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL))
            && !user?.roles?.includes(ROLES.management) ?
                <React.Fragment>
                    <div className={'button-actions'}>
                        <Button variant={'success'} className={'mb-3'} onClick={() => {
                            addNewAmortization()
                        }}>
                            <IconText icon={'plus'} text={'Создать'}/>
                        </Button>
                    </div>
                </React.Fragment> : null}
            <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                              filtering={filtering}
                              data={amortization} setFunc={setAmortization} page={1} url={'/amortization'}
                              url_update={'/amortization/item'}
                              get_title={'amortization'}
                              checkField={checkField}
                              permission={PERMISSIONS.MANAGE_FINANCING}
                              fields={FIELDS}
            />
        </React.Fragment>
    );
}

export default Amortization;
