import * as React from "react";
import {useEffect, useState} from "react";
import {
    Button, Card, Col, Row,
} from "react-bootstrap";
import {getRequest, postRequest} from "../../actions/common";
import ReactBreadcrumb from "../../components/breadcrumbs";
import {IconText} from "../../components/icon_txt";
import ReactTable from "../../components/tables/react_table";
import ReactPagination from "../../components/pagination/pagination";
import {Input} from "../../components/form/forms";
import {Field, FormikProvider, useFormik} from "formik";
import {FormikSelect} from "../../components/form/formik";
import {useAbac} from "react-abac";
import {PERMISSIONS} from "../../rbac/constant";


const DashboardReport = () => {

    const init_values = {
        title: '',
        report_year: ''
    }

    const formik = useFormik({
        initialValues: init_values,
        onSubmit: values => {
            onSubmitForm()
        },
        enableReinitialize: true
    })
    const labels = {
        id: 'ID',
        title: 'Заголовок',
        report_year: 'Год',
        created_at: 'Время создания',
        updated_at: 'Время обновления'
    };
    const {userHasPermissions} = useAbac();

    const [ordering, setOrdering] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState(null);
    const [select_data, setSelectData] = useState({});
    const [generate_report, setGenerateReport] = useState({});

    useEffect(() => {
        document.title = 'Данные дашборда | ИС «НЦМУ»';
    });

    useEffect(() => {
        if (Object.keys(select_data).length === 0) {
            getRequest(`dashboard/report_year`, setSelectData, {}, '', select_data, 'report_year');
        }
    }, [select_data]);

    useEffect(() => {
        getRequest('dashboard_report', setData, {...formik.values, page: currentPage, ordering: ordering});
    }, [ordering, currentPage])

    useEffect(() => {
        if (!generate_report) {
            postRequest('generate_report', setGenerateReport({}));
        } else {
            setTimeout(() => {
                getRequest('dashboard_report', setData, {...formik.values, page: currentPage, ordering: ordering});
            }, 2200)
        }
    }, [generate_report]);

    const btnFilter = () => {
        let card = document.getElementById('card-filter');
        if (card && card.style.display === 'none') {
            card.style.display = 'block';
        } else if (card && card.style.display === 'block') {
            card.style.display = 'none';
        }
    }

    const handleGenerateReport = (e) => {
        e.preventDefault();
        postRequest('generate_report', setGenerateReport({}), {}).then()
    }
    const refreshFilter = () => {
        formik.setValues(init_values);
        getRequest('dashboard_report', setData, {page: currentPage, ordering: ordering});
    }

    const onSubmitForm = () => {
        if (formik.isValid) {
            setCurrentPage(1);
            getRequest('dashboard_report', setData, {...formik.values, ordering: ordering});
        }
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <FormikProvider value={formik}>
                <h1 className={"page-header"}>
                    Данные дашборда
                </h1>

                <div className="button-actions mb-3">
                    <Button variant="secondary" onClick={btnFilter}>
                        <IconText text={'Форма поиска'} icon={'search'}/>
                    </Button>
                    {userHasPermissions(PERMISSIONS.ADMIN_PANEL) ?
                        <Button variant="warning" onClick={handleGenerateReport}>
                            Зафиксировать данные
                        </Button>
                        : null}
                </div>

                <Card style={{display: 'none'}} id='card-filter'>
                    <form onSubmit={onSubmitForm}>
                        <Card.Body>
                            <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3'>
                                <Col>
                                    <Input id={'title'} name={'title'}
                                           label={'Заголовок'}
                                           handleChangeValue={formik.handleChange}
                                           value={formik.values.title}
                                    />
                                </Col>
                                <Col>
                                    <Field component={FormikSelect}
                                           className={'ms-2'}
                                           id={'report_year'} name={'report_year'}
                                           label={'Год'}
                                           options={select_data?.report_year ? select_data?.report_year.map(v => {
                                               return {value: v, label: v}
                                           }) : null}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <div className={'button-actions'}>
                                <Button onClick={formik.submitForm}>Найти</Button>
                                <Button variant='secondary' onClick={() => refreshFilter()}>Сбросить</Button>
                            </div>
                        </Card.Footer>
                    </form>
                </Card>

                <ReactTable data={data ? data : null} labels={labels} pageSize={25}
                            bordered={true} striped={true} hover={true}
                            sort={true}
                            page={1}
                            get_title={'dashboard_report'}
                            setFunc={setData}
                            url_update={''}
                            url_field={'/dashboard-report/item'}
                            is_update={false}
                            ordering={ordering}
                            setOrdering={setOrdering}
                />
                <ReactPagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={data ? data.count : null}
                    pageSize={25}
                    onPageChange={page => setCurrentPage(page)}
                />
            </FormikProvider>
        </React.Fragment>
    );
}

export default DashboardReport;
