import ReactBreadcrumb from "../../../components/breadcrumbs";
import * as React from "react";
import {Check, Input} from "../../../components/form/forms";
import {useEffect, useState} from "react";
import {getRequest, updateRequest} from "../../../actions/common";
import {Button} from "react-bootstrap";
import {getValueByKey, setChangedValue} from "../../../utils/utils";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";

export const IndicatorSettings = () => {
    const navigate = useNavigate();
    const [settings, setSettings] = useState(null);
    const formik = useFormik({
        initialValues: {
            report_year: null,
            auto_calculate: false,
        }
    })

    useEffect(() => {
        document.title = 'Настройки показателей | ИС «НЦМУ»';
    })
    useEffect(() => {
        if (!settings) {
            getRequest('settings', setSettings, {type_data: 'all'});
        } else {
            formik.setFieldValue('report_year', getValueByKey(settings, 'report_year', 'value'))
            formik.setFieldValue('auto_calculate', getValueByKey(settings, 'auto_calculate', 'value') === 'True')
        }
    }, [settings])

    const handleSubmitForm = (e) => {
        e.preventDefault();

        for (let key in formik.values) {
            let id = getValueByKey(settings, key, 'id');
            if (id) {
                updateRequest('settings', {common: {value: formik.values?.[key]}}, id).then(r => {
                    navigate('/indicator');
                });
            }
        }
    }

    return <React.Fragment>
        <ReactBreadcrumb/>
        <h1 className={"page-header"}>
            Настройки показателей
        </h1>
        <form onSubmit={handleSubmitForm}>
            <Input label={'Отчетный год'}
                   type={'number'}
                   id={'report_year'}
                   name={'report_year'}
                   value={formik.values.report_year}
                   handleChangeValue={formik.handleChange}
            />
            <Check label={'Автоматический расчет'}
                   id={'auto_calculate'}
                   name={'auto_calculate'}
                   value={formik.values.auto_calculate}
                   handleChangeValue={formik.handleChange}
            />
            <Button type={'submit'} variant={'success'}>Сохранить</Button>
        </form>
    </React.Fragment>
}
