import * as React from "react";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import ReactFilterTable from "../../../components/tables/react_table_filters";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PERMISSIONS, ROLES} from "../../../rbac/constant";
import {Badge, Button} from "react-bootstrap";
import {IconText} from "../../../components/icon_txt";
import {AuthContext} from "../../../auth";
import {FIELDS, LABELS, RID_DOCUMENT_TYPES, RID_STATUS, RID_TYPES, STATUS_BADGE_CLASS, STATUS_LABELS} from "./constant";
import {formatDate, getLabelFromOptions, Messages} from "../../../utils/utils";
import {putRequest} from "../../../actions/common";
import {STATUS_ACCEPT, STATUS_NEW} from "./constant";
import {useAbac} from "react-abac";


const Rid = () => {
    const navigate = useNavigate();
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();

    const [rid, setRid] = useState(null);
    const [filtering, setFiltering] = useState(null);
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'report_year', 'title', 'status', 'type', 'application_status', 'date', 'application_date', 'document_type'],
        'Скрытые / Фиксированные колонки': ["number", "project", "event", "patent_office", "organization", "messages",
            'updated_by',
            "created_at",
            "updated_at",
            "created_by",
            "direction_rid_by",
            "direction_by",
            "authors",
            "other_authors"
        ]
    });

    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({status_not: STATUS_NEW})
        } else {
            setFiltering({})
        }
    }, [user])

    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        }
        if (['country', 'patent_office'].includes(key) && field) {
            return <span>{field.title}</span>
        }
        if (['date', 'application_date'].includes(key) && field) {
            return formatDate(field);
        }
        if (key === 'event') {
            return field?.title_short ? `(${field?.title_short}) ${field?.title}` : field?.title
        }
        if (['project', 'job_contract', 'organization'].includes(key) && field) {
            return <span>{field.title_short}</span>
        }
        if (key === 'type') {
            return <span>{getLabelFromOptions(RID_TYPES, field)}</span>
        }
        if (key === 'document_type') {
            return <span>{getLabelFromOptions(RID_DOCUMENT_TYPES, field)}</span>
        }
        if (key === 'application_status') {
            return <span>{getLabelFromOptions(RID_STATUS, field)}</span>
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'rid'}/>
        }
        if (key === 'title') {
            return <span>
                {field?.ru ? <span>
                    <Badge bg={'secondary'} style={{color: 'white'}}>
                        Ru:
                    </Badge>
                    <span>{field?.ru}</span>
                </span> : null}
                <br/>
                {field?.en ? <span>
                <Badge bg={'secondary'} style={{color: 'white'}}>
                    En:
                    </Badge>
                    <span>{field?.en}</span>
                </span> : null}
            </span>
        }
        if (key === 'authors' && field) {
            let elements = [];
            for (let index in field) {
                elements.push(`${field[index].fio}   `)
            }
            return elements
        }

        return null;
    }

    const addNewRid = (state) => {
        putRequest('rid', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW, project: user?.project?.id,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/rid/item/${response.data.id}/?update=true`);
            }
        })
    }

    return (<React.Fragment>
        <ReactBreadcrumb/>
        <h1 className={"page-header"}>
            РИДы
        </h1>
        {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction_rid) || user?.roles?.includes(ROLES.project_manager)
        || user?.roles?.includes(ROLES.direction) ?
            <React.Fragment>
                <div className={'button-actions'}>
                    {!user?.roles?.includes(ROLES.direction) ?
                        <Button variant={'success'} className={'mb-3'} onClick={() => {
                            addNewRid()
                        }}>
                            <IconText icon={'plus'} text={'Создать'}/>
                        </Button> : null}
                    {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction_rid)
                    || user?.roles?.includes(ROLES.direction) ?
                        <Button variant={'secondary'} className={'mb-3'} onClick={() => {
                            addNewRid({monitoring: true})
                        }}>
                            <IconText icon={'plus'} text={'Добавить в мониторинг'}/>
                        </Button> : null}
                </div>
            </React.Fragment> : null}
        <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                          filtering={filtering}
                          data={rid} setFunc={setRid} page={1} url={'/rid'}
                          url_update={'/rid/item'}
                          get_title={'rid'}
                          checkField={checkField}
                          permission={PERMISSIONS.MANAGE_RID}
                          fields={FIELDS}
        />
    </React.Fragment>);
}

export default Rid;
