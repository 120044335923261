import * as React from 'react';
import {AbstractImport} from "./abstract_import";

export const StaffImport = () => {
    const fields = {
        "fio": 'ФИО',
        "project_manager": "Менеджер проекта",
        "staff_staff_position": "Должность",
        "staff_staff_category": "Возможные категории по методическим указаниям",
        "employee_id": "Табельный номер",
        "birth_date": "Дата рождения",
        "acc_scopus": "Scopus ID",
        "acc_researcher": "Researcher ID",
        "contract_number": "Номер договора",
        "contract_date": "Дата договора",
        "additional_info": "Дополнительная информация",
        "participant_info": "Отчет о реализации программы создания и развития центра",
        "personal_account": "Лицевой счет",
        "job_contract": "Форма привлечения работника",
        "country": "Гражданство",
        "department": "Подразделение",
        "report_category": "Категория в отчетность",
        "project": "Проект",
        "event": "Мероприятие",
        "self_academic_degree": "Ученая степень",
        "h_index": "Индекс Хирша",
        "work_group_order": "Приказ о включении в рабочую группу",
        "snils": "СНИЛС",
        "inn": "ИНН",
    };

    return <AbstractImport title={'Импорт в БД Кадры'}
                           fields={fields}
                           post_title={'staff_import'}
    />
}
