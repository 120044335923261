import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useContext, useEffect, useRef, useState} from "react";
import {getRequest, putRequest, updateRequest} from "../../../actions/common";
import {
    LABELS,
    STATUS_DRAFT,
    STATUS_NEW,
} from "./constant";
import {Input} from "../../../components/form/forms";
import {PERMISSIONS} from "../../../rbac/constant";
import {useAbac} from "react-abac";
import {findInSelectData} from "../../../utils/utils";
import {AuthContext} from "../../../auth";
import {Field, FieldArray, FormikProvider, useFormik} from "formik";
import {REQUIRED_FIELDS_TEMP} from "./constant";
import {FormikDate, FormikSelect} from "../../../components/form/formik";


export const AmortizationCreate = () => {
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();
    const navigate = useNavigate();
    const project_ref = useRef();

    const [select_data, setSelectData] = useState({});
    const select_fields = ['project', 'event'];

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'full_sum': 0,
        'quarters': [{}, {}, {}, {}].fill({
            date: undefined,
            number: null,
            sum: null,
        }, 0, 3)
    });

    const formik = useFormik({
        validationSchema: REQUIRED_FIELDS_TEMP[STATUS_DRAFT],
        initialValues: input_fields,
        onSubmit: values => {
            saveAmortization()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        if (!userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
            && !userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL)) {
            navigate('/access-error');
        } else {
            document.title = `Создание записи в БД Aмортизация | ИС «НЦМУ»`;
            if (user?.project?.id) {
                formik.setFieldValue('project', user?.project?.id)
            }
        }
    });

    useEffect(() => {
        if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
            for (let field of select_fields) {
                if (!Object.keys(select_data).includes(field) && field !== 'event') {
                    getRequest(field, setSelectData, {type_data: 'all'}, '', select_data, field);
                    break
                }
                if (!select_data?.event?.length && field === 'event' && formik.values?.project) {
                    getRequest('event', setSelectData, {
                        type_data: 'all',
                        project: formik.values?.project
                    }, '', select_data, 'event').then(r => {
                        if (formik.values?.event_id && !findInSelectData(r.data, 'id', formik.values?.event_id)) {
                            setInputFields({...formik.values, event_id: null})
                        }
                    });
                    break
                }
            }
        }
    }, [select_data, formik.values]);

    useEffect(() => {
        if (formik.values?.project !== project_ref.current) {
            formik.setFieldValue('event_id', null);
            setSelectData({...select_data, event: []})
        }
        project_ref.current = formik.values?.project;
    }, [formik.values])

    const generateOptions = (field, key) => {
        if (select_data && Object.keys(select_data).includes(field)) {
            let options = [];
            for (let element of select_data[field]) {
                let label = null;
                if (typeof key === 'string') {
                    label = element[key]
                } else {
                    label = key(element);
                }
                options.push({value: element.id, label: label});
            }
            return options;
        }
        return null;
    }

    const saveAmortization = () => {
        if (formik?.isValid) {
            formik.setFieldValue('save_form', false);
            putRequest('amortization', {...formik.values, status: STATUS_NEW}).then(response => {
                if (response.status === 201) {
                    navigate(`/amortization/item/${response.data.id}?update=true`)
                }
            })
        }
    }

    const ErrorsBlock = ({form}) => {

        const generateErrorDict = (err) => {
            let temp = [];
            if (err)
                for (const [key, value] of Object?.entries(err)) {
                    if (value !== '' && value) {
                        if (value instanceof Array) {
                            generateErrorList(value);
                        } else if (key !== 'message') {
                            temp.push(<li key={key.replace('_id', '')}>
                                {LABELS[key.replace('_id', '')]
                                    ? `Необходимо заполнить: "${LABELS[key.replace('_id', '')]}"`
                                    : null}
                            </li>)
                        }
                    }
                }
            return temp
        }

        const generateErrorList = (err) => {
            let temp = [];
            for (const value of err) {
                temp.concat(generateErrorDict(value))
            }
            return temp
        }

        if (form.errors) {
            let temp = generateErrorDict(form.errors)
            return <div className="alert alert-danger" style={{display: temp.length > 0 ? "block" : "none"}}>
                <p>Исправьте следующие ошибки:</p>
                <ul>
                    {generateErrorDict(form.errors)}
                </ul>
            </div>
        } else return null
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <FormikProvider value={formik}>
                <Form>
                    <div className="section-header sticky-top">
                        <h1 className="page-header">
                            {`Создание записи в БД Амортизация`}
                        </h1>
                        <div className="button-actions">
                            <Button variant='success' onClick={() => formik.submitForm()}>Создать</Button>
                        </div>
                    </div>

                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className="text-danger">красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className="text-success">зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className="badge badge-success">Сохранить</span> выполняет сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className="badge badge-success">Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <legend>Данные аммортизации</legend>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'project'} label={'Проект'}
                                               id={'project'}
                                               required={true}
                                               isSearchable={true}
                                               isClearable={true}
                                               options={generateOptions('project', 'title_short')}
                                               error={formik?.errors['project']}
                                               invalid={formik?.errors['project']}
                                               disabled={user ? user?.project?.id : disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Направление'}
                                               id={'direction'}
                                               value={user?.project?.direction}
                                               disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'event'} label={'Мероприятие'}
                                               id={'event'}
                                               required={true}
                                               isClearable={true}
                                               isSearchable={true}
                                               options={generateOptions('event', (element) => {
                                                   if (element?.title_short)
                                                       return `(${element.title_short}) ${element.title}`
                                                   else
                                                       return `${element.title}`
                                               }, select_data)}
                                               error={formik?.errors['event']}
                                               invalid={formik?.errors['event']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input label={'Общая сумма по всем кварталам'}
                                               type={'number'}
                                               small={'Высчитывается автоматически'}
                                               id={'full_sum'}
                                               name={'full_sum'}
                                               value={formik.values?.full_sum}
                                               disabled={true}
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                            <FieldArray name={'quarters'} render={arrayHelpers => (<div>
                                {arrayHelpers.form.values?.quarters?.map((stage, index) => (
                                    <fieldset>
                                        <legend>Квартал №{index + 1}</legend>
                                        <Row>
                                            <Col md={6}>
                                                <Input label={'Номер справки'}
                                                       id={`quarters.${index}.number`}
                                                       name={`quarters.${index}.number`}
                                                       handleChangeValue={formik.handleChange}
                                                       error={formik?.errors?.quarters?.[index]?.['number']}
                                                       invalid={formik?.errors?.quarters?.[index]?.['number']}
                                                       value={stage?.number}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Field component={FormikDate}
                                                       label={'Дата справки'}
                                                       id={`quarters.${index}.date`}
                                                       name={`quarters.${index}.date`}
                                                       type={'date'}
                                                       handleChangeValue={formik.handleChange}
                                                       error={formik?.errors?.quarters?.[index]?.['date']}
                                                       invalid={formik?.errors?.quarters?.[index]?.['date']}
                                                       value={stage?.date}
                                                />
                                            </Col>
                                        </Row>
                                        <Input handleChangeValue={formik.handleChange}
                                               id={`quarters.${index}.sum`}
                                               name={`quarters.${index}.sum`}
                                               label={'Сумма'}
                                               type={'number'}
                                               error={formik?.errors?.quarters?.[index]?.['sum']}
                                               invalid={formik?.errors?.quarters?.[index]?.['sum']}
                                               value={stage?.sum}
                                        />
                                        <hr/>
                                    </fieldset>
                                ))}
                            </div>)}/>
                            <fieldset>
                                <legend>Прикрепленные файлы</legend>
                                <hr/>
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    );
}
