import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {
    Button, Card, Col, Form, Row,
} from "react-bootstrap";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {IconText} from "../../../components/icon_txt";
import ReactTable from "../../../components/tables/react_table";
import {Link} from "react-router-dom";
import ReactPagination from "../../../components/pagination/pagination";
import {downloadRequest, getRequest} from "../../../actions/common";
import {SelectReact} from "../../../components/form/select";
import {Input} from "../../../components/form/forms";
import {DownloadFile} from "../../../components/file_manager/file_handlers";
import {PERMISSIONS, ROLES} from "../../../rbac/constant";
import {useAbac} from "react-abac";
import {AuthContext} from "../../../auth";

const Report = ({is_create = true,}) => {
    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'type': 'Тип',
        'start_date': 'Начало периода',
        'end_date': 'Окончание периода',
        'file': 'Файл отчета',
        'created_at': 'Время создания',
        'updated_at': 'Время обновления'
    };
    const fields = [
        'title',
        'type',
        'created_at',
    ];
    const select_fields = [];
    const [currentPage, setCurrentPage] = useState(1);
    const [ordering, setOrdering] = useState(null);
    const [data, setData] = useState(null);
    const [filters_value, setFiltersValue] = useState({});
    const [select_data, setSelectData] = useState({});
    const [select_values, setSelectValues] = useState({});

    useEffect(() => {
        document.title = 'Отчеты | ИС «НЦМУ»';
        if (fields) {
            fields.forEach((field) => {
                document.getElementById(field).value = filters_value[field] !== undefined ? filters_value[field] : null;
            })
        }
    });


    useEffect(() => {
        if (Object.keys(select_data).length === 0 && select_fields !== null) {
            for (let field in select_fields) {
                getRequest(select_fields[field], setSelectData, {}, '', select_data, select_fields[field]);
            }
        }
    }, [select_data]);

    useEffect(() => {
        getRequest('report', setData, {page: currentPage, ordering: ordering});
    }, [ordering, currentPage])

    const btnFilter = () => {
        let card = document.getElementById('card-filter');
        if (card && card.style.display === 'none') {
            card.style.display = 'block';
        } else if (card && card.style.display === 'block') {
            card.style.display = 'none';
        }
    }

    const refreshFilter = () => {
        if (fields) {
            fields.forEach((field) => {
                document.getElementById(field).value = '';
            })
        }
        setFiltersValue({});
        getRequest('report', setData);
    }

    const onSubmitForm = (e) => {
        e.preventDefault();
        let data = {};
        const inputs = Array.from(e.target);
        inputs.forEach(({id, value}) => {
            if (value) {
                data[id] = value;
            }
        });
        for (let field in select_fields) {
            data[`${select_fields[field]}`] = select_values[select_fields[field]];
        }
        setFiltersValue(data);
        getRequest('report', setData, data);
    }

    const FilterInputs = () => {
        if (fields) {
            let elements = [];
            fields.forEach((field) => {
                elements.push(
                    <Col key={field}>
                        <Input label={labels[field]} id={field}/>
                    </Col>
                )
            })
            return elements;
        }
        return null;
    }

    const FilterSelects = () => {
        if (select_fields && Object.keys(select_data).length !== 0) {
            let elements = [];
            select_fields.forEach((field) => {
                let options = [];
                for (let i = 0; i < select_data[field].results.length; i++) {
                    if (select_data[field].results[i]) {
                        options.push({
                            value: select_data[field].results[i].id,
                            label: select_data[field].results[i].title
                        });
                    }
                }
                elements.push(
                    <Col key={field}>
                        <div className='form-group'>
                            <Form.Label>{labels[field]}</Form.Label>
                            <SelectReact options={options} setSelectState={true} select_key={field}
                                         setState={setSelectValues}
                                         select_values={select_values}
                                         value={select_values[field]}
                                         selectID={`${field}_select`}/>
                        </div>
                    </Col>
                )
            })
            return elements;
        }
        return null;
    }

    const additionalCheckField = (field, key) => {
        const type_labels = {
            1: 'Ручной',
            2: 'Квартальный',
            3: 'Ежегодный'
        }
        if (key === 'type') {
            return <span>{type_labels[field]}</span>
        } else if (key === 'file') {
            return <DownloadFile folder={'reports'} file={field} title={'report'}/>
        }
        return null;
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={"page-header"}>
                Отчеты
            </h1>
            {userHasPermissions(PERMISSIONS.MANAGE_COMMON) ?
                <div className="button-actions mb-3">
                    <Button variant="secondary" onClick={btnFilter}>
                        <IconText text={'Форма поиска'} icon={'search'}/>
                    </Button>
                    {is_create ?
                        <Link to={`/indicator/report/create/`}>
                            <Button variant="success">
                                <IconText text={'Сгенерировать отчет'} icon={'plus'}/>
                            </Button>
                        </Link>
                        : null
                    }
                </div> : null
            }

            <Card style={{display: 'none'}} id='card-filter'>
                <form onSubmit={onSubmitForm}>
                    <Card.Body>
                        <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3'>
                            <FilterInputs/>
                            <FilterSelects/>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <div className={'button-actions'}>
                            <Button type='submit'>Найти</Button>
                            <Button variant='secondary' onClick={refreshFilter}>Сбросить</Button>
                        </div>
                    </Card.Footer>
                </form>
            </Card>

            <ReactTable data={data ? data : null} labels={labels} pageSize={25}
                        bordered={true} striped={true} hover={true}
                        sort={true}
                        page={1}
                        get_title={'report'}
                        setFunc={setData}
                        is_update={!user?.roles?.includes(ROLES.management)}
                        is_see_field={false}
                        setOrdering={setOrdering}
                        ordering={ordering}
                        url_update={'/indicator/report/update'}
                        additionalCheckField={additionalCheckField}
            />
            <ReactPagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={data ? data.count : null}
                pageSize={25}
                onPageChange={page => setCurrentPage(page)}
            />
        </React.Fragment>
    );
}

export default Report;
