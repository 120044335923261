import * as React from "react";
import DictCreate from "./common/create";
import {Area, Check, Input, Select} from "../../components/form/forms";
import {DownloadFile, UploadFile} from "../../components/file_manager/file_handlers";
import DictUpdate from "./common/update";
import {useEffect, useState} from "react";
import {getRequest} from "../../actions/common";
import {REPORT_YEAR} from "../database/publication/constant";

export const AcademicDegreeCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'academic_degree'}
            />
        </React.Fragment>
    );
}


export const AcademicRankCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'academic_rank'}
            />
        </React.Fragment>
    );
}


export const CountryCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'country'}
            />
        </React.Fragment>
    );
}

// export const DepartamentCreate = (props) => {
//     const fields = [
//         'id', 'title', 'title_short', 'parent_id', 'is_active'
//     ];
//     const labels = {
//         'id': 'ID',
//         'title': 'Заголовок',
//         'title_short': 'Аббревиатура',
//         'sort_index': 'Порядковый №',
//         'ref_id': 'Внешняя ссылка',
//         'parent_id': 'ID родителя',
//         'is_active': 'Активно?',
//         'data_create': 'Дата открытия',
//         'data_close': 'Дата закрытия'
//     };
//
//     return (
//         <React.Fragment>
//             <DictCreate labels={labels}  fields={fields}
//                   dict_title={'departament'}
//             />
//         </React.Fragment>
//     );
// }

export const DirectionCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'direction'}
            />
        </React.Fragment>
    );
}

export const EventCategoryCreate = (props) => {
    const fields = [
        'title', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'event_category'}
            />
        </React.Fragment>
    );
}

export const EventsCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index',
    ];

    const [event_category, setEventCategory] = useState(null);
    const [projects, setProjects] = useState(null);

    useEffect(() => {
        if (!event_category) {
            getRequest('event_category', setEventCategory, {type_data: 'all'})
        }
    }, [event_category])

    useEffect(() => {
        if (!projects) {
            getRequest('project', setProjects, {type_data: 'all'})
        }
    }, [projects])

    let custom_fields = {
        category_id: Select,
        project_id: Select,
        is_educational: Check
    }


    let select_options = {
        category_id: event_category ? event_category.map(v => {
            return {value: v.id, label: v.title}
        }) : null,
        project_id: projects ? projects.map(v => {
            return {value: v.id, label: v.title}
        }) : null,
    }

    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
        'category_id': 'Категория',
        'project_id': 'Проект',
        'is_educational': 'Образовательное'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'event'}
                        custom_fields={custom_fields}
                        select_options={select_options}
            />
        </React.Fragment>
    );
}

export const JobContractCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'job_contract'}
            />
        </React.Fragment>
    );
}

export const OrganizationCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'organization'}
            />
        </React.Fragment>
    );
}

export const PatentOfficeCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'patent_office'}
            />
        </React.Fragment>
    );
}

export const ProjectCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index', 'direction_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
        'direction_id': 'Направление'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'project'}
            />
        </React.Fragment>
    );
}

export const SelfAcademicDegreeCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'self_academic_degree'}
            />
        </React.Fragment>
    );
}

export const SelfAcademicRankCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'self_academic_rank'}
            />
        </React.Fragment>
    );
}


export const StaffCategoryCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'staff_category'}
            />
        </React.Fragment>
    );
}

export const StaffPositionCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'staff_position'}
            />
        </React.Fragment>
    );
}

export const StaffUnitCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'staff_unit'}
            />
        </React.Fragment>
    );
}

export const UnitCreate = (props) => {
    const fields = [
        'title', 'title_short', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'unit'}
            />
        </React.Fragment>
    );
}

export const PublicationTypeCreate = (props) => {
    const fields = [
        'title', 'ref_id', 'sort_index'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <DictCreate labels={labels} fields={fields}
                        dict_title={'publication_type'}
            />
        </React.Fragment>
    );
}

export const OrderCreate = () => {
    const labels = {
        'number': 'Номер приказа',
        'date': 'Дата приказа',
        'description': 'Описание',
        'category': 'Категория'
    };

    let custom_fields = {
        number: Input,
        date: Input,
        category: Select,
        description: Area,
        new_file: UploadFile
    }

    let select_fields = ['category']

    let custom_props = {
        date: {type: 'date'},
        new_file: {
            small_text: 'Разрешена загрузка файлов только со следующими расширениями: doc, docx, pdf, xls, xlsx.',
            accept: ".xls,.xlsx,.doc,.docx,.pdf"
        }
    }

    let select_options = {
        category: [
            {value: 3, label: 'Не задано'},
            {value: 1, label: 'Зачисление на обучение'},
            {value: 2, label: 'Рабочая группа'}
        ]
    }

    const checkFields = (payload) => {
        if (payload) {
            let errors = {};
            for (let key in payload) {
                if (!payload?.['number']) {
                    errors = ({...errors, number: 'Обязательное поле!'})
                } else {
                    let temp = {...errors};
                    delete temp?.number;
                    errors = temp;
                }
                if (!payload?.['file']) {
                    errors = ({...errors, new_file: 'Обязательное поле!'})
                } else if (payload?.['file']) {
                    if ((payload?.['file']?.size / (1024 * 1024)).toFixed(2) > 24.9) {
                        errors = ({...errors, new_file: 'Файл слишком большой. Размер не должен превышать 25 МБ.'})
                    }
                } else {
                    let temp = {...errors};
                    delete temp?.['new_file'];
                    errors = temp;
                }
            }
            return Object.keys(errors).length ? errors : null;
        }
        return null;
    }

    return (
        <React.Fragment>
            <DictCreate labels={labels}
                        custom_fields={custom_fields}
                        custom_props={custom_props}
                        dict_title={'order'}
                        select_fields={select_fields}
                        select_options={select_options}
                        checkFields={checkFields}
                        header={'title'}
            />
        </React.Fragment>
    );
}

export const TemplateCreate = () => {
    const labels = {
        'title': 'Заголовок',
        'description': 'Описание',
    };

    let custom_fields = {
        title: Input,
        description: Area,
        new_file: UploadFile
    }

    let custom_props = {
        new_file: {
            small_text: 'Разрешена загрузка файлов только со следующими расширениями: docx, xlsx.',
            accept: ".xlsx,.docx"
        }
    }

    const checkFields = (payload) => {
        if (payload) {
            let errors = {};
            for (let key in payload) {
                if (!payload?.['title']) {
                    errors = ({...errors, title: 'Обязательное поле!'})
                } else {
                    let temp = {...errors};
                    delete temp?.title;
                    errors = temp;
                }
                if (!payload?.['file']) {
                    errors = ({...errors, new_file: 'Обязательное поле!'})
                } else if (payload?.['file']) {
                    if ((payload?.['file']?.size / (1024 * 1024)).toFixed(2) > 24.9) {
                        errors = ({...errors, new_file: 'Файл слишком большой. Размер не должен превышать 25 МБ.'})
                    }
                } else {
                    let temp = {...errors};
                    delete temp?.['new_file'];
                    errors = temp;
                }
            }
            return Object.keys(errors).length ? errors : null;
        }
        return null;
    }

    return (
        <React.Fragment>
            <DictCreate custom_fields={custom_fields}
                        labels={labels}
                        custom_props={custom_props}
                        checkFields={checkFields}
                        dict_title={'template'}
                        header={'title'}
            />
        </React.Fragment>
    );
}


export const GrantCreate = () => {

    const [events, setEvents] = useState(null);

    const labels = {
        'event': 'Мероприятие',
        'grant': 'Грант',
        'account': 'Номер лицевого счёта',
        'year': 'Год',
    };

    useEffect(() => {
        if (!events) {
            getRequest('event', setEvents, {type_data: 'all'})
        }
    }, [events])


    let custom_fields = {
        event: Select,
        grant: Input,
        account: Input,
        year: Select,
    }

    let custom_props = {
        grant: {
            type: 'number',
            step: 'any'
        },
    }

    let select_options = {
        event: events ? events.map(v => {
            let label = null;
            if (v.title_short) {
                label = `(${v.title_short}) ${v.title}`
            } else {
                label = v.title
            }
            return {value: v.id, label: label}
        }) : null,
        year: REPORT_YEAR
    }

    return (
        <React.Fragment>
            <DictCreate custom_fields={custom_fields}
                        custom_props={custom_props}
                        select_options={select_options}
                        labels={labels}
                        dict_title={'grant'}
                        header={'year'}
            />
        </React.Fragment>
    );
}



