import {ROLES} from "../../../rbac/constant";
import {isValue} from "../../../utils/utils";
import {Input, InputDate, Select} from "../../../components/form/forms";
import * as Yup from "yup";

export const STATUS_TEMP = 5;               // Черновик
export const STATUS_ACCEPT = 4;             // Принято
export const STATUS_DRAFT = -1;             // Черновик
export const STATUS_NEW = 1;                // Новая сущность в БД
export const STATUS_APPROVE = 2;            // Рассматривается Дирекцией
export const STATUS_PROCESS = 3;            // Обрабатывается Дирекцией

export const STATUS_LABELS = {
    [STATUS_NEW]: 'Новый',
    [STATUS_APPROVE]: 'Рассматривается',
    [STATUS_PROCESS]: 'Обрабатывается',
    [STATUS_ACCEPT]: 'Принято',
}

export const STATUS_OPTIONS = [
    {value: STATUS_NEW, label: 'Новый'},
    {value: STATUS_APPROVE, label: 'Рассматривается'},
    {value: STATUS_PROCESS, label: 'Обрабатывается'},
    {value: STATUS_ACCEPT, label: 'Принято'},
]

export const STATUS_BADGE_CLASS = {
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_PROCESS]: 'badge-primary',
    [STATUS_ACCEPT]: 'badge-success',
}

export const LABELS = {
    "id": "ID",
    "status": "Статус",
    "employee_id": "Табельный номер",
    "employee": "Табельный номер",
    "fio": 'ФИО',
    "first_name": "Имя",
    "last_name": "Фамилия",
    "middle_name": "Отчество",
    "age": 'Возраст',
    "birth_date": "Дата рождения",
    "employment_date": "Дата первого приема в СПбПУ",
    "acc_scopus": "Scopus ID",
    "acc_researcher": "Research ID",
    "contract_number": "Номер договора",
    "contract_date": "Дата договора",
    "additional_info": "Дополнительная информация",
    "participant_info": "Отчет о реализации программы создания и развития центра",
    "personal_account": "Лицевой счет",
    "related_docs_url": "Ссылка на сопутствующие документы",
    "finance_category": "Категория в финансовую отчетность",
    "h_index": "Индекса Хирша",
    "orcid": "ORCID",
    "dismissal_date": "Дата увольнения",
    "work_group_order": "Приказ о включении в рабочую группу",
    "order": "Приказ о включении в рабочую группу",
    "is_supervisor": "Руководитель исследования",
    "snils": "СНИЛС",
    "inn": "ИНН",
    "job_contract": "Форма привлечения работника",
    "academic_degree": "Ученая степень (внешний источник)",
    "academic_rank": "Ученое звание",
    "country": "Гражданство",
    "department": "Подразделение",
    "unit": "Штатная единица (внешний источник)",
    // "report_category": "Категория в отчетность",
    "project": "Проект",
    "staff_staff_position": "Должность(и)",
    "staff_staff_category": "Возможные категории по методическим указаниям",
    "event": "Мероприятие",
    "direction_staff": "Дирекция Кадры",
    "self_academic_degree": "Ученая степень",
    "self_academic_rank": "Ученое звание",
    "staff_category": "Категория в отчетность",
    "direction": "Дирекция",
    'updated_by': 'Обновлено пользователем',
    "created_at": "Дата создания",
    "updated_at": "Дата обновления",
    "created_by": "Создано пользователем",
    "messages": "Сообщения",
    "message": "Комментарий",
    "ncmuspecialist": "В специалистах",
};

export const FIELDS = {
    id: {
        type: Input,
        content: 'number'
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS
    },
    employee_id: {
        type: Input,
        source: 'text'
    },
    fio: {
        type: Input,
        content: 'text'
    },
    first_name: {
        type: Input,
        content: 'text'
    },
    last_name: {
        type: Input,
        content: 'text'
    },
    middle_name: {
        type: Input,
        content: 'text'
    },
    age: {
        type: Input,
        content: 'number'
    },
    birth_date: {
        type: InputDate,
        content: 'date'
    },
    employment_date: {
        type: InputDate,
        content: 'date'
    },
    acc_scopus: {
        type: Input,
        content: 'text'
    },
    acc_researcher: {
        type: Input,
        content: 'text'
    },
    contract_number: {
        type: Input,
        content: 'number'
    },
    contract_date: {
        type: InputDate,
        content: 'date'
    },
    additional_info: {
        type: Input,
        content: 'text'
    },
    participant_info: {
        type: Input,
        content: 'text'
    },
    personal_account: {
        type: Input,
        content: 'text'
    },
    finance_category: {
        type: Input,
        content: 'text'
    },
    h_index: {
        type: Input,
        content: 'text'
    },
    orcid: {
        type: Input,
        content: 'text'
    },
    dismissal_date: {
        type: InputDate,
        content: 'date'
    },
    work_group_order: {
        type: Input,
        content: 'text'
    },
    is_supervisor: {
        type: Select,
        options: [{value: 'true', label: 'Да'}, {value: 'false', label: 'Нет'}]
    },
    snils: {
        type: Input,
        content: 'text',
        props: {
            mask: '999-999-999 99'
        }
    },
    inn: {
        type: Input,
        content: 'text',
        props: {
            mask: '9999-999999-99'
        }
    },
    job_contract: {
        type: Select,
        source: 'job_contract'
    },
    order: {
        type: Select,
        source: 'order'
    },
    academic_degree: {
        type: Select,
        source: 'academic_degree'
    },
    academic_rank: {
        type: Select,
        source: 'academic_rank'
    },
    country: {
        type: Select,
        source: 'country',
        key: (v) => v?.title_short
    },
    department: {
        type: Select,
        source: 'department'
    },
    unit: {
        type: Select,
        source: 'staff_unit'
    },
    report_category: {
        type: Input,
        content: 'text'
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short
    },
    event: {
        type: Select,
        source: 'event',
        key: (v) => `(${v?.title_short}) ${v?.title}`
    },
    self_academic_degree: {
        type: Select,
        source: 'self_academic_degree'
    },
    self_academic_rank: {
        type: Select,
        source: 'self_academic_rank'
    },
    staff_category: {
        type: Select,
        props: {
            isMulti: true,
            closeMenuOnSelect: false
        },
        source: 'staff_category',
        key: (v) => {
            if (v?.title_short)
                return `${v?.title} (${v?.title_short})`
            else
                return v?.title
        }
    },
    direction: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
    created_at: {
        type: InputDate,
        content: 'date'
    },
    updated_at: {
        type: InputDate,
        content: 'date'
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
    direction_staff: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
    staff_staff_position: {
        type: Select,
        source: 'staff_position',
    },
    staff_staff_category: {
        type: Select,
        source: 'staff_category',
        key: (v) => {
            if (v?.title_short)
                return `${v?.title} (${v?.title_short})`
            else
                return v?.title
        }
    }
}

export const CAN_MANAGE_ROLES = {
    [STATUS_NEW]: [ROLES.admin, ROLES.direction, ROLES.direction_staff, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction_staff],
    [STATUS_PROCESS]: [ROLES.admin, ROLES.direction],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction, ROLES.direction_staff]
}

export const REQUIRED_FIELDS = {
    [STATUS_NEW]: [
        'first_name', 'last_name', 'birth_date',
    ],
    [STATUS_APPROVE]: [
        'first_name', 'last_name', 'birth_date', 'project_id', 'self_academic_degree_id', 'self_academic_rank_id', 'event_id', 'job_contract_id'
    ],
    [STATUS_PROCESS]: [
        'first_name', 'last_name', 'birth_date', 'project_id', 'self_academic_degree_id', 'self_academic_rank_id', 'event_id', 'job_contract_id', 'country_id', 'contract_number', 'contract_date', 'personal_account', 'department_id', 'employment_date', 'staff_staff_position', 'employee_id'
    ],
    [STATUS_ACCEPT]: [
        'first_name', 'last_name', 'birth_date', 'project_id', 'self_academic_degree_id', 'self_academic_rank_id', 'event_id', 'job_contract_id', 'country_id', 'contract_number', 'contract_date', 'personal_account', 'department_id', 'employment_date', 'staff_staff_position', 'staff_category_id', 'employee_id'
    ],
    [STATUS_TEMP]: [
        'first_name', 'last_name', 'birth_date', 'project_id', 'self_academic_degree_id', 'self_academic_rank_id', 'event_id', 'job_contract_id', 'country_id', 'contract_number', 'contract_date', 'personal_account', 'department_id', 'employment_date', 'staff_staff_position', 'staff_category_id', 'employee_id'
    ]
}

export const VALUES_TO_HISTORY = ['last_name', 'work_group_order', 'project_id', 'country_id', 'contract_number', 'contract_date',
    'dismissal_date', 'personal_account', 'event_id', 'order_id', 'h_index', 'staff_staff_category', 'staff_staff_position',
    'self_academic_degree', 'job_contract', 'staff_category_id', 'pub_info', 'patent_info'];

const setRequiredField = (field, status_required, is_array = false) => {
    if (field && !isNaN(status_required)) {
        if (is_array)
            return field?.when(['status'], (status, schema) => {
                return (status[0] >= status_required) ? schema?.required('Обязательное поле!').min(1, 'Обязательное поле!') : schema
            })
        else {
            return field?.when(['status'], (status, schema) => {
                return (status[0] >= status_required) ? schema?.required('Обязательное поле!') : schema
            })
        }
    } else {
        return null
    }
}

const getSchema = () => {
    return {
        status: Yup.number(),
        current_status: Yup.number(),
        message: Yup.string().when(['status', 'current_status'], (status, schema) => {
            return status[0] < status[1] ? schema.required('Обязательное поле!') : schema
        }),
        project_id: Yup.number()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!'),
        event_id: Yup.number()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!'),
        first_name: Yup.string().nullable().required('Обязательное поле!'),
        last_name: Yup.string().nullable().required('Обязательное поле!'),
        birth_date: Yup.string().nullable().required('Обязательное поле!').typeError('Обязательное поле!'),
        dismissal_date: Yup.string().nullable().typeError('Ошибка заполнения!').nullable(),
        self_academic_degree_id: setRequiredField(Yup.number().typeError('Обязательное поле!'), STATUS_APPROVE),
        self_academic_rank_id: setRequiredField(Yup.number().typeError('Обязательное поле!'), STATUS_APPROVE),
        job_contract_id: setRequiredField(
            Yup.number()
                .typeError('Обязательное поле!'), STATUS_APPROVE),
        country_id: setRequiredField(
            Yup.number().typeError('Обязательное поле!'), STATUS_PROCESS),
        contract_number: setRequiredField(
            Yup.string().nullable(), STATUS_PROCESS),
        contract_date: setRequiredField(
            Yup.string().nullable()
                .typeError('Обязательное поле!'), STATUS_PROCESS),
        personal_account: setRequiredField(
            Yup.string().nullable(), STATUS_PROCESS),
        department_id: setRequiredField(
            Yup.number()
                .typeError('Обязательное поле!'), STATUS_PROCESS),
        employment_date: setRequiredField(
            Yup.string().nullable()
                .typeError('Обязательное поле!'), STATUS_PROCESS),
        staff_staff_position: setRequiredField(
            Yup.array(Yup.number()), STATUS_PROCESS, true),
        staff_staff_category: Yup.array(Yup.number()),
        pub_info: Yup.array(Yup.object().shape({
                doi: Yup.string().nullable(),
                year: Yup.number().nullable(),
                title: Yup.string().nullable(),
                pub_ref_id: Yup.number().nullable()
            }
        )).nullable(),
        patent_info: Yup.array(Yup.object().shape({
                year: Yup.number().nullable(),
                title: Yup.string().nullable(),
            }),
        ).nullable().json(),
        employee_id: setRequiredField(
            Yup.number().typeError('Обязательное поле!').nullable(), STATUS_PROCESS, false),
        staff_category_id: setRequiredField(
            Yup.number().typeError('Обязательное поле!').nullable(), STATUS_ACCEPT),
    }
}

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_NEW]: Yup.object().shape(getSchema()),
    [STATUS_APPROVE]: Yup.object().shape(getSchema()),
    [STATUS_PROCESS]: Yup.object().shape(getSchema()),
    [STATUS_ACCEPT]: Yup.object().shape(getSchema()),
}


export const CHECK_CATEGORY = {
    'ВУ': (staff) => staff?.pub_info && Object.keys(staff?.pub_info).length ? staff?.pub_info?.some((pub) => pub?.q1_q2 === 'on' || pub?.q1_q2 === true) || staff?.patent_info?.some((pat) => pat?.year > new Date().getFullYear() - 3) : false,
    'МПИ': (staff) => isValue(staff?.self_academic_degree) && (staff?.pub_info?.length === 2 || staff?.patent_info?.length === 2) && staff?.age < 40,
    'АСПДС': (staff) => isValue(staff?.postgraduate_info?.subject) && isValue(staff?.postgraduate_info?.organization),
    'АСП': (staff) => isValue(staff?.postgraduate_info?.order),
    'ИАСП': (staff) => isValue(staff?.postgraduate_info?.order)
}
