import React from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";

import Item from "./item";

const SortableItem = ({id, visible, label, disabled = false}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({id, disabled});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    return (
        <li
            style={style}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
        >
            {!disabled ?
                <Item id={id} label={label} visible={visible}/>
                : null}
        </li>
    );
};

export default SortableItem;
