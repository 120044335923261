import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Home from "../../screens/home";
import Login from "../../screens/auth/login";
import Forget from "../../screens/auth/forget";
import Indicator from "../../screens/indicator/indicator/indicator";
import * as React from "react";
import RouteGuard from "./routeguard";
import RoutePublic from "./routepublic";
import {BaseLayout} from "../../components/base";
import * as IndicatorSection from "../../screens/indicator/indicator_section_elements";
import IndicatorCreate from "../../screens/indicator/indicator/create";
import IndicatorField from "../../screens/indicator/indicator/item";
import IndicatorUpdate from "../../screens/indicator/indicator/update";
import Staff from "../../screens/database/staff/staff";
import * as Dict from "../../screens/dict/dict_elements";
import * as DictField from "../../screens/dict/dict_item";
import * as DictUpdate from "../../screens/dict/dict_update";
import * as DictCreate from "../../screens/dict/dict_create";
import Report from "../../screens/indicator/report/report";
import Report_fin from "../../screens/indicator/report_fin/report_fin";
import IndicatorDirectionCreate from "../../screens/indicator/section/create";
import Dashboard from "../../screens/dashboard/dashboard";
import DashboardReport from "../../screens/dashboard/report";
import DashboardReportItem from "../../screens/dashboard/item";
import Publication from "../../screens/database/publication/publication";
import Rid from "../../screens/database/rid/rid";
import Users from "../../screens/users/users";
import UserCreate from "../../screens/users/create";
import Verify from "../../screens/users/verify";
import UserField from "../../screens/users/item";
import UserUpdate from "../../screens/users/update";
import AccessError from "../../screens/error/access_error";
import {AbacProvider, AllowedTo} from "react-abac";
import {PERMISSIONS, RULES} from "../../rbac/constant";
import {useContext} from "react";
import {AuthContext} from "../../auth";
import IndicatorProjectCreate from "../../screens/indicator/projects/create";
import ResetPass from "../../screens/auth/reset_pass";
import Specialist from "../../screens/database/specialist/specialist";
import {StaffCreate} from "../../screens/database/staff/create";
import {PublicationCreate} from "../../screens/database/publication/create";
import {RidCreate} from "../../screens/database/rid/create";
import {SpecialistCreate} from "../../screens/database/specialist/create";
import ReportCreate from "../../screens/indicator/report/create";
import ReportUpdate from "../../screens/indicator/report/update";
import ReportFinCreate from "../../screens/indicator/report_fin/create";
import ReportFinUpdate from "../../screens/indicator/report_fin/update";
import {MyFileBrowser} from "../../components/file_manager/file_manager";
import {
    DatabaseItemAmortization,
    DatabaseItemFinancing,
    DatabaseItemPublication,
    DatabaseItemRid,
    DatabaseItemSpecialist,
    DatabaseItemStaff
} from "../../screens/database/items";
import Financing from "../../screens/database/financing/financing";
import {FinancingCreate} from "../../screens/database/financing/create";
import {IndicatorSettings} from "../../screens/indicator/indicator/settings";
import {StaffImport} from "../../screens/import/staff_import";
import {SpecialistImport} from "../../screens/import/specialist_import";
import Amortization from "../../screens/database/amortization/amortization";
import {AmortizationCreate} from "../../screens/database/amortization/create";
import {NotFound} from "../../screens/error/not_found_error";

const SiteRoutes = () => {
    const user = useContext(AuthContext);

    const dictRoutes = (name, title, MainPage = null, FieldPage = null, UpdatePage = null, CreatePage = null) => {
        return <React.Fragment>
            <Route path={`/dicts/${name}/`} element={
                <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                    <BaseLayout>
                        <RouteGuard component={MainPage} title={title}/>
                    </BaseLayout>
                </AllowedTo>
            }/>
            <Route path={`/dicts/${name}/item/:id`} element={
                <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                    <BaseLayout>
                        <RouteGuard component={FieldPage}/>
                    </BaseLayout>
                </AllowedTo>
            }/>
            {UpdatePage ?
                <Route path={`/dicts/${name}/update/:id`} element={
                    <AllowedTo perform={PERMISSIONS.MANAGE_COMMON} no={AccessError}>
                        <BaseLayout>
                            <RouteGuard component={UpdatePage}/>
                        </BaseLayout>
                    </AllowedTo>
                }/> : null
            }
            {CreatePage ?
                <Route path={`/dicts/${name}/create/`} element={
                    <AllowedTo perform={PERMISSIONS.MANAGE_COMMON} no={AccessError}>
                        <BaseLayout>
                            <RouteGuard component={CreatePage}/>
                        </BaseLayout>
                    </AllowedTo>
                }/> : null
            }
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <BrowserRouter>
                <AbacProvider user={user} roles={user?.roles} rules={RULES}>
                    <Routes>
                        <Route path="*" element={<NotFound/>}/>
                        <Route path="/login" element={<RoutePublic component={Login}/>}/>
                        <Route path="/forget" element={<Forget/>}/>
                        <Route path="/reset/:token" element={<ResetPass/>}/>
                        <Route path="/dicts" element={<Navigate to={'/'}/>}/>
                        <Route path="/verify/:verify_token" element={<Verify/>}/>
                        <Route path="/access-error" element={<AccessError/>}/>

                        <Route exact path="/access-error" element={
                            <BaseLayout>
                                <RouteGuard component={AccessError}/>
                            </BaseLayout>
                        }/>

                        <Route exact path="/" element={
                            <BaseLayout>
                                <RouteGuard component={Home}/>
                            </BaseLayout>
                        }/>

                        <Route exact path="/users" element={
                            <AllowedTo perform={PERMISSIONS.ADMIN_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={Users}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route exact path="/users/create" element={
                            <AllowedTo perform={PERMISSIONS.ADMIN_PANEL} no={AccessError}>

                                <BaseLayout>
                                    <RouteGuard component={UserCreate}/>
                                </BaseLayout> </AllowedTo>

                        }/>
                        <Route exact path="/users/item/:id" element={
                            <AllowedTo perform={PERMISSIONS.ADMIN_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={UserField}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route exact path="/users/update/:id" element={
                            <AllowedTo perform={PERMISSIONS.ADMIN_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={UserUpdate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/dashboard" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <RouteGuard component={Dashboard}/>
                            </AllowedTo>
                        }/>
                        <Route path="/dashboard-report" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={DashboardReport}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/dashboard-report/item/:id" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={DashboardReportItem}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/indicator/" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={Indicator}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/indicator/:id" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={IndicatorField}/>
                                </BaseLayout>
                            </AllowedTo>

                        }/>
                        <Route path="/indicator/create" element={
                            <AllowedTo perform={PERMISSIONS.MANAGE_COMMON} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={IndicatorCreate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/indicator/update/:id" element={
                            <AllowedTo perform={PERMISSIONS.MANAGE_COMMON} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={IndicatorUpdate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/indicator/settings" element={
                            <AllowedTo perform={PERMISSIONS.MANAGE_COMMON} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={IndicatorSettings}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/indicator-direction" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={IndicatorSection.IndicatorDirection}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/indicator-direction/create" element={
                            <AllowedTo perform={PERMISSIONS.MANAGE_COMMON} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={IndicatorDirectionCreate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/indicator-project" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={IndicatorSection.IndicatorProject}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/indicator-project/create" element={
                            <AllowedTo perform={PERMISSIONS.MANAGE_COMMON} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={IndicatorProjectCreate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/staff" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={Staff}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/staff/item/:id" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={DatabaseItemStaff}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/staff/create/" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={StaffCreate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/publication" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={Publication}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/publication/item/:id" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={DatabaseItemPublication}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/publication/create/" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={PublicationCreate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/rid" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={Rid}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/rid/item/:id" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={DatabaseItemRid}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/rid/create" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={RidCreate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/amortization" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={Amortization}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/amortization/item/:id" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={DatabaseItemAmortization}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/amortization/create" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={AmortizationCreate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/specialist" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={Specialist}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/specialist/item/:id" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={DatabaseItemSpecialist}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/specialist/create" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={SpecialistCreate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/indicator/report" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={Report}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/indicator/report/create" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={ReportCreate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/indicator/report/update/:id" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={ReportUpdate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/indicator/report_fin" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={Report_fin}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/indicator/report_fin/create" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={ReportFinCreate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/indicator/report_fin/update/:id" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={ReportFinUpdate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path={'/file-manager'} element={
                            <AllowedTo perform={PERMISSIONS.ADMIN_PANEL} no={AccessError}>
                                <MyFileBrowser path={`root`}
                                               instanceId={`root`}/>
                            </AllowedTo>
                        }/>

                        <Route path="/financing" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={Financing}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/financing/create" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={FinancingCreate}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>
                        <Route path="/financing/item/:id" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={DatabaseItemFinancing}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/import-staff" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={StaffImport}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        <Route path="/import-specialist" element={
                            <AllowedTo perform={PERMISSIONS.MANAGER_PANEL} no={AccessError}>
                                <BaseLayout>
                                    <RouteGuard component={SpecialistImport}/>
                                </BaseLayout>
                            </AllowedTo>
                        }/>

                        {dictRoutes('grant', 'Гранты',
                            Dict.Grant, DictField.GrantField, DictUpdate.GrantUpdate, DictCreate.GrantCreate)}
                        {dictRoutes('event-category', 'Категории мероприятий',
                            Dict.EventCategories, DictField.EventCategoryField, DictUpdate.EventCategoryUpdate, DictCreate.EventCategoryCreate)}
                        {dictRoutes('order', 'Приказы',
                            Dict.Order, DictField.OrderField, DictUpdate.OrderUpdate, DictCreate.OrderCreate)}
                        {dictRoutes('template', 'Шаблоны',
                            Dict.Template, DictField.TemplateField, DictUpdate.TemplateUpdate, DictCreate.TemplateCreate)}
                        {dictRoutes('person', 'Персоналии',
                            Dict.Person, DictField.PersonField)}
                        {dictRoutes('academic-degree', 'Ученые степени',
                            Dict.AcademicDegree, DictField.AcademicDegreeField, DictUpdate.AcademicDegreeUpdate, DictCreate.AcademicDegreeCreate)}
                        {dictRoutes('academic-rank', 'Ученые звания',
                            Dict.AcademicRank, DictField.AcademicRankField, DictUpdate.AcademicRankUpdate, DictCreate.AcademicRankCreate)}
                        {dictRoutes('country', 'Страны',
                            Dict.Country, DictField.CountryField, DictUpdate.CountryUpdate, DictCreate.CountryCreate)}
                        {dictRoutes('department', 'Подразделения',
                            Dict.Departament, DictField.DepartamentField)}
                        {dictRoutes('direction', 'Направления',
                            Dict.Direction, DictField.DirectionField, DictUpdate.DirectionUpdate, DictCreate.DirectionCreate)}
                        {dictRoutes('event', 'Мероприятия',
                            Dict.Events, DictField.EventsField, DictUpdate.EventsUpdate, DictCreate.EventsCreate)}
                        {dictRoutes('job-contract', 'Формы привлечения работников',
                            Dict.JobContract, DictField.JobContractField, DictUpdate.JobContractUpdate, DictCreate.JobContractCreate)}
                        {dictRoutes('organization', 'Организации',
                            Dict.Organization, DictField.OrganizationField, DictUpdate.OrganizationUpdate, DictCreate.OrganizationCreate)}
                        {dictRoutes('patent-office', 'Патентные ведомства',
                            Dict.PatentOffice, DictField.PatentOfficeField, DictUpdate.PatentOfficeUpdate, DictCreate.PatentOfficeCreate)}
                        {dictRoutes('project', 'Проекты',
                            Dict.Project, DictField.ProjectField, DictUpdate.ProjectUpdate, DictCreate.ProjectCreate)}
                        {dictRoutes('self-academic-degree', 'Ученые степени',
                            Dict.SelfAcademicDegree, DictField.SelfAcademicDegreeField, DictUpdate.SelfAcademicDegreeUpdate, DictCreate.SelfAcademicDegreeCreate)}
                        {dictRoutes('self-academic-rank', 'Ученые звания',
                            Dict.SelfAcademicRank, DictField.SelfAcademicRankField, DictUpdate.SelfAcademicRankUpdate, DictCreate.SelfAcademicRankCreate)}
                        {dictRoutes('staff-category', 'Категории работников',
                            Dict.StaffCategory, DictField.StaffCategoryField, DictUpdate.StaffCategoryUpdate, DictCreate.StaffCategoryCreate)}
                        {dictRoutes('staff-position', 'Должности',
                            Dict.StaffPosition, DictField.StaffPositionField, DictUpdate.StaffPositionUpdate, DictCreate.StaffPositionCreate)}
                        {dictRoutes('staff-unit', 'Штатные единицы',
                            Dict.StaffUnit, DictField.StaffUnitField, DictUpdate.StaffUnitUpdate, DictCreate.StaffUnitCreate)}
                        {dictRoutes('unit', 'Единицы измерения',
                            Dict.Unit, DictField.UnitField, DictUpdate.UnitUpdate, DictCreate.UnitCreate)}
                        {dictRoutes('publication-type', 'Вид издания',
                            Dict.PublicationType, DictField.PublicationTypeField, DictUpdate.PublicationTypeUpdate, DictCreate.PublicationTypeCreate)}
                    </Routes>
                </AbacProvider>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default SiteRoutes;