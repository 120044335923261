import axiosInstance, {protectedAxios} from "../utils/axiosAPI";

export const getDirectory = (setFunc, path) => {
    return protectedAxios(axiosInstance.get, "/api/directory/", {params: {path: path}})
        .then(response => {
            if (response.status === 200) {
                setFunc(response.data);
            }
        })
        .catch(err => console.log(err));
};

export const getFile = (path, file) => {
    let body = {
        params: {
            path: path,
            file: file
        },
        headers:
            {
                'Content-Disposition': "attachment;",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
        responseType: 'blob',
    };

    protectedAxios(axiosInstance.get, `/api/file/`, body)
        .then(({data}) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', file);
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => console.log(err));
}

export const uploadFile = (url = '/api/file/', formData, setFunc) => {
    let body = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return protectedAxios(axiosInstance.post, url, formData, body)
        .then((response) => {
            if (response.status === 200) {
                setFunc(response.data);
            }
            return response;
        })
        .catch(err => {
            return err.response;
        });
}


export const deleteFile = (path, file, setFunc) => {
    let body = {
        data: {
            path: path,
            file: file
        }
    };

    protectedAxios(axiosInstance.delete, `/api/file/`, body)
        .then((response) => {
            if (response.status === 200) {
                setFunc(response.data);
            }
        })
        .catch(err => console.log(err));
}
