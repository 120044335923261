import * as React from "react";
import Dict from "./common/dict";
import {DownloadFile} from "../../components/file_manager/file_handlers";
import {Link} from "react-router-dom";

export const AcademicDegree = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/academic-degree/update'}
                  dict_title={'academic_degree'}
                  url_filed={'/dicts/academic-degree/item'}
            />
        </React.Fragment>
    );
}

export const AcademicRank = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/academic-rank/update'}
                  dict_title={'academic_rank'}
                  url_filed={'/dicts/academic-rank/item'}
            />
        </React.Fragment>
    );
}


export const Country = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/country/update'}
                  dict_title={'country'}
                  url_filed={'/dicts/country/item'}
            />
        </React.Fragment>
    );
}

export const Departament = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'parent', 'is_active'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'parent': 'ID родителя',
        'is_active': 'Активно?',
        'date_create': 'Дата открытия',
        'date_close': 'Дата закрытия',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',

    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/department/update'}
                  dict_title={'department'}
                  url_filed={'/dicts/department/item'}
                  is_update={false}
                  is_create={false}
            />
        </React.Fragment>
    );
}

export const Direction = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/direction/update'}
                  dict_title={'direction'}
                  url_filed={'/dicts/direction/item'}
            />
        </React.Fragment>
    );
}

export const EventCategories = (props) => {
    const filter_fields = [
        'id', 'title', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/event-category/update'}
                  dict_title={'event_category'}
                  url_filed={'/dicts/event-category/item'}
            />
        </React.Fragment>
    );
}

export const Events = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
        'category': 'Категория',
        'project': 'Проект',
        'is_educational': 'Образовательное'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/event/update'}
                  dict_title={'event'}
                  url_filed={'/dicts/event/item'}
            />
        </React.Fragment>
    );
}

export const JobContract = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/job-contract/update'}
                  dict_title={'job_contract'}
                  url_filed={'/dicts/job-contract/item'}
            />
        </React.Fragment>
    );
}

export const Organization = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/organization/update'}
                  dict_title={'organization'}
                  url_filed={'/dicts/organization/item'}
            />
        </React.Fragment>
    );
}

export const PatentOffice = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/patent-office/update'}
                  dict_title={'patent_office'}
                  url_filed={'/dicts/patent-office/item'}
            />
        </React.Fragment>
    );
}

export const Project = (props) => {
    const select_fields = ['direction']
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'direction': 'Направление',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    };

    const additionalCheckField = (field, key, element) => {
        if (key === 'direction' && element?.direction_id) {
            return <Link to={`/dicts/direction/item/${element?.direction_id}`}>
                {field}
            </Link>
        }
        return field
    }

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title}
                  filter_fields={filter_fields}
                  select_fields={select_fields}
                  additionalCheckField={additionalCheckField}
                  url_update={'/dicts/project/update'}
                  dict_title={'project'}
                  url_filed={'/dicts/project/item'}
            />
        </React.Fragment>
    );
}

export const SelfAcademicDegree = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/self-academic-degree/update'}
                  dict_title={'self_academic_degree'}
                  url_filed={'/dicts/self-academic-degree/item'}
            />
        </React.Fragment>
    );
}

export const SelfAcademicRank = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/self-academic-rank/update'}
                  dict_title={'self_academic_rank'}
                  url_filed={'/dicts/self-academic-rank/item'}
            />
        </React.Fragment>
    );
}


export const StaffCategory = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/staff-category/update'}
                  dict_title={'staff_category'}
                  url_filed={'/dicts/staff-category/item'}
            />
        </React.Fragment>
    );
}

export const StaffPosition = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/staff-position/update'}
                  dict_title={'staff_position'}
                  url_filed={'/dicts/staff-position/item'}
            />
        </React.Fragment>
    );
}

export const StaffUnit = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/staff-unit/update'}
                  dict_title={'staff_unit'}
                  url_filed={'/dicts/staff-unit/item'}
            />
        </React.Fragment>
    );
}

export const Unit = (props) => {
    const filter_fields = [
        'id', 'title', 'title_short', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/unit/update'}
                  dict_title={'unit'}
                  url_filed={'/dicts/unit/item'}
            />
        </React.Fragment>
    );
}

export const PublicationType = (props) => {
    const filter_fields = [
        'id', 'title', 'ref_id'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка'
    };

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  url_update={'/dicts/publication-type/update'}
                  dict_title={'publication_type'}
                  url_filed={'/dicts/publication-type/item'}
            />
        </React.Fragment>
    );
}


export const Person = (props) => {
    const filter_fields = [
        'id', 'first_name', 'last_name', 'middle_name', 'employee_id'
    ];
    const labels = {
        'id': 'ID',
        'employee_id': 'Внешняя ссылка',
        'fio': 'ФИО',
        'age': 'Возраст',
        'birth_date': 'Дата рождения',
        'employment_date': 'Дата приема',
        'dismissal_date': 'Дата увольнения',
        'scientific_accounts': 'Научные идентификаторы',
        'country': 'Гражданство',
        'department': 'Основное подразделение',
        'self_academic_degree': 'Ученая степень',
        'academic_rank': 'Ученое звание',
        'created_at': 'Время создания',
        'updated_at': 'Время обновления'
    };

    const additionalCheckField = (field, key) => {
        if (['country', 'self_academic_degree', 'academic_rank', 'department'].includes(key)) {
            return field ? field.title : null
        } else if (key === 'scientific_accounts') {
            let elements = [];
            for (let account in field) {
                elements.push(
                    <div key={account}>
                        <span className="badge badge-secondary">{account}</span>
                        {field[account]}
                    </div>
                )
            }
            return field && Object.keys(field).length > 0 ? elements : <span className={'not-set'}>(не задано)</span>
        }
        return null;
    }

    return (
        <React.Fragment>
            <Dict labels={labels} title={props.title} filter_fields={filter_fields}
                  filter_labels={
                      {
                          'first_name': 'Имя',
                          'last_name': 'Фамилия',
                          'middle_name': 'Отчество',
                      }
                  }
                  url_update={''}
                  dict_title={'person'}
                  url_filed={'/dicts/person/item'}
                  update_field={'fio'}
                  additionalCheckField={additionalCheckField}
                  is_create={false}
            />
        </React.Fragment>
    );
}

export const Template = (props) => {
    const fields = [
        'id', 'title', 'description'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'description': 'Описание',
        'filename': 'Файл',
        'created_at': 'Время создания',
        'updated_at': 'Время обновления'
    };
    const additionalCheckField = (field, key) => {
        if (key === 'filename') {
            return <DownloadFile folder={'templates'} file={field} title={'template'}
                                 icon={'file-word'}
                                 icon_color={'#0056b3'}/>
        }
        return null;
    }

    return (
        <React.Fragment>
            <Dict labels={labels} filter_fields={fields}
                  title={props.title}
                  url_update={'/dicts/template/update'}
                  url_filed={'/dicts/template/item'}
                  dict_title={'template'}
                  additionalCheckField={additionalCheckField}
            />
        </React.Fragment>
    );
}

export const Order = (props) => {
    const fields = [
        'number', 'date'
    ];
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'description': 'Описание',
        'category': 'Категория',
        'filename': 'Файл',
        'created_at': 'Время создания',
        'updated_at': 'Время обновления'
    };
    let select_options = {
        3: 'Не задано',
        1: 'Зачисление на обучение',
        2: 'Рабочая группа'
    }

    const additionalCheckField = (field, key) => {
        if (key === 'filename') {
            return <DownloadFile
                icon={'file-word'}
                icon_color={'#0056b3'}
                folder={'orders'}
                file={field}
                title={'order'}/>
        }
        if (key === 'category') {
            return select_options[field]
        }
        return null;
    }

    return (
        <React.Fragment>
            <Dict labels={labels} filter_fields={fields}
                  title={props.title}
                  url_filed={'/dicts/order/item'}
                  url_update={'/dicts/order/update'}
                  dict_title={'order'}
                  additionalCheckField={additionalCheckField}
            />
        </React.Fragment>
    );
}


export const Grant = (props) => {
    const fields = [
        'account', 'year'
    ];

    const labels = {
        'id': 'ID',
        'event': 'Мероприятие',
        'category': 'Категория',
        'grant': 'Грант',
        'account': 'Номер лицевого счёта',
        'year': 'Год',
    };

    const additionalCheckField = (field, key) => {
        if (key === 'event') {
            let label = null;
            if (field.title_short) {
                label = `(${field.title_short}) ${field.title}`
            } else {
                label = field.title
            }
            return label
        }
        return null;
    }

    return (
        <React.Fragment>
            <Dict labels={labels}
                  filter_fields={fields}
                  title={props.title}
                  update_field={'event'}
                  url_filed={'/dicts/grant/item'}
                  url_update={'/dicts/grant/update'}
                  dict_title={'grant'}
                  additionalCheckField={additionalCheckField}
            />
        </React.Fragment>
    );
}

