import * as React from "react";
import {useEffect, useState} from "react";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {Button, Card} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {getRequest, updateRequest} from "../../../actions/common";
import {Input, Area, Select, Check} from "../../../components/form/forms";
import {setChangedValue} from "../../../utils/utils";
import {DownloadFile, UploadFile} from "../../../components/file_manager/file_handlers";
import {uploadFile} from "../../../actions/file_manager";

const DictUpdate = ({
                        fields, labels, dict_title = '',
                        custom_fields = null, custom_props, header = 'title_short',
                        select_fields = null, select_options = null
                    }) => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [changes, setChanges] = useState({common: {}});
    const [select_values, setSelectValues] = useState({});
    const [select_data, setSelectData] = useState({});
    const [file, setFile] = useState(null);

    const params = useParams();

    useEffect(() => {
        if (!data) {
            if (dict_title === 'event') getRequest(dict_title, setData, {not_additional: true}, params.id);
            else getRequest(dict_title, setData, {}, params.id);
        } else if (select_fields) {
            let set_selected = {};
            select_fields.forEach((field) => {
                set_selected[field] = data.results[0][field];
            });
            setSelectValues(set_selected);
        }
        if (data) {
            document.title = `${data.results[0][header]} | ИС «НЦМУ»`;
        }
    }, [data]);

    useEffect(() => {
        if (select_fields && Object.keys(select_data).length < select_fields.length) {
            for (let field of select_fields) {
                if (!Object.keys(select_data).includes(select_fields[field])
                    && !Object.keys(select_options).includes(select_fields[field])) {
                    getRequest(field, setSelectData, {type_data: 'all'}, '', select_data, field);
                    break
                }
            }
        }
    }, [select_data]);

    useEffect(() => {
        if (data && Object.keys(select_values).length === 0 && select_options) {
            let selected = {};
            for (let index in select_options) {
                selected[index] = data.results[0]?.[index];
            }
            setSelectValues(selected);
        }
    }, [select_values, select_options]);

    const sendFile = () => {
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('path', `root/${data !== null && data.results ? `${dict_title}s` : null}`);
            uploadFile(formData, () => {
            });
        }
    }

    const handleChangeValue = (e) => {
        if (e.target.type !== 'checkbox')
            e.preventDefault();

        let target = e.target.getAttribute('data_change_key');
        let value = e.target.type === 'file' ? e.target.files[0].name : e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        let key = e.target.id === 'new_file' ? 'filename' : e.target.id;

        if (e.target.type === 'file') {
            setFile(e.target.files[0]);
        }
        setChangedValue(setChanges, changes, target, key, value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (changes && data) {
            let payload = {common: {...changes['common'], ...select_values}};
            sendFile();
            updateRequest(dict_title, payload, data.results[0].id).then();
            navigate(`/dicts/${dict_title.replaceAll('_', '-')}`);
        }
    }


    return <React.Fragment>
        <ReactBreadcrumb label={data?.results?.[0]?.title}/>
        <h1 className={"page-header"}>
            <span className={'mr-1'}>Редактировать</span>
            <small className={'text-muted'}>{data !== null && data?.results ? data?.results[0]?.id : null}</small>
        </h1>
        <form onSubmit={handleSubmit}>
            <Card>
                <Card.Body>
                    {fields ?
                        fields.map((field) => {
                            return (
                                <Input label={labels[field]} id={field} handleChangeValue={handleChangeValue}
                                       value={data !== null && data.results ? data?.results[0][field] : null}
                                       key={field}
                                       data_change_key={'common'}
                                />
                            )
                        })
                        : null}
                    {custom_fields ? Object.keys(custom_fields).map(key => {
                        let Field = custom_fields[key];
                        let props = custom_props ? custom_props[key] ? custom_props[key] : {} : {};
                        if (Field === Input || Field === Area || Field === Check) {
                            props['label'] = labels[key];
                            props['handleChangeValue'] = handleChangeValue;
                            if (typeof changes.common[key] === 'undefined' && data && data?.results?.[0]?.[key]) {
                                let temp = {...changes};
                                temp.common[key] = data?.results?.[0]?.[key];
                                setChanges(temp);
                            }
                            if (Field === Area)
                                props['defaultValue'] = changes.common[key];
                            else
                                props['value'] = changes.common[key];
                        } else if (Field === Select) {
                            props['label'] = labels[key];
                            props['select_values'] = select_values;
                            props['setSelectValues'] = setSelectValues;
                            props['options'] = select_options ? select_options[key] ? select_options[key] : null : null;
                        } else if (Field === DownloadFile) {
                            props['folder'] = data !== null && data.results ? `${dict_title}s` : null;
                            props['title'] = dict_title;
                            props['file'] = data !== null && data.results ? data?.results[0][key] : null;
                        } else if (Field === UploadFile) {
                            props['handleChangeValue'] = handleChangeValue;
                            props['file_name'] = file?.name;
                        }
                        return (<Field {...props}
                                       key={key}
                                       id={key}
                                       data_change_key={'common'}
                        />)
                    }) : null}
                    {/*<ChangesInput/>*/}
                </Card.Body>
                <Card.Footer>
                    <div className={'button-actions'}>
                        <Button variant='success' type='submit'>Сохранить</Button>
                        <Button variant='secondary' onClick={() => {
                            window.location.reload();
                        }}>
                            Сбросить
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
        </form>
    </React.Fragment>
}

export default DictUpdate;