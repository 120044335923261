import {IconText} from "../icon_txt";
import {Link} from "react-router-dom";
import * as React from "react";
import {downloadRequest} from "../../actions/common";

import {Button} from "react-bootstrap";

export const DownloadFile = ({folder, file, title, icon = 'file-excel', icon_color = 'green'}) => {
    const downloadHandler = (e) => {
        e.preventDefault()
        let data = {file_name: e.target.getAttribute('data-file-name')};
        downloadRequest(title, data);
    }

    return <Link to={'#'}>
        <div onClick={downloadHandler} data-file-name={`${folder}/${file}`}>
            <IconText icon={icon}
                      icon_color={icon_color}
                      props={{'data-file-name': `${folder}/${file}`}}
                      text={'Скачать'}/>
        </div>
    </Link>
}

export const UploadFile = ({handleChangeValue = null, id, file_name, data_change_key, small_text, error, accept}) => {

    const handleUpload = (e) => {
        if (handleChangeValue) {
            handleChangeValue(e)
        }
    }

    return <div>
        <Button variant={error ? 'danger' : 'primary'} onClick={() => {
            document.getElementById(id).click()
        }}>
            Выбрать файл
        </Button>
        {small_text ? <small style={{display: "block"}}>{small_text}</small> : null}
        {file_name ? <small style={{display: "block"}}>Выбранный файл: {file_name}</small> : null}
        {error ? <small className="form-text text-danger">{error}</small> : null}
        <input type={'file'} style={{display: "none"}}
               onChange={handleUpload} id={id}
               data_change_key={data_change_key}
               accept={accept}
        />
    </div>
}
