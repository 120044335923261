import {ROLES} from "../../../rbac/constant";
import {Input, InputDate, Select} from "../../../components/form/forms";
import * as Yup from "yup";
import {setRequiredField} from "../../../utils/utils";

export const STATUS_DRAFT = -1;             // Черновик
export const STATUS_ACCEPT = 4;             // Принято
export const STATUS_NEW = 1;                // Новая сущность в БД
export const STATUS_APPROVE = 2;            // Рассматривается Дирекцией
export const STATUS_PROCESS = 3;            // Обрабатывается Дирекцией
export const STATUS_TEMP = 5;

export const STATUS_LABELS = {
    [STATUS_NEW]: 'Новый',
    [STATUS_APPROVE]: 'Рассматривается',
    [STATUS_PROCESS]: 'Обрабатывается',
    [STATUS_ACCEPT]: 'Принято',
}

export const STATUS_OPTIONS = [
    {value: STATUS_NEW, label: 'Новый'},
    {value: STATUS_APPROVE, label: 'Рассматривается'},
    {value: STATUS_PROCESS, label: 'Обрабатывается'},
    {value: STATUS_ACCEPT, label: 'Принято'},
]

export const STATUS_BADGE_CLASS = {
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_PROCESS]: 'badge-primary',
    [STATUS_ACCEPT]: 'badge-success',
}

export const REPORT_YEAR = [
    {value: 2020, label: 2020},
    {value: 2021, label: 2021},
    {value: 2022, label: 2022},
    {value: 2023, label: 2023},
    {value: 2024, label: 2024},
    {value: 2025, label: 2025},
]

export const RID_TYPES = [
    {value: 1, label: 'Изобретение'},
    {value: 2, label: 'Полезная модель'},
    {value: 3, label: 'Промышленный образец'},
    {value: 4, label: 'Программа для ЭВМ'},
    {value: 5, label: 'База данных'},
    {value: 6, label: 'Топология интегральной микросхемы'},
]

export const RID_DOCUMENT_TYPES = [
    {value: 1, label: 'Письмо о подаче заявки'},
    {value: 2, label: 'Патент'},
    {value: 3, label: 'Свидетельство о государственной регистрации'},
    {value: 4, label: 'Уведомление ФИПС'},
]

export const RID_STATUS = [
    {value: 3, label: 'Отказ'},
    {value: 2, label: 'Получение охранного документа'},
    {value: 1, label: 'Заявка'},
]


export const LABELS = {
    "id": "ID",
    "status": "Статус",
    "application_status": "Статус подачи",
    "application_date": "Дата подачи заявки или выдачи патента, свидетельства",
    "date": "Дата получения охранного документа",
    "document_type": "Вид документа",
    "event": "Связь с программой",
    "number": "Номер",
    "patent_office": "Страна действия патента",
    "organization": "Заявленный правообладатель",
    "title": "Заголовок",
    "title_en": "Наименование охранного документа на английском",
    "title_ru": "Наименование охранного документа на русском",
    "type": "Вид охранного документа",
    "related_docs_url": 'Ссылка на хранилище',
    "other_authors": "Прочие авторы",
    "project": "Проект",
    "report_year": "Год зачета РИД",
    "authors": "Авторы-сотрудники НЦМУ",
    "rid_message": "Комментарий",
    "messages": "Сообщения",
    'updated_by': 'Обновлено пользователем',
    "created_at": "Дата создания",
    "updated_at": "Дата обновления",
    "created_by": "Создано пользователем",
    "direction_rid_by": "Дирекция РИД",
    "direction_by": "Дирекция",
};

export const ERROR_LABELS = {
    "application_date": "Дата подачи заявки",
}

export const FIELDS = {
    id: {
        type: Input,
        content: 'number'
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS
    },
    application_status: {
        type: Select,
        options: RID_STATUS
    },
    application_date: {
        type: InputDate,
        content: 'date'
    },
    date: {
        type: InputDate,
        content: 'date'
    },
    document_type: {
        type: Select,
        options: RID_DOCUMENT_TYPES
    },
    event: {
        type: Select,
        source: 'event',
        key: (v) => `(${v?.title_short}) ${v?.title}`
    },
    number: {
        type: Input,
        content: 'number'
    },
    patent_office: {
        type: Select,
        source: 'patent_office'
    },
    organization: {
        type: Select,
        source: 'organization',
        key: (v) => v?.title_short
    },
    title: {
        type: Input,
        content: 'text'
    },
    title_en: {
        type: Input,
        content: 'text'
    },
    title_ru: {
        type: Input,
        content: 'text'
    },
    type: {
        type: Select,
        options: RID_TYPES
    },
    report_year: {
        type: Input,
        content: 'number'
    },
    related_docs_url: {
        type: Input,
        content: 'text'
    },
    other_authors: {
        type: Input,
        content: 'text'
    },
    authors: {
        type: Select,
        source: 'staff_select',
        key: (v) => v?.fio
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
    created_at: {
        type: InputDate,
        content: 'date'
    },
    updated_at: {
        type: InputDate,
        content: 'date'
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
    direction_rid_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
    direction_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio
    },
}

export const REQUIRED_FIELDS = {
    [STATUS_NEW]: [
        'title_ru', 'type'
    ],
    [STATUS_APPROVE]: [
        'title_ru', 'type', 'project', 'patent_office', 'application_status', 'organization', 'authors', 'document_type',
    ],
    [STATUS_PROCESS]: [
        'title_ru', 'type', 'project', 'patent_office', 'application_status', 'application_date', 'organization', 'authors', 'document_type', 'date', 'number', 'event', 'report_year'
    ],
    [STATUS_ACCEPT]: [
        'title_ru', 'type', 'project', 'patent_office', 'application_status', 'application_date', 'organization', 'authors', 'document_type', 'date', 'number', 'event', 'report_year'
    ],
    [STATUS_TEMP]: [
        'title_ru', 'type', 'project', 'patent_office', 'application_status', 'application_date', 'organization', 'authors', 'document_type', 'date', 'number', 'event', 'report_year'
    ]
}

const getSchema = () => {
    return {
        status: Yup.number(),
        message: Yup.string().when(['status', 'current_status'], (status, schema) => {
            return status[0] < status[1] ? schema.required('Обязательное поле!') : schema
        }),
        project_id: Yup.number()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!'),
        event_id: setRequiredField(Yup.number()
            .typeError('Обязательное поле!'), STATUS_PROCESS),
        type: Yup.number()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!'),
        patent_office_id: setRequiredField(Yup.number()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        title_ru: Yup.string().required('Обязательное поле!'),
        application_status: setRequiredField(Yup.number()
            .typeError('Обязательное поле!').nullable(), STATUS_APPROVE),
        application_date: setRequiredField(Yup.date().default(undefined)
            .typeError('Обязательное поле!').nullable(), STATUS_PROCESS),
        organization_id: setRequiredField(Yup.number()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        authors: setRequiredField(Yup.array(Yup.number())
            .nullable()
            .typeError('Обязательное поле!'), STATUS_APPROVE, true),
        document_type: setRequiredField(Yup.number().nullable()
            .typeError('Обязательное поле!'), STATUS_APPROVE),
        date: setRequiredField(Yup.date()
            .typeError('Обязательное поле!')
            .nullable(), STATUS_PROCESS),
        number: setRequiredField(Yup.string()
            .nullable()
            .typeError('Обязательное поле!'), STATUS_PROCESS),
        report_year: setRequiredField(Yup.number().typeError('Обязательное поле!').nullable(), STATUS_PROCESS)
    }
}

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_NEW]: Yup.object().shape(getSchema()),
    [STATUS_APPROVE]: Yup.object().shape(getSchema()),
    [STATUS_PROCESS]: Yup.object().shape(getSchema()),
    [STATUS_ACCEPT]: Yup.object().shape(getSchema()),
}


export const CAN_MANAGE_ROLES = {
    [STATUS_NEW]: [ROLES.admin, ROLES.direction_rid, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction_rid],
    [STATUS_PROCESS]: [ROLES.admin, ROLES.direction],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction]
}
