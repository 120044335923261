import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {
    Button, Card, Col, Form, Row,
} from "react-bootstrap";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {IconText} from "../../../components/icon_txt";
import ReactTable from "../../../components/tables/react_table";
import {Link, useParams} from "react-router-dom";
import ReactPagination from "../../../components/pagination/pagination";
import {getRequest} from "../../../actions/common";
import {SelectReact} from "../../../components/form/select";
import {useAbac} from "react-abac";
import {PERMISSIONS, ROLES} from "../../../rbac/constant";
import {Input} from "../../../components/form/forms";
import {AuthContext} from "../../../auth";

const Dict = ({
                  labels, title, filter_fields,
                  url_update = '', url_filed = '', dict_title = '',
                  filter_labels,
                  is_update = true,
                  is_create = true,
                  select_fields = null,
                  update_field = 'title',
                  additionalCheckField = null,
              }) => {
    const {userHasPermissions} = useAbac();
    const user = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [ordering, setOrdering] = useState(null);
    const [data, setData] = useState(null);
    const [filters_value, setFiltersValue] = useState({});
    const [select_data, setSelectData] = useState({});
    const [select_values, setSelectValues] = useState({});

    useEffect(() => {
        document.title = `${title} | ИС «НЦМУ»`;
        if (filter_fields) {
            filter_fields.forEach((field) => {
                if (field === 'id' && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) return;

                document.getElementById(field).value = filters_value[field] !== undefined ? filters_value[field] : null;
            })
        }
    });

    useEffect(() => {
        if (Object.keys(select_data).length === 0 && select_fields !== null) {
            for (let field in select_fields) {
                getRequest(select_fields[field], setSelectData, {}, '', select_data, select_fields[field]);
            }
        }
    }, [select_data]);

    useEffect(() => {
        getRequest(dict_title, setData, {page: currentPage, ordering: ordering});
    }, [ordering, currentPage])

    const btnFilter = () => {
        let card = document.getElementById('card-filter');
        if (card && card.style.display === 'none') {
            card.style.display = 'block';
        } else if (card && card.style.display === 'block') {
            card.style.display = 'none';
        }
    }

    const refreshFilter = () => {
        if (filter_fields) {
            filter_fields.forEach((field) => {
                document.getElementById(field).value = '';
            })
        }
        setFiltersValue({});
        getRequest(dict_title, setData);
    }

    const onSubmitForm = (e) => {
        e.preventDefault();
        let data = {};
        const inputs = Array.from(e.target);
        inputs.forEach(({id, value}) => {
            if (value) {
                data[id] = value;
            }
        });
        for (let field in select_fields) {
            data[`${select_fields[field]}`] = select_values[select_fields[field]];
        }
        setFiltersValue(data);
        getRequest(dict_title, setData, data);
    }

    const FilterInputs = () => {
        if (filter_fields) {
            let elements = [];
            filter_fields.forEach((field) => {
                if (field === 'id' && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) return;

                elements.push(
                    <Col key={field}>
                        <Input id={field} key={field} label={labels[field] ? labels[field] : filter_labels?.[field]}
                               handleChangeValue={() => {
                               }}/>
                    </Col>
                )
            })
            return elements;
        }
        return null;
    }

    const FilterSelects = () => {
        if (select_fields && Object.keys(select_data).length !== 0) {
            let elements = [];
            select_fields.forEach((field) => {
                let options = [];
                for (let i = 0; i < select_data[field].results.length; i++) {
                    if (select_data[field].results[i]) {
                        options.push({
                            value: select_data[field].results[i].id,
                            label: select_data[field].results[i].title
                        });
                    }
                }
                elements.push(
                    <Col key={field}>
                        <div className='form-group'>
                            <Form.Label>{labels[field]}</Form.Label>
                            <SelectReact options={options} setSelectState={true} select_key={field}
                                         setState={setSelectValues}
                                         select_values={select_values}
                                         value={select_values[field]}
                                         selectID={`${field}_select`}/>
                        </div>
                    </Col>
                )
            })
            return elements;
        }
        return null;
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={"page-header"}>
                {title}
            </h1>

            <div className="button-actions mb-3">
                <Button variant="secondary" onClick={btnFilter}>
                    <IconText text={'Форма поиска'} icon={'search'}/>
                </Button>
                {is_create ? userHasPermissions(PERMISSIONS.MANAGE_COMMON) ?
                    <Link to={`/dicts/${dict_title.replaceAll('_', '-')}/create/`}>
                        <Button variant="success">
                            <IconText text={'Создать'} icon={'plus'}/>
                        </Button>
                    </Link>
                    : null : null
                }
            </div>

            <Card style={{display: 'none'}} id='card-filter'>
                <form onSubmit={onSubmitForm}>
                    <Card.Body>
                        <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3'>
                            <FilterInputs/>
                            <FilterSelects/>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <div className={'button-actions'}>
                            <Button type='submit'>Найти</Button>
                            <Button variant='secondary' onClick={refreshFilter}>Сбросить</Button>
                        </div>
                    </Card.Footer>
                </form>
            </Card>
            <div className="table-responsive kv-grid-container">
                <ReactTable data={data ? data : null} labels={labels} pageSize={25}
                            bordered={true} striped={true} hover={true}
                            sort={true}
                            page={1}
                            get_title={dict_title}
                            setFunc={setData}
                            url_update={url_update}
                            url_field={url_filed}
                            is_update={is_update && !user?.roles?.includes(ROLES.management)}
                            update_field={update_field}
                            additionalCheckField={additionalCheckField}
                            setOrdering={setOrdering}
                />
            </div>
            <ReactPagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={data ? data.count : null}
                pageSize={25}
                onPageChange={page => setCurrentPage(page)}
            />
        </React.Fragment>
    );
}

export default Dict;
